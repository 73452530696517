import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Layout from '../../../components/Layout/Layout'
import { Modal } from 'antd'
import axios from 'axios'
import { toast } from 'react-toastify'
import LoadingSpinner from './../../../components/shared/LoadingSpinner'
import useIsMobile from '../../../hooks/useIsMobile'
import { countries } from '../../../utils/countryList'
import Select from 'react-select'
import { stateProvinceList } from '../../../utils/stateProvinceList'

const IntlIndividualPayment = () => {
  const navigate = useNavigate()
  const isMobile = useIsMobile()
  const [totalFee, setTotalFee] = useState(0)
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null)
  const [expandedMethod, setExpandedMethod] = useState(null)
  const [errorMessage, setErrorMessage] = useState('')
  const [fileUploads, setFileUploads] = useState({})
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isQrModalVisible, setIsQrModalVisible] = useState(false)
  const [isUpgrade, setIsUpgrade] = useState(false)
  const [status, setStatus] = useState(null)
  const [loading, setLoading] = useState(true)
  const [paymentRecords, setPaymentRecords] = useState([])
  const [isProcessingCredit, setIsProcessingCredit] = useState(false)

  const countryOptions = countries.map((c) => ({
    value: c.code,
    label: c.name,
  }))

  const [billingInfo, setBillingInfo] = useState({
    firstName: '',
    lastName: '',
    email: '',
  })

  const validatePostalCode = (country, code) => {
    if (country === 'US') {
      return /^\d{5}-\d{4}$/.test(code)
    }
    if (country === 'CA') {
      return /^[A-Za-z]\d[A-Za-z] \d[A-Za-z]\d$/.test(code)
    }
    return true
  }

  const handleCreditPayment = async () => {
    setIsProcessingCredit(true)
    const {
      firstName,
      lastName,
      email,
      address1,
      city,
      state,
      postalCode,
      country,
    } = billingInfo

    if (totalFee <= 0) {
      setErrorMessage('Total fee must be greater than 0.')
      setIsProcessingCredit(false)
      return
    }

    if (!firstName || !lastName || !email || !address1 || !city || !country) {
      setErrorMessage('Please fill in all required billing fields.')
      setIsProcessingCredit(false)
      return
    }

    if ((country === 'US' || country === 'CA') && (!state || !postalCode)) {
      setErrorMessage('State and postal code are required for US/Canada.')
      setIsProcessingCredit(false)
      return
    }

    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      setErrorMessage('Invalid email format.')
      setIsProcessingCredit(false)
      return
    }

    if (
      (country === 'US' || country === 'CA') &&
      !validatePostalCode(country, postalCode)
    ) {
      setErrorMessage(
        country === 'US'
          ? 'Invalid US postal code format. Use 12345-6789.'
          : 'Invalid Canadian postal code format. Use A1B 2C3.'
      )
      setIsProcessingCredit(false)
      return
    }

    try {
      const { data } = await axios.post(
        '/api/v1/intl/payment/credit-debit-card',
        {
          amount: totalFee,
          bill_to_address_city: city,
          bill_to_address_country: country,
          bill_to_address_line1: address1,
          bill_to_address_postal_code: postalCode,
          bill_to_address_state: state,
          bill_to_email: email,
          bill_to_forename: firstName,
          bill_to_surname: lastName,
        },
        { withCredentials: true }
      )

      const form = document.createElement('form')
      form.method = 'POST'
      form.action = data.actionUrl

      Object.entries(data.params).forEach(([name, value]) => {
        const input = document.createElement('input')
        input.type = 'hidden'
        input.name = name
        input.value = value
        form.appendChild(input)
      })

      document.body.appendChild(form)
      form.submit()
    } catch (error) {
      console.error('Error redirecting to payment gateway:', error)
      toast.error('Failed to initiate payment.')
    } finally {
      setIsProcessingCredit(false)
    }
  }

  const paymentMethods = [
    {
      value: '2',
      label: 'Credit / Debit Card',
      icon: 'bi bi-credit-card',
      description: (
        <div>
          Pay securely using your credit or debit card via our trusted payment
          gateway. We accept Visa, Mastercard.
          <div className='mt-2'>
            <div
              style={{ fontWeight: '600' }}
              className='text-danger ms-3 mb-2'
            >
              Please complete all required billing fields before proceeding.
            </div>
            <div className='px-3'>
              <div className='row'>
                <div className='col-md-4 mb-2'>
                  <label className='form-label'>
                    First Name <span className='require'>*</span>
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    value={billingInfo.firstName}
                    onChange={(e) =>
                      setBillingInfo({
                        ...billingInfo,
                        firstName: e.target.value,
                      })
                    }
                  />
                </div>
                <div className='col-md-4 mb-2'>
                  <label className='form-label'>
                    Last Name <span className='require'>*</span>
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    value={billingInfo.lastName}
                    onChange={(e) =>
                      setBillingInfo({
                        ...billingInfo,
                        lastName: e.target.value,
                      })
                    }
                  />
                </div>
                <div className='col-md-4 mb-2'>
                  <label className='form-label'>
                    Email Address <span className='require'>*</span>
                  </label>
                  <input
                    type='email'
                    className='form-control'
                    value={billingInfo.email}
                    onChange={(e) =>
                      setBillingInfo({ ...billingInfo, email: e.target.value })
                    }
                  />
                </div>

                <div className='col-md-4 mb-2'>
                  <label className='form-label'>
                    Address Line 1 <span className='require'>*</span>
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    maxLength={60}
                    value={billingInfo.address1}
                    onChange={(e) =>
                      setBillingInfo({
                        ...billingInfo,
                        address1: e.target.value,
                      })
                    }
                  />
                </div>

                <div className='col-md-4 mb-2'>
                  <label className='form-label'>
                    City <span className='require'>*</span>
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    maxLength={50}
                    value={billingInfo.city}
                    onChange={(e) =>
                      setBillingInfo({
                        ...billingInfo,
                        city: e.target.value,
                      })
                    }
                  />
                </div>

                <div className='col-md-4 mb-2'>
                  <label className='form-label'>
                    Country <span className='require'>*</span>
                  </label>
                  <Select
                    options={countryOptions}
                    value={countryOptions.find(
                      (opt) => opt.value === billingInfo.country
                    )}
                    onChange={(selectedOption) =>
                      setBillingInfo({
                        ...billingInfo,
                        country: selectedOption ? selectedOption.value : '',
                      })
                    }
                    placeholder='-- Select Country --'
                    isClearable
                  />
                </div>

                {(billingInfo.country === 'US' ||
                  billingInfo.country === 'CA') && (
                  <>
                    <div className='col-md-4'>
                      <label className='form-label'>
                        State <span className='require'>*</span>
                      </label>
                      <Select
                        options={stateProvinceList[billingInfo.country] || []}
                        value={
                          stateProvinceList[billingInfo.country]?.find(
                            (opt) => opt.value === billingInfo.state
                          ) || null
                        }
                        onChange={(selectedOption) =>
                          setBillingInfo({
                            ...billingInfo,
                            state: selectedOption?.value || '',
                          })
                        }
                        isClearable
                        placeholder='-- Select State --'
                      />
                    </div>

                    <div className='col-md-4'>
                      <label className='form-label'>
                        Postal Code <span className='require'>*</span>
                        {billingInfo.country === 'US' && (
                          <small className='text-muted ms-2'>
                            (e.g. 12345-6789)
                          </small>
                        )}
                        {billingInfo.country === 'CA' && (
                          <small className='text-muted ms-2'>
                            (e.g. A1B 2C3)
                          </small>
                        )}
                      </label>
                      <input
                        type='text'
                        className='form-control'
                        value={billingInfo.postalCode}
                        onChange={(e) =>
                          setBillingInfo({
                            ...billingInfo,
                            postalCode: e.target.value,
                          })
                        }
                      />
                    </div>
                  </>
                )}
              </div>

              <div className='mt-3 text-end mb-2'>
                <button
                  className='btn btn-primary btn-sm'
                  onClick={handleCreditPayment}
                  disabled={isProcessingCredit}
                >
                  {isProcessingCredit ? (
                    <>
                      <span className='spinner-border spinner-border-sm me-2'></span>
                      Processing...
                    </>
                  ) : (
                    'Proceed to Payment'
                  )}
                </button>

                {!billingInfo.firstName ||
                  !billingInfo.lastName ||
                  !billingInfo.email ||
                  !billingInfo.address1 ||
                  !billingInfo.country ||
                  ((billingInfo.country === 'US' ||
                    billingInfo.country === 'CA') &&
                    (!billingInfo.city ||
                      !billingInfo.state ||
                      !billingInfo.postalCode))}
              </div>
            </div>
          </div>
        </div>
      ),
      requiresUpload: false,
    },
    {
      value: '1',
      label: 'Telegraphic Transfer',
      icon: 'bi bi-bank',
      description: (
        <>
          <div className='d-flex gap-2 mt-2'>
            <i
              className='bi bi-info-circle-fill'
              style={{ color: '#0d6efd' }}
            ></i>
            <span>
              Transfer the funds to our bank account via Telegraphic Transfer
              (TT), upload the proof of payment, and submit.
            </span>
          </div>
          <div className='d-flex mt-1'>
            <div className='ms-4'>
              <div>Bank Account Information:</div>
              <ul className='m-0'>
                <li>Name of Bank: Public Bank Berhad</li>
                <li>Account Name: The Christian Association of Subang Jaya</li>
                <li>Account Number: 3242177619</li>
                <li>Branch: Subang Jaya Branch</li>
                <li>
                  Branch Address: B1 - B4, Jalan SS 15/4D, Subang Jaya, 47500
                  Petaling Jaya, Selangor, Malaysia
                </li>
                <li>Swift Code: PBBEMYKL</li>
              </ul>
            </div>
          </div>
        </>
      ),
      requiresUpload: true,
    },
    {
      value: '3',
      label: 'QR eWallet',
      icon: 'bi bi-qr-code',
      description: (
        <>
          <div className='d-flex gap-2 mt-2'>
            <i
              className='bi bi-info-circle-fill'
              style={{ color: '#0d6efd' }}
            ></i>
            <span>
              Scan the QR code with your eWallet app, upload the proof of
              payment, and submit. (Applicable for Indonesia, Singapore,
              Thailand, China, South Korea, and Cambodia only.)
            </span>
          </div>
          <div className='d-flex mt-1'>
            <div className='ms-4'>
              <div
                onClick={() => setIsQrModalVisible(true)}
                style={{ cursor: 'pointer', color: '#0d6efd' }}
              >
                Click here to view QR code <i className='bi bi-qr-code'></i>
              </div>
            </div>
          </div>
        </>
      ),
      requiresUpload: true,
    },
  ]

  const checkFee = async () => {
    try {
      const res = await axios.get('/api/v1/intl/payment/check-individual-fee', {
        withCredentials: true,
      })
      if (res.data.success) {
        setStatus(res.data.status)
        setTotalFee(res.data.feeUsd || 0)
        setIsUpgrade(res.data.isUpgrade || false)
      } else {
        setStatus(null)
      }
    } catch (err) {
      console.error('Failed to fetch fee:', err)
      setStatus(null)
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    checkFee()
    fetchPaymentRecords()
  }, [])

  const handleFileChange = (methodValue, event) => {
    const file = event.target.files[0]
    setFileUploads((prev) => ({ ...prev, [methodValue]: file }))
  }

  const handleUploadSubmit = async (methodValue) => {
    if (!fileUploads[methodValue]) {
      setErrorMessage('Please upload a file before submit.')
      return
    }

    setIsSubmitting(true)
    setErrorMessage('')

    const formData = new FormData()
    formData.append('paySlip', fileUploads[methodValue])
    formData.append('paymentMethod', methodValue)
    formData.append('totalAmount', totalFee)

    try {
      await axios.post('/api/v1/intl/payment/individual/submit', formData, {
        withCredentials: true,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })

      setSelectedPaymentMethod(null)
      setExpandedMethod(null)
      setFileUploads((prev) => ({ ...prev, [methodValue]: null }))

      setLoading(true)
      await checkFee()
      await fetchPaymentRecords()
    } catch (error) {
      toast.error('Error uploading payment data.')
      setErrorMessage(
        error.response?.data?.message || 'Upload failed. Try again.'
      )
    } finally {
      setIsSubmitting(false)
    }
  }

  const toggleExpand = (value) => {
    setExpandedMethod(expandedMethod === value ? null : value)
  }

  const handleQrModalCancel = () => setIsQrModalVisible(false)

  const fetchPaymentRecords = async () => {
    try {
      const res = await axios.get('/api/v1/intl/payment/individual/records', {
        withCredentials: true,
      })

      if (res.data.success) {
        setPaymentRecords(res.data.data || [])
      }
    } catch (err) {
      console.error('Failed to fetch payment records:', err)
    }
  }

  return (
    <Layout title='Event Registration System (ERS)'>
      <div className='p-3 mb-5'>
        <div
          className='fw-bold mb-3 text-uppercase text-center'
          style={{
            color: '#5d3104',
            fontSize: '18px',
            borderBottom: '1px solid #d3c4a7',
            paddingBottom: '10px',
          }}
        >
          2025 September International Training for Elders and Responsible Ones
        </div>

        <div
          className={`d-flex flex-column flex-md-row justify-content-end gap-2 gap-md-4 ${
            isMobile ? 'mb-4' : 'mb-2'
          }`}
        >
          <button
            className='btn btn-success btn-sm'
            onClick={() => navigate('/')}
            style={{
              textTransform: 'uppercase',
              fontWeight: 600,
              paddingTop: isMobile ? '8px' : '6px',
              paddingBottom: isMobile ? '8px' : '6px',
              fontSize: isMobile ? '1rem' : '0.875rem',
            }}
          >
            Lodging & Meal
          </button>

          <button
            className='btn btn-success btn-sm'
            disabled
            style={{
              textTransform: 'uppercase',
              fontWeight: 600,
              cursor: 'not-allowed',
              paddingTop: isMobile ? '8px' : '6px',
              paddingBottom: isMobile ? '8px' : '6px',
              fontSize: isMobile ? '1rem' : '0.875rem',
            }}
          >
            Payment
          </button>

          <button
            className='btn btn-success btn-sm'
            onClick={() => navigate('/itero/hotel-reservation/transportation')}
            style={{
              textTransform: 'uppercase',
              fontWeight: 600,
              paddingTop: isMobile ? '8px' : '6px',
              paddingBottom: isMobile ? '8px' : '6px',
              fontSize: isMobile ? '1rem' : '0.875rem',
            }}
          >
            Transportation
          </button>
        </div>

        <div
          className='fw-bold text-center mb-2'
          style={{
            fontSize: '17px',
            color: '#219119',
            textTransform: 'uppercase',
          }}
        >
          Payment
        </div>

        <div className='mb-2 text-center'>
          <div style={{ fontWeight: 600, color: '#951a26', fontSize: '18px' }}>
            <i className='bi bi-exclamation-circle-fill'></i> All payments are
            non-refundable
          </div>
        </div>

        {status === 3 && (
          <div
            style={{
              border: '1px solid #856404',
              backgroundColor: '#fffbf0',
              color: '#856404',
              padding: '10px',
              borderRadius: '8px',
              marginBottom: '1rem',
              textAlign: 'center',
              fontWeight: 600,
            }}
          >
            <i
              className='bi bi-hourglass-split'
              style={{ marginRight: '8px' }}
            ></i>
            We have received your payment slip and it is currently being
            processed. Once the payment is confirmed, you will be notified via
            email.
          </div>
        )}

        {loading ? (
          <LoadingSpinner />
        ) : status === null ? (
          <div
            className='fw-bold text-center mb-3'
            style={{ fontSize: '17px', color: '#006ba1' }}
          >
            Please complete your lodging and meal registration before making
            payment.
          </div>
        ) : (
          <>
            <div
              style={{ color: '#2f4483', fontWeight: '600', fontSize: '17px' }}
            >
              Total Amount for Payments: USD{' '}
              {totalFee !== null ? totalFee.toFixed(2) : '0.00'}
              {isUpgrade && (
                <span
                  className='ms-2'
                  style={{ color: '#c92c2c', fontWeight: '500' }}
                >
                  (Please pay the remaining upgrade fee.)
                </span>
              )}
            </div>

            <div className='mb-4'>
              <div className='mt-2'>
                <div className='d-flex justify-content-between align-items-center mb-2'>
                  <div style={{ fontWeight: 'bold' }}>
                    Please choose a payment method below:
                  </div>
                  {errorMessage && (
                    <div style={{ color: 'red', fontWeight: 'bold' }}>
                      {errorMessage}
                    </div>
                  )}
                </div>

                <div className='d-flex flex-column gap-3'>
                  {paymentMethods.map((method) => (
                    <div
                      key={method.value}
                      className={`p-3 border rounded ${
                        selectedPaymentMethod === method.value
                          ? 'border-primary bg-light'
                          : 'border-secondary'
                      }`}
                      style={{ cursor: 'pointer', transition: 'all 0.2s ease' }}
                    >
                      <div
                        className='d-flex align-items-center justify-content-between'
                        onClick={() => {
                          setSelectedPaymentMethod(method.value)
                          setErrorMessage('')
                          toggleExpand(method.value)
                        }}
                      >
                        <div className='d-flex align-items-center'>
                          <input
                            type='radio'
                            name='paymentMethod'
                            value={method.value}
                            checked={selectedPaymentMethod === method.value}
                            onChange={(e) =>
                              setSelectedPaymentMethod(e.target.value)
                            }
                            style={{ marginRight: '10px' }}
                          />
                          <span style={{ fontWeight: 'bold' }}>
                            {method.label}
                          </span>
                          <i className={`${method.icon} ms-2`}></i>
                        </div>
                        <i
                          className={`bi ${
                            expandedMethod === method.value
                              ? 'bi-chevron-up'
                              : 'bi-chevron-down'
                          }`}
                        ></i>
                      </div>

                      {expandedMethod === method.value && (
                        <div
                          className='mt-2 p-2'
                          style={{
                            backgroundColor: '#fff',
                            borderRadius: '4px',
                            border: '1px solid #ddd',
                          }}
                        >
                          <div className='m-0' style={{ fontWeight: '500' }}>
                            {method.description}
                          </div>
                          {method.requiresUpload && (
                            <div className='mt-2 d-flex align-items-center'>
                              <div
                                className='custom-file'
                                style={{ flex: '1' }}
                              >
                                <input
                                  type='file'
                                  accept='image/*,application/pdf'
                                  id={`file-upload-${method.value}`}
                                  onChange={(e) =>
                                    handleFileChange(method.value, e)
                                  }
                                  className='custom-file-input'
                                  style={{ display: 'none' }}
                                />
                                <label
                                  className='btn btn-light w-100 text-start'
                                  htmlFor={`file-upload-${method.value}`}
                                >
                                  {fileUploads[method.value]?.name ||
                                    'Upload Payment Slip'}
                                </label>
                              </div>
                              <button
                                className='btn btn-primary'
                                onClick={() => handleUploadSubmit(method.value)}
                                disabled={
                                  isSubmitting || !fileUploads[method.value]
                                }
                              >
                                {isSubmitting ? (
                                  <span className='spinner-border spinner-border-sm me-2'></span>
                                ) : (
                                  'Submit'
                                )}
                              </button>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </>
        )}

        <div className='mb-4'>
          <div
            className='fw-bold mb-2 text-center'
            style={{ color: '#006ba1' }}
          >
            Payment Records
          </div>

          {/* Responsive wrapper */}
          <div className='table-responsive'>
            <table className='table'>
              <thead>
                <tr>
                  <th>Payment Method</th>
                  <th className='text-end'>Amount</th>
                  <th className='text-center'>Status</th>
                  <th>Reference Number</th>
                  <th className='text-end'>Date</th>
                </tr>
              </thead>
              <tbody>
                {paymentRecords.length > 0 ? (
                  paymentRecords.map((rec, idx) => (
                    <tr key={rec._id || idx}>
                      <td>
                        {{
                          1: 'Telegraphic Transfer',
                          2: 'Credit / Debit Card',
                          3: 'QR eWallet',
                        }[rec.paymentMethod] || 'Unknown'}
                        {rec.paySlipSystem && (
                          <a
                            href={`${process.env.REACT_APP_BACKEND_URL}/files/eventpay/67cf0e922ea8904cab38ae7b/${rec.paySlipSystem}`}
                            target='_blank'
                            rel='noopener noreferrer'
                            style={{ textDecoration: 'none', color: '#0d6efd' }}
                            className='ms-2'
                          >
                            [View Pay Slip]
                          </a>
                        )}
                      </td>

                      <td className='text-end'>
                        {rec.amount?.toFixed(2) || '0.00'}
                      </td>
                      <td
                        className='text-center'
                        style={{
                          fontWeight: '600',
                          color:
                            rec.paymentStatus === 1
                              ? 'green'
                              : rec.paymentStatus === 2
                              ? 'red'
                              : rec.paymentStatus === 3
                              ? '#856404'
                              : rec.paymentStatus === 4 ||
                                rec.paymentStatus === 5
                              ? 'gray'
                              : 'inherit',
                        }}
                      >
                        {rec.paymentStatus === 1
                          ? 'Paid & Confirmed'
                          : rec.paymentStatus === 2
                          ? 'Pending Payment'
                          : rec.paymentStatus === 3
                          ? 'Processing'
                          : rec.paymentStatus === 4
                          ? 'Cancelled'
                          : rec.paymentStatus === 5
                          ? 'Failed'
                          : ''}
                      </td>

                      <td></td>
                      <td className='text-end'>
                        {(() => {
                          const d = new Date(rec.createdAt)
                          const pad = (n) => n.toString().padStart(2, '0')
                          return `${pad(d.getUTCDate())}/${pad(
                            d.getUTCMonth() + 1
                          )}/${d.getUTCFullYear()} ${pad(
                            d.getUTCHours()
                          )}:${pad(d.getUTCMinutes())}:${pad(
                            d.getUTCSeconds()
                          )}`
                        })()}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan='5' className='text-center text-muted'>
                      No payment records found.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        <Modal
          open={isQrModalVisible}
          onCancel={handleQrModalCancel}
          footer={
            <div className='text-end'>
              <button className='close-btn' onClick={handleQrModalCancel}>
                Close
              </button>
            </div>
          }
          title={<div className='text-center'>QR Code for Payment</div>}
          centered
        >
          <div className='d-flex justify-content-center'>
            <img
              src='/mbb_qr.jpg'
              alt='QR Code for eWallet Payment'
              style={{ maxWidth: '70%', height: 'auto' }}
            />
          </div>
        </Modal>
      </div>
    </Layout>
  )
}

export default IntlIndividualPayment
