import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import Layout from '../../../components/Layout/Layout'
import { Tooltip } from 'antd'

import axios from 'axios'
import PaginationButtons from '../../../components/shared/PaginationButtons'
import LoadingSpinner from '../../../components/shared/LoadingSpinner'
import { toast } from 'react-toastify'

const AdminPaymentEventRecords = () => {
  const { id } = useParams()
  const [eventName, setEventName] = useState('')
  const [records, setRecords] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [loading, setLoading] = useState(false)
  const [expandedRows, setExpandedRows] = useState([])

  useEffect(() => {
    const fetchEventName = async () => {
      try {
        const { data } = await axios.get(
          `/api/v1/admin/registration/event/${id}`
        )
        setEventName(data.eventName)
      } catch (error) {
        console.error('Error fetching event name:', error)
      }
    }

    const fetchPayments = async () => {
      setLoading(true)
      try {
        const { data } = await axios.get(
          `/api/v1/admin/payment/event/${id}?page=${currentPage}`
        )
        setRecords(data.payments)
        setTotalPages(data.totalPages || 1)
      } catch (error) {
        console.error('Error fetching payment records:', error)
      } finally {
        setLoading(false)
      }
    }

    if (id) {
      fetchEventName()
      fetchPayments()
    }
  }, [id, currentPage])

  const handleConfirm = async (record) => {
    const confirm = window.confirm('Confirm this payment?')
    if (!confirm) return

    try {
      const paymentId = record._id
      const isIntl = record.intl === 1 || record.intl

      const url = isIntl
        ? `/api/v1/admin/payment/intl/confirm/${paymentId}`
        : `/api/v1/admin/payment/confirm/${paymentId}`

      await axios.put(url)
      setRecords((prev) =>
        prev.map((rec) =>
          rec._id === paymentId ? { ...rec, paymentStatus: 1 } : rec
        )
      )
      toast.success('Payment confirmed successfully.')
    } catch (err) {
      console.error('Error confirming payment:', err)
      toast.error('Failed to confirm payment.')
    }
  }

  const handleCancel = async (paymentId) => {
    const confirm = window.confirm('Cancel this payment?')
    if (!confirm) return

    try {
      await axios.put(`/api/v1/admin/payment/cancel/${paymentId}`)
      setRecords((prev) =>
        prev.map((rec) =>
          rec._id === paymentId ? { ...rec, paymentStatus: 4 } : rec
        )
      )
      toast.success('Payment cancelled successfully.')
    } catch (err) {
      console.error('Error cancelling payment:', err)
      toast.error('Failed to cancel payment.')
    }
  }

  const toggleRow = (id) => {
    setExpandedRows((prev) =>
      prev.includes(id) ? prev.filter((rowId) => rowId !== id) : [...prev, id]
    )
  }

  return (
    <Layout title={`Event Transport Records - ${eventName || 'Loading...'}`}>
      <div className='py-3 px-2'>
        <div className='d-flex justify-content-between align-items-center mb-2'>
          <Link to={`/admin/registration/data/${id}`}>
            <button type='button' className='btn btn-secondary btn-sm'>
              <i className='bi bi-arrow-left-circle'></i> Return
            </button>
          </Link>

          <h5 className='fw-bold text-center flex-grow-1 m-0'>
            {eventName ? eventName : <LoadingSpinner />}
          </h5>
        </div>
        <hr />
        <div className='table-responsive'>
          <table className='table'>
            <thead>
              <tr>
                <th>Locality</th>
                <th>Payment Method</th>
                <th className='text-end'>Amount</th>
                <th className='text-center'>Paid For</th>
                <th className='text-center'>Status</th>
                <th className='text-end'>Upload Date</th>
              </tr>
            </thead>
            <tbody>
              {records.length === 0 ? (
                <tr>
                  <td colSpan='6' className='text-center'>
                    {loading
                      ? 'Loading records...'
                      : 'No payment records found.'}
                  </td>
                </tr>
              ) : (
                records.map((record, index) => (
                  <React.Fragment key={record._id}>
                    <tr key={record._id || index}>
                      <td>{record.locality?.name}</td>
                      <td>
                        {record.paymentMethod === 1
                          ? 'Bank Transfer'
                          : record.paymentMethod === 2
                          ? 'Credit/Debit Card'
                          : record.paymentMethod === 3
                          ? 'QR Code'
                          : ''}

                        {record.paySlipSystem && (
                          <>
                            <a
                              href={`${process.env.REACT_APP_BACKEND_URL}/files/eventpay/${record.eventId}/${record.paySlipSystem}`}
                              target='_blank'
                              rel='noopener noreferrer'
                              style={{
                                textDecoration: 'none',
                                color: '#0d6efd',
                              }}
                              className='ms-2'
                            >
                              <i className='bi bi-file-earmark-pdf'></i>
                            </a>
                          </>
                        )}
                      </td>
                      <td className='text-end'>
                        {record.amount != null
                          ? parseFloat(record.amount).toFixed(2)
                          : ''}
                      </td>
                      <td className='text-center'>
                        <i
                          className={`bi bi-people-fill ${
                            record.paymentStatus === 4
                              ? 'text-secondary'
                              : 'text-primary'
                          }`}
                          style={{
                            cursor:
                              record.paymentStatus === 4
                                ? 'not-allowed'
                                : 'pointer',
                            opacity: record.paymentStatus === 4 ? 0.5 : 1,
                          }}
                          onClick={() =>
                            record.paymentStatus !== 4 && toggleRow(record._id)
                          }
                        ></i>
                      </td>

                      <td className='text-center'>
                        {record.paymentStatus === 4 ? (
                          <span style={{ color: 'gray', fontWeight: '500' }}>
                            Cancelled
                          </span>
                        ) : (
                          <>
                            <Tooltip
                              title={
                                record.paymentStatus === 1
                                  ? 'Paid & Confirmed'
                                  : record.paymentStatus === 3
                                  ? 'Processing'
                                  : ''
                              }
                            >
                              <span
                                className='me-3'
                                style={{
                                  display: 'inline-block',
                                  width: '14px',
                                  height: '14px',
                                  borderRadius: '50%',
                                  backgroundColor:
                                    record.paymentStatus === 1
                                      ? '#1e7e34'
                                      : record.paymentStatus === 3
                                      ? '#ba8b00'
                                      : 'transparent',
                                  cursor: 'pointer',
                                }}
                              ></span>
                            </Tooltip>

                            {record.paymentStatus === 3 && (
                              <>
                                <Tooltip title='Confirm this payment'>
                                  <i
                                    className='bi bi-check-circle-fill text-success fs-4 me-3'
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => handleConfirm(record)}
                                  ></i>
                                </Tooltip>
                                <Tooltip title='Cancel this payment'>
                                  <i
                                    className='bi bi-x-circle-fill text-danger fs-4'
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => handleCancel(record._id)}
                                  ></i>
                                </Tooltip>
                              </>
                            )}
                          </>
                        )}
                      </td>

                      <td className='text-end'>
                        {(() => {
                          const d = new Date(record.createdAt)
                          const pad = (n) => n.toString().padStart(2, '0')
                          return `${pad(d.getUTCDate())}/${pad(
                            d.getUTCMonth() + 1
                          )}/${d.getUTCFullYear()} ${pad(
                            d.getUTCHours()
                          )}:${pad(d.getUTCMinutes())}:${pad(
                            d.getUTCSeconds()
                          )}`
                        })()}
                      </td>
                    </tr>
                    {expandedRows.includes(record._id) && (
                      <tr>
                        <td colSpan='6'>
                          <div>
                            <strong>Participants:</strong>
                            <ul className='mb-0'>
                              {record.userPay?.map((p, idx) => (
                                <li key={idx}>
                                  {p.name}
                                  {p.spouseName && ` (Spouse: ${p.spouseName})`}
                                </li>
                              ))}
                            </ul>
                          </div>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))
              )}
            </tbody>
          </table>
        </div>
        <PaginationButtons
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </div>
    </Layout>
  )
}

export default AdminPaymentEventRecords
