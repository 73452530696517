import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Layout from '../../components/Layout/Layout'
import axios from 'axios'
import { useAuth } from '../../context/auth'
import { Modal, Tooltip } from 'antd'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import IntlRoomPair from './RoomPair'
import LoadingSpinner from './../../components/shared/LoadingSpinner'

const IntlRegistrationList = () => {
  const navigate = useNavigate()
  const [records, setRecords] = useState([])
  const [loading, setLoading] = useState(true)
  const [searchQuery, setSearchQuery] = useState('')
  const [auth] = useAuth()

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedRecord, setSelectedRecord] = useState(null)

  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [totalRecords, setTotalRecords] = useState(1)
  const limit = 20

  const [summary, setSummary] = useState({
    totalRegistered: 0,
    totalPaid: 0,
    totalProcessing: 0,
    totalUnpaid: 0,
    totalFee: 0,
    totalPaidFee: 0,
    totalUnpaidFee: 0,
    totalProcessingFee: 0,
  })
  const formatNumber = (num) =>
    num.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })

  const fetchSummary = useCallback(async () => {
    try {
      const response = await axios.get('/api/v1/intl/summary', {
        withCredentials: true,
      })
      setSummary(response.data)
    } catch (error) {
      console.error('Error fetching summary:', error)
    }
  }, [])

  useEffect(() => {
    if (auth.user) {
      fetchSummary()
    }
  }, [auth.user, fetchSummary])

  const fetchRegistrationRecords = useCallback(async () => {
    if (!auth.user) return
    if (searchQuery.length > 0 && searchQuery.length <= 3) return

    try {
      setLoading(true)
      const response = await axios.get('/api/v1/intl/records', {
        params: {
          page,
          limit,
          searchText: searchQuery.length > 3 ? searchQuery : '',
        },
        withCredentials: true,
      })

      setRecords(response.data.data)
      setTotalPages(response.data.totalPages)
      setTotalRecords(response.data.totalRecords)
    } catch (error) {
      console.error('Error fetching registrations:', error)
      toast.error('Failed to fetch records.')
    } finally {
      setLoading(false)
    }
  }, [auth.user, page, searchQuery])

  useEffect(() => {
    fetchRegistrationRecords()
  }, [fetchRegistrationRecords])

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value)
    setPage(1)
  }

  const handleClearSearch = () => {
    setSearchQuery('')
  }

  const handleDelete = async (recordId) => {
    const confirmDelete = window.confirm('Confirm Delete?')
    if (!confirmDelete) return
    try {
      await axios.delete(`/api/v1/intl/records/${recordId}`)
      setRecords((prevRecords) =>
        prevRecords.filter((record) => record._id !== recordId)
      )
      toast.success('Record deleted successfully!')
      fetchSummary()
    } catch (error) {
      console.error('Error deleting registration:', error)
      toast.error('Failed to delete record!')
    }
  }

  const handleOpenModal = (record) => {
    setSelectedRecord(record)
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
    setSelectedRecord(null)
  }

  const handlePairingUpdate = (updatedRecord, updatedPairedRecord) => {
    setRecords((prevRecords) => {
      const updatedRecordsMap = new Map(
        prevRecords.map((record) => [record._id, record])
      )
      if (updatedRecord) {
        updatedRecordsMap.set(updatedRecord._id, updatedRecord)
      }
      if (updatedPairedRecord) {
        updatedRecordsMap.set(updatedPairedRecord._id, updatedPairedRecord)
      }
      return Array.from(updatedRecordsMap.values())
    })
  }

  const handleExport = async () => {
    try {
      const response = await axios.get('/api/v1/intl/records/download', {
        responseType: 'blob',
        withCredentials: true,
      })

      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'itero_accommodation_list.xlsx'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } catch (error) {
      console.error('Error downloading Excel file:', error)
      toast.error('Failed to download the Excel file.')
    }
  }

  const handlePairingCheck = async (record) => {
    try {
      const response = await axios.get(
        `/api/v1/intl/check/pairing/${record._id}`,
        {
          withCredentials: true,
        }
      )
      const latestRecord = response.data.data
      if (latestRecord.pairingName) {
        toast.warning('User already has a pairing.')
        setRecords((prevRecords) =>
          prevRecords.map((r) =>
            r._id === latestRecord._id ? latestRecord : r
          )
        )
        return
      }
      handleOpenModal(latestRecord)
    } catch (error) {
      console.error('Error checking pairing status:', error)
      toast.error('Failed to verify pairing status.')
    }
  }

  return (
    <Layout title={'2025 September ITERO'}>
      <div className='p-2'>
        <div
          className='mb-3 text-uppercase'
          style={{
            textAlign: 'center',
            color: '#5d3104',
            fontSize: '18px',
            borderBottom: '1px solid #d3c4a7',
            paddingBottom: '10px',
            fontWeight: 'bold',
          }}
        >
          2025 September International Training for Elders and Responsible Ones
        </div>
        <div className='d-flex justify-content-end gap-4 mb-2'>
          <button
            className='btn btn-success btn-sm'
            disabled
            style={{
              textTransform: 'uppercase',
              fontWeight: 600,
              cursor: 'not-allowed',
            }}
          >
            Lodging & Meal List
          </button>
          <button
            className='btn btn-success btn-sm'
            style={{ textTransform: 'uppercase', fontWeight: 600 }}
            onClick={() => navigate('itero/hotel-reservation/form')}
          >
            Lodging & Meal Form
          </button>
          <button
            className='btn btn-success btn-sm'
            style={{ textTransform: 'uppercase', fontWeight: 600 }}
            onClick={() => navigate('/itero/hotel-reservation/payment')}
          >
            Payment
          </button>
          <button
            className='btn btn-success btn-sm'
            style={{ textTransform: 'uppercase', fontWeight: 600 }}
            onClick={() => navigate('itero/hotel-reservation/transportation')}
          >
            Transportation
          </button>
        </div>

        <div
          className='fw-bold text-center mb-2'
          style={{
            fontSize: '17px',
            color: '#219119',
            textTransform: 'uppercase',
          }}
        >
          Lodging & Meal List
        </div>

        <div
          className='mb-2'
          style={{ fontWeight: 600, color: '#951a26', fontSize: '17px' }}
        >
          <i className='bi bi-exclamation-circle-fill'></i> Please make payment
          as soon as possible to confirm your room reservation. Once the event
          venue is full, your booking will be directed to the overflowing hotel.
        </div>

        <div className='d-flex justify-content-end align-items-center mb-3'>
          {/* Left Section - Summary */}
          {/* <div className='fw-bold'>
            Total - {summary.totalRegistered.toLocaleString('en-US')} (USD{' '}
            {formatNumber(summary.totalFee)}) |{' '}
            <span style={{ color: '#1e7e34' }}>
              Paid - {summary.totalPaid.toLocaleString('en-US')} (USD{' '}
              {formatNumber(summary.totalPaidFee)})
            </span>{' '}
            |{' '}
            <span style={{ color: '#856404' }}>
              Processing - {summary.totalProcessing.toLocaleString('en-US')}{' '}
              (USD {formatNumber(summary.totalProcessingFee)})
            </span>{' '}
            |{' '}
            <span style={{ color: 'red' }}>
              Unpaid - {summary.totalUnpaid.toLocaleString('en-US')} (USD{' '}
              {formatNumber(summary.totalUnpaidFee)})
            </span>
          </div> */}

          {/* Right Section - Search & Download Button */}
          <div className='d-flex align-items-center'>
            <div style={{ width: '300px' }}>
              <input
                type='text'
                className='form-control'
                placeholder='Search Name / Email / Phone'
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </div>

            <button
              className='btn btn-secondary'
              onClick={handleClearSearch}
              disabled={searchQuery.trim() === ''}
            >
              Clear
            </button>

            {/* <Tooltip title='Download Registration List'>
              <button
                className='btn btn-info ms-3'
                onClick={handleExport}
                style={{ whiteSpace: 'nowrap' }}
              >
                <i className='bi bi-file-earmark-spreadsheet'></i>
              </button>
            </Tooltip> */}
          </div>
        </div>

        <div className='table-responsive'>
          <table className='table'>
            <thead>
              <tr>
                <th>Name</th>
                <th>Email/Phone</th>
                <th>Passport</th>
                <th className='text-center'>Age</th>
                <th>Package</th>
                <th className='text-end' style={{ whiteSpace: 'nowrap' }}>
                  Fee (USD)
                </th>
                <th className='text-center'>Status</th>
                <th style={{ whiteSpace: 'nowrap' }}>Pairing Code/Roommate</th>
                <th className='text-end' style={{ whiteSpace: 'nowrap' }}>
                  Register Date
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan='10' className='text-center'>
                    <div>
                      <span>
                        <LoadingSpinner />
                      </span>
                    </div>
                  </td>
                </tr>
              ) : records.length > 0 ? (
                records.flatMap((reg, index) => [
                  <React.Fragment key={reg._id}>
                    <tr
                      style={{
                        borderColor:
                          reg.spouseInfo ||
                          reg.remark ||
                          reg.meeting ||
                          reg.churchBlending === 1 ||
                          reg.churchBlendingSabah === 1 ||
                          reg.churchBlendingSarawak === 1 ||
                          reg.extraOneNight === 1 ||
                          reg.extraTwoNight === 1
                            ? 'white'
                            : '#219119',
                      }}
                    >
                      <td className='fw-bold'>
                        <div
                          style={{
                            textTransform: 'uppercase',
                            color: reg.gender === 1 ? '#2f4483' : '#78003c',
                          }}
                        >
                          {reg.name}
                        </div>
                      </td>
                      <td>
                        {reg.email && (
                          <div style={{ whiteSpace: 'nowrap' }}>
                            <i
                              className='bi bi-envelope-fill me-2'
                              style={{ color: '#7a6844' }}
                            ></i>
                            {reg.email}
                          </div>
                        )}
                        {reg.phoneNo && (
                          <div style={{ whiteSpace: 'nowrap' }}>
                            <i
                              className='bi bi-telephone-fill me-2'
                              style={{ color: '#7a6844' }}
                            ></i>
                            ({reg.phoneCode}) {reg.phoneNo}
                          </div>
                        )}
                      </td>
                      <td>{reg.icNo}</td>
                      <td className='text-center'>{reg.age}</td>
                      <td>
                        {reg.packageNmEn} {reg.bedName && `(${reg.bedName})`}
                        {reg.mealPackage !== 1 && (
                          <div>
                            <span style={{ color: '#7a6844', fontWeight: 600 }}>
                              Meal:
                            </span>{' '}
                            {
                              [
                                'No Preference',
                                'Vegan',
                                'Gluten Free',
                                'Vegan & Gluten Free',
                              ][reg.mealPackage - 1]
                            }
                          </div>
                        )}
                      </td>
                      <td className='text-end'>
                        {(() => {
                          const extraFee =
                            (reg.extraOneNight ? 70 : 0) +
                            (reg.extraTwoNight ? 140 : 0) +
                            (reg.churchBlending
                              ? reg.spouseInfo
                                ? 700
                                : 350
                              : 0) +
                            (reg.churchBlendingSabah
                              ? reg.spouseInfo
                                ? 900
                                : 450
                              : 0) +
                            (reg.churchBlendingSarawak
                              ? reg.spouseInfo
                                ? 900
                                : 450
                              : 0)

                          const baseFee = reg.feeUsd || 0
                          return (
                            <>
                              {baseFee.toFixed(2)}
                              {extraFee > 0 && (
                                <Tooltip title='Extra Charges'>
                                  <span style={{ cursor: 'help' }}>
                                    {' '}
                                    (+{extraFee.toFixed(2)})
                                  </span>
                                </Tooltip>
                              )}
                            </>
                          )
                        })()}
                      </td>

                      <td className='text-center'>
                        <Tooltip
                          title={
                            reg.status === 1
                              ? 'Paid & Confirmed'
                              : reg.status === 2
                              ? 'Pending Payment'
                              : 'Processing'
                          }
                        >
                          <span
                            style={{
                              display: 'inline-block',
                              width: '14px',
                              height: '14px',
                              borderRadius: '50%',
                              backgroundColor:
                                reg.status === 1
                                  ? '#1e7e34'
                                  : reg.status === 2
                                  ? 'red'
                                  : '#ba8b00',
                              marginRight: '8px',
                              cursor: 'pointer',
                            }}
                          ></span>
                        </Tooltip>
                      </td>

                      <td>
                        {reg.packageCapacity === 2 &&
                          !reg.spouseInfo &&
                          reg.status === 1 &&
                          reg.confirmCode !== 0 && (
                            <>
                              <span>{reg.confirmCode}</span>

                              {reg.completeRoom === 0 ? (
                                <span className='ms-1'>
                                  <i
                                    className='bi bi-person-fill-add'
                                    style={{
                                      color: '#7a6844',
                                      cursor: 'pointer',
                                    }}
                                    onClick={() => handlePairingCheck(reg)}
                                  ></i>
                                </span>
                              ) : reg.completeRoom === 1 ? (
                                <div>
                                  Pair with:{' '}
                                  <span
                                    style={{
                                      textTransform: 'uppercase',
                                      fontWeight: 600,
                                    }}
                                  >
                                    {reg.pairingName}
                                  </span>
                                </div>
                              ) : null}
                            </>
                          )}
                      </td>

                      <td className='text-end'>
                        {(() => {
                          const dateObj = new Date(reg.createdAt)
                          const formattedDate = dateObj.toLocaleDateString(
                            'en-GB',
                            {
                              day: '2-digit',
                              month: '2-digit',
                              year: 'numeric',
                            }
                          )
                          const formattedTime = dateObj
                            .toISOString()
                            .slice(11, 19)
                          return (
                            <div>
                              <div>{formattedDate}</div>
                              <div>{formattedTime}</div>
                            </div>
                          )
                        })()}
                      </td>
                      <td className='text-end'>
                        <div>
                          <i
                            className='bi bi-pencil-square text-primary'
                            style={{ cursor: 'pointer' }}
                            onClick={() =>
                              navigate(
                                `/itero/hotel-reservation/group/edit/${reg._id}`
                              )
                            }
                          ></i>

                          {reg.status === 2 ? (
                            <Tooltip title='Delete Record'>
                              <span>
                                <i
                                  className='bi bi-trash3-fill ms-3'
                                  style={{
                                    cursor: 'pointer',
                                    color: 'red',
                                  }}
                                  onClick={() => handleDelete(reg._id)}
                                ></i>
                              </span>
                            </Tooltip>
                          ) : (
                            <span>
                              <i
                                className='bi bi-trash3-fill ms-3'
                                style={{
                                  cursor: 'not-allowed',
                                  color: 'grey',
                                }}
                              ></i>
                            </span>
                          )}
                        </div>
                      </td>
                    </tr>

                    {/* Remark Row */}
                    {reg.remark && (
                      <tr
                        style={{
                          borderColor:
                            reg.spouseInfo || reg.meeting ? 'white' : '#219119',
                        }}
                      >
                        <td colSpan='10' className='pt-0'>
                          <span className='me-2' style={{ fontWeight: 600 }}>
                            Remark:
                          </span>
                          {reg.remark}
                        </td>
                      </tr>
                    )}
                    {/* Additional Participation Info */}
                    {(reg.meeting ||
                      reg.churchBlending === 1 ||
                      reg.churchBlendingSabah === 1 ||
                      reg.churchBlendingSarawak === 1 ||
                      reg.extraOneNight === 1 ||
                      reg.extraTwoNight === 1) && (
                      <tr>
                        <td colSpan='10' className='pt-0'>
                          <div>
                            {reg.meeting === 1 && (
                              <label className='d-block'>
                                Lord's Day Morning Blending Meeting: Attending
                              </label>
                            )}

                            <div className='mt-1'>
                              {reg.churchBlending === 1 && (
                                <div>
                                  <i className='bi bi-check-circle-fill me-1 text-success'></i>{' '}
                                  3 days 2 nights trip within Peninsular
                                  Malaysia from September 28-30, 2025 ($350.00
                                  USD per person)
                                </div>
                              )}
                              {reg.churchBlendingSabah === 1 && (
                                <div>
                                  <i className='bi bi-check-circle-fill me-1 text-success'></i>{' '}
                                  4 days 3 nights trip within Sabah from
                                  September 28 – October 1, 2025 ($450.00 USD
                                  per person)
                                </div>
                              )}
                              {reg.churchBlendingSarawak === 1 && (
                                <div>
                                  <i className='bi bi-check-circle-fill me-1 text-success'></i>{' '}
                                  4 days 3 nights trip within Sarawak from
                                  September 28 – October 1, 2025 ($450.00 USD
                                  per person)
                                </div>
                              )}
                              {reg.extraOneNight === 1 && (
                                <div>
                                  <i className='bi bi-check-circle-fill me-1 text-success'></i>{' '}
                                  One Additional Night ($70.00 USD per room)
                                </div>
                              )}
                              {reg.extraTwoNight === 1 && (
                                <div>
                                  <i className='bi bi-check-circle-fill me-1 text-success'></i>{' '}
                                  Two Additional Nights ($140.00 USD per room)
                                </div>
                              )}
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}

                    {/* Spouse Info Row */}
                    {reg.spouseInfo && (
                      <tr
                        key={`${reg._id}-spouse`}
                        style={{
                          borderColor:
                            reg.spouseInfo.remark || reg.spouseInfo.meeting
                              ? 'white'
                              : '#219119',
                        }}
                      >
                        <td>
                          <span
                            className='fw-bold'
                            style={{
                              textTransform: 'uppercase',
                              color:
                                reg.spouseInfo.gender === 1
                                  ? '#2f4483'
                                  : '#78003c',
                            }}
                          >
                            {reg.spouseInfo.name}
                          </span>
                        </td>
                        <td>
                          {reg.spouseInfo.email && (
                            <div style={{ whiteSpace: 'nowrap' }}>
                              <i
                                className='bi bi-envelope-fill me-2'
                                style={{ color: '#7a6844' }}
                              ></i>
                              {reg.spouseInfo.email}
                            </div>
                          )}
                          {reg.spouseInfo.phoneNo && (
                            <div>
                              <i
                                className='bi bi-telephone-fill me-2'
                                style={{ color: '#7a6844' }}
                              ></i>
                              ({reg.spouseInfo.phoneCode}){' '}
                              {reg.spouseInfo.phoneNo}
                            </div>
                          )}
                        </td>
                        <td>{reg.spouseInfo.icNo}</td>
                        <td className='text-center'>{reg.spouseInfo.age}</td>
                        <td>
                          {reg.spouseInfo.mealPackage !== 1 && (
                            <div>
                              <span
                                style={{ color: '#7a6844', fontWeight: 600 }}
                              >
                                Meal:
                              </span>{' '}
                              {
                                [
                                  'No Preference',
                                  'Vegan',
                                  'Gluten Free',
                                  'Vegan & Gluten Free',
                                ][reg.spouseInfo.mealPackage - 1]
                              }
                            </div>
                          )}
                        </td>
                        <td colSpan={4}></td>
                        <td colSpan={3}></td>
                      </tr>
                    )}

                    {/* Spouse Remark Row */}
                    {reg.spouseInfo?.remark && (
                      <tr
                        style={{
                          borderColor: reg.spouseInfo.meeting
                            ? 'white'
                            : '#219119',
                        }}
                      >
                        <td colSpan='10' className='pt-0'>
                          <span className='me-2' style={{ fontWeight: 600 }}>
                            Spouse Remark:
                          </span>
                          {reg.spouseInfo.remark}
                        </td>
                      </tr>
                    )}

                    {/* Spouse Meeting Row */}
                    {reg.spouseInfo?.meeting && (
                      <tr>
                        <td colSpan='10' className='pt-0'>
                          <span className='me-2'>
                            Lord's Day Morning Blending Meeting: Attending
                          </span>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>,
                ])
              ) : (
                <tr>
                  <td colSpan='10' className='text-center'>
                    No Records
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {totalPages > 1 && (
          <div className='d-flex justify-content-center mt-2 mb-5'>
            <button
              className='btn btn-outline-primary btn-sm me-2'
              disabled={page === 1}
              onClick={() => setPage(page - 1)}
            >
              Previous
            </button>

            {[...Array(totalPages)].map((_, index) => (
              <button
                key={index + 1}
                className={`btn btn-sm mx-1 px-2 ${
                  page === index + 1 ? 'btn-primary' : 'btn-outline-primary'
                }`}
                onClick={() => setPage(index + 1)}
              >
                {index + 1}
              </button>
            ))}

            <button
              className='btn btn-outline-primary btn-sm ms-2'
              disabled={page === totalPages}
              onClick={() => setPage(page + 1)}
            >
              Next
            </button>
          </div>
        )}

        <Modal open={isModalOpen} onCancel={handleCloseModal} footer={null}>
          <IntlRoomPair
            record={selectedRecord}
            onClose={handleCloseModal}
            onUpdate={handlePairingUpdate}
          />
        </Modal>
      </div>
    </Layout>
  )
}

export default IntlRegistrationList
