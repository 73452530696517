import React, { useEffect, useState } from 'react'
import Layout from '../../../components/Layout/Layout'
import PageTitle from '../../../components/shared/PageTitle'
import { Link, useParams } from 'react-router-dom'
import axios from 'axios'
import Select from 'react-select'
import { toast } from 'react-toastify'

const AdminEditUser = () => {
  const { id } = useParams()
  const [localityOptions, setLocalityOptions] = useState([])
  const [eventInfo, setEventInfo] = useState({ total: 0 })

  const [form, setForm] = useState({
    gender: 'sister',
    englishName: '',
    chineseName: '',
    email: '',
    phoneNo: '',
    ic: '',
    dob: '',
    locality: '',
    role: 'user',
    language: 'en',
    newsletter: 'subscribe',
    emailBounced: false,
  })

  useEffect(() => {
    fetchLocalities() // ✅ fetch once on mount

    const fetchUser = async () => {
      try {
        const res = await axios.get(`/api/v1/admin/user/${id}`)
        setForm({
          englishName: res.data.name,
          chineseName: res.data.chiName,
          email: res.data.email,
          phoneNo: res.data.phoneNo,
          ic: res.data.icNo,
          dob: res.data.dob?.slice(0, 10) || '',
          gender: res.data.gender === 1 ? 'brother' : 'sister',
          locality: res.data.locality?._id,
          role: res.data.role === 1 ? 'admin' : 'user',
          language:
            res.data.language === 2
              ? 'bm'
              : res.data.language === 1
              ? 'chi'
              : 'en',
          newsletter: res.data.newsletter === 0 ? 'unsubscribe' : 'subscribe',
          emailBounced: res.data.newsletterBounce === 1,
        })
      } catch (err) {
        console.error('Error fetching user:', err)
        alert('Failed to fetch user data.')
      }
    }

    fetchUser()
  }, [id])

  useEffect(() => {
    const fetchEventInfo = async () => {
      try {
        const res = await axios.get(`/api/v1/admin/user/${id}/active-events`)
        setEventInfo(res.data)
      } catch (err) {
        console.error('Failed to load user event info:', err)
      }
    }

    if (id) fetchEventInfo()
  }, [id])

  const handleResetPassword = async () => {
    const confirmed = window.confirm('Confirm reset password?')
    if (!confirmed) return

    try {
      await axios.put(`/api/v1/admin/user/${id}/reset-password`)
      alert('Password has been reset to "event12".')
    } catch (err) {
      console.error('Password reset failed:', err)
      toast.error('Failed to reset password.')
    }
  }

  const fetchLocalities = async () => {
    if (localityOptions.length > 0) return
    try {
      const res = await axios.get('/api/v1/admin/user/locality-list')
      const options = res.data.map((loc) => ({
        value: loc._id,
        label: loc.name,
      }))
      setLocalityOptions(options)
    } catch (err) {
      console.error('Failed to load localities:', err)
    }
  }

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target
    setForm((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    const confirmed = window.confirm('Confirm update user?')
    if (!confirmed) return

    const updatedData = {
      name: form.englishName,
      chiName: form.chineseName,
      email: form.email,
      phoneNo: form.phoneNo,
      icNo: form.ic,
      dob: form.dob,
      gender: form.gender === 'brother' ? 1 : 2,
      role: form.role === 'admin' ? 1 : 2,
      language: form.language === 'bm' ? 2 : form.language === 'chi' ? 1 : 0,
      newsletter: form.newsletter === 'subscribe' ? 1 : 0,
      newsletterBounce: form.emailBounced ? 1 : 0,
      locality: form.locality,
    }

    try {
      await axios.put(`/api/v1/admin/user/${id}`, updatedData)
      toast.success('User updated successfully!')
    } catch (err) {
      console.error('Update failed:', err)
      toast.error('Failed to update user.')
    }
  }

  return (
    <Layout title='Edit User'>
      <div className='py-3 px-2'>
        <div className='position-relative mb-3'>
          {/* Return button - left aligned */}
          <div className='position-absolute start-0 top-0'>
            <Link to='/admin/personal-account'>
              <button type='button' className='btn btn-secondary btn-sm'>
                <i className='bi bi-arrow-left-circle'></i> Return
              </button>
            </Link>
          </div>

          {/* PageTitle - center aligned */}
          <div className='text-center'>
            <PageTitle title='Edit User' />
          </div>
        </div>

        <form className='card p-4' onSubmit={handleSubmit}>
          {/* Gender: full row */}
          <div className='row mb-3'>
            <div className='col'>
              <label className='form-label me-3'>Gender</label>
              {['brother', 'sister'].map((g) => (
                <div className='form-check form-check-inline' key={g}>
                  <input
                    className='form-check-input'
                    type='radio'
                    name='gender'
                    value={g}
                    checked={form.gender === g}
                    onChange={handleChange}
                  />
                  <label className='form-check-label'>
                    {g.charAt(0).toUpperCase() + g.slice(1)}
                  </label>
                </div>
              ))}
            </div>
          </div>

          {/* Grid row 1 */}
          <div className='row'>
            <div className='col-md-3 mb-3'>
              <label className='form-label'>English Name</label>
              <input
                type='text'
                className='form-control'
                name='englishName'
                value={form.englishName}
                onChange={handleChange}
              />
            </div>
            <div className='col-md-3 mb-3'>
              <label className='form-label'>Chinese Name</label>
              <input
                type='text'
                className='form-control'
                name='chineseName'
                value={form.chineseName}
                onChange={handleChange}
              />
            </div>
            <div className='col-md-3 mb-3'>
              <label className='form-label'>Email</label>
              <input
                type='email'
                className='form-control'
                name='email'
                value={form.email}
                onChange={handleChange}
              />
            </div>
            <div className='col-md-3 mb-3'>
              <label className='form-label'>Phone Number</label>
              <input
                type='text'
                className='form-control'
                name='phoneNo'
                value={form.phoneNo}
                onChange={handleChange}
              />
            </div>
          </div>

          {/* Grid row 2 */}
          <div className='row'>
            <div className='col-md-3 mb-3'>
              <label className='form-label'>IC Number</label>
              <input
                type='text'
                className='form-control'
                name='ic'
                value={form.ic}
                onChange={handleChange}
              />
            </div>
            <div className='col-md-3 mb-3'>
              <label className='form-label'>Date of Birth</label>
              <input
                type='date'
                className='form-control'
                name='dob'
                value={form.dob}
                onChange={handleChange}
              />
            </div>
            <div className='col-md-3 mb-3'>
              <label className='form-label'>Locality</label>
              <Select
                options={localityOptions}
                value={
                  localityOptions.find((o) => o.value === form.locality) || null
                }
                onChange={(selected) => {
                  setForm((prev) => ({
                    ...prev,
                    locality: selected?.value || '',
                  }))
                }}
                placeholder='Select locality...'
                isClearable
              />
            </div>
          </div>

          {/* Grid row 3 */}
          <div className='row'>
            <div className='col-md-3 mb-3'>
              <label className='form-label'>Role</label>
              <div>
                {['admin', 'user'].map((role) => (
                  <div className='form-check form-check-inline' key={role}>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='role'
                      value={role}
                      checked={form.role === role}
                      onChange={handleChange}
                    />
                    <label className='form-check-label text-capitalize'>
                      {role}
                    </label>
                  </div>
                ))}
              </div>
            </div>

            <div className='col-md-3 mb-3'>
              <label className='form-label'>Language</label>
              <div>
                {['en', 'chi', 'bm'].map((lang) => (
                  <div className='form-check form-check-inline' key={lang}>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='language'
                      value={lang}
                      checked={form.language === lang}
                      onChange={handleChange}
                    />
                    <label className='form-check-label text-uppercase'>
                      {lang}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <hr />
          <div className='col-md-12 mb-3'>
            <div className='mb-2' style={{ fontWeight: '600' }}>
              Additional Info:
            </div>
            {eventInfo.total > 0 ? (
              <>
                <div className='mb-1'>
                  User has <strong>{eventInfo.total}</strong> registered active
                  event{eventInfo.total > 1 ? 's' : ''}.
                </div>
                <div>
                  Latest:{' '}
                  <strong>
                    {eventInfo.latestEventName} (
                    {eventInfo.latestEventDate?.slice(0, 10)})
                  </strong>
                  , locality: <strong>{eventInfo.latestEventLocality}</strong>.
                </div>
              </>
            ) : (
              <p className='text-muted'>No upcoming registered events.</p>
            )}
          </div>

          {/* Submit Button */}
          <div className='col-md-12 mb-3 d-flex justify-content-end'>
            <button
              type='button'
              className='btn btn-danger me-4'
              onClick={handleResetPassword}
            >
              Reset Password
            </button>

            <button type='submit' className='btn btn-warning'>
              Update
            </button>
          </div>
        </form>
      </div>
    </Layout>
  )
}

export default AdminEditUser
