import React from 'react'
import TitleModal from '../../components/shared/TitleModal'

const IteroLoginGuide = ({ onCancel }) => {
  return (
    <>
      <div>
        <TitleModal title='Login Guidelines' />
      </div>
      <div>
        <ol style={{ paddingLeft: '10px', fontSize: '17px' }}>
          <li>
            For first-time login, please use your LSM Attendee ID for individual
            login, and your LSM username for group login.
          </li>
        </ol>
      </div>
      <div className='text-center'>
        <button className='close-btn' onClick={onCancel}>
          Close
        </button>
      </div>
    </>
  )
}

export default IteroLoginGuide
