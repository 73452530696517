import React from 'react'
import Select from 'react-select'
import countryPhoneCodes from '../../../utils/countryPhoneCodes'
import { useNavigate } from 'react-router-dom'

const IntlRegisterForm = ({
  formData,
  stayPackages,
  handleFieldChange,
  handleSubmit,
  errors,
  isSubmitting,
  mode = 'create',
}) => {
  const navigate = useNavigate()

  const eligiblePackages =
    mode === 'edit'
      ? formData.status === 1 && !formData.oriPackageFee
        ? stayPackages.filter(
            (pkg) => Number(pkg.feeUsd) >= Number(formData.feeUsd || 0)
          )
        : Number(formData.oriPackageFee || 0) > 0
        ? stayPackages.filter(
            (pkg) => Number(pkg.feeUsd) >= Number(formData.oriPackageFee)
          )
        : stayPackages
      : stayPackages

  return (
    <div className='mb-3'>
      <div
        className='p-3 mb-3 rounded bg-white shadow-sm'
        style={{
          border: '1px solid #a89163',
          boxShadow: '0 0 10px rgba(217, 83, 79, 0.5)',
        }}
      >
        {/* === Register For: Myself / Others === */}
        <div className='mb-3'>
          <label className='form-label me-3'>
            Register for <span className='require'>*</span>
          </label>

          <div className='form-check form-check-inline'>
            <input
              className='form-check-input'
              type='radio'
              name='onBehalf'
              id='onBehalfMyself'
              value='0'
              checked={formData.onBehalf === '0'}
              onChange={() => handleFieldChange('onBehalf', '0')}
              style={{
                cursor: 'pointer',
              }}
            />
            <label
              className='form-check-label'
              htmlFor='onBehalfMyself'
              style={{
                cursor: 'pointer',
                transition: 'color 0.2s ease',
              }}
              onMouseEnter={(e) => (e.target.style.color = '#006ba1')}
              onMouseLeave={(e) => (e.target.style.color = '')}
            >
              Myself
            </label>
          </div>

          <div className='form-check form-check-inline'>
            <input
              className='form-check-input'
              type='radio'
              name='onBehalf'
              id='onBehalfOthers'
              value='1'
              checked={formData.onBehalf === '1'}
              onChange={() => handleFieldChange('onBehalf', '1')}
              style={{
                cursor: 'pointer',
              }}
            />
            <label
              className='form-check-label'
              htmlFor='onBehalfOthers'
              style={{
                cursor: 'pointer',
                transition: 'color 0.2s ease',
              }}
              onMouseEnter={(e) => (e.target.style.color = '#006ba1')}
              onMouseLeave={(e) => (e.target.style.color = '')}
            >
              Others
            </label>
          </div>
          {errors.onBehalf && (
            <div className='text-danger mt-1' style={{ fontSize: '14px' }}>
              {errors.onBehalf}
            </div>
          )}
        </div>

        {/* First Row */}
        <div className='row g-3'>
          <div className='col-md-3 col-sm-6'>
            <label className='form-label'>Name</label>
            <input
              type='text'
              className='form-control'
              value={formData.name}
              disabled
              style={{
                backgroundColor: '#fcfcfc',
                color: '#006ba1',
                fontWeight: '600',
              }}
            />
          </div>
          <div className='col-md-2 col-sm-6'>
            <label className='form-label'>Email</label>
            <input
              type='email'
              className='form-control'
              value={formData.email}
              disabled
              style={{
                backgroundColor: '#fcfcfc',
                color: '#006ba1',
                fontWeight: '600',
              }}
            />
          </div>
          <div className='col-md-2 col-sm-6'>
            <label className='form-label'>
              Passport <span className='require'>*</span>
            </label>
            <input
              type='text'
              className={`form-control ${errors.icNo ? 'is-invalid' : ''}`}
              value={formData.icNo}
              onChange={(e) => handleFieldChange('icNo', e.target.value)}
            />
            {errors.icNo && (
              <div className='invalid-feedback'>{errors.icNo}</div>
            )}
          </div>

          <div className='col-md-1 col-sm-6'>
            <label className='form-label'>
              Age <span className='require'>*</span>
            </label>
            <input
              type='text'
              className={`form-control ${errors.age ? 'is-invalid' : ''}`}
              value={formData.age}
              onChange={(e) => handleFieldChange('age', e.target.value)}
              inputMode='numeric'
              pattern='[0-9]{1,2}'
              maxLength='2'
            />

            {errors.age && <div className='invalid-feedback'>{errors.age}</div>}
          </div>

          <div className='col-md-4 col-sm-6'>
            <label className='form-label'>
              Phone Number <span className='require'>*</span>
            </label>
            <div className='d-flex align-items-center'>
              <div style={{ width: '50%' }}>
                <Select
                  options={countryPhoneCodes}
                  isSearchable
                  placeholder='+Code'
                  value={formData.phoneCode}
                  onChange={(selected) =>
                    handleFieldChange('phoneCode', selected)
                  }
                  styles={{
                    menu: (provided) => ({
                      ...provided,
                      width: '200px',
                    }),
                    control: (provided) => ({
                      ...provided,
                      minWidth: '110px',
                      borderColor:
                        errors.phoneCode || errors.phoneNo
                          ? 'red'
                          : provided.borderColor,
                      '&:hover': {
                        borderColor:
                          errors.phoneCode || errors.phoneNo
                            ? 'red'
                            : provided.borderColor,
                      },
                    }),
                  }}
                  menuPortalTarget={document.body}
                />
              </div>
              <input
                type='text'
                inputMode='numeric'
                pattern='[0-9]*'
                className={`form-control ${
                  errors.phoneNo || errors.phoneCode ? 'is-invalid' : ''
                }`}
                value={formData.phoneNo}
                onChange={(e) => {
                  const value = e.target.value.replace(/\D/g, '')
                  handleFieldChange('phoneNo', value)
                }}
              />
            </div>
            <div
              className='mt-1'
              style={{ fontWeight: '600', fontSize: '15px', color: '#0056b3' }}
            >
              <i className='bi bi-exclamation-circle-fill'></i> Remember the
              last 6 digits for next login.
            </div>
            {(errors.phoneCode || errors.phoneNo) && (
              <div className='text-danger mt-1' style={{ fontSize: '14px' }}>
                {errors.phoneNo
                  ? errors.phoneNo
                  : 'Phone number and country code are required'}
              </div>
            )}
          </div>
        </div>

        {/* Second Row */}
        <div className='row g-3 mt-1'>
          <div className='col-md-4 col-sm-12'>
            <label className='form-label'>
              Package <span className='require'>*</span>
            </label>
            <select
              className={`form-select ${errors.package ? 'is-invalid' : ''}`}
              value={formData.package}
              onChange={(e) => handleFieldChange('package', e.target.value)}
              disabled={mode === 'edit' && formData.status === 3}
            >
              {mode !== 'edit' && <option value=''>Select a Package</option>}
              {eligiblePackages.map((pkg) => (
                <option key={pkg.pkgId} value={pkg.pkgId}>
                  {pkg.packageNmEn}
                </option>
              ))}
            </select>
            {mode === 'edit' && formData.status === 3 && (
              <div
                className='text-danger mt-1'
                style={{ fontSize: '15px', fontWeight: '600' }}
              >
                Package editing or upgrading is disabled until payment is
                confirmed.
              </div>
            )}

            {errors.package && (
              <div className='invalid-feedback'>{errors.package}</div>
            )}
          </div>
          <div className='col-md-1 col-sm-6'>
            <label className='form-label' style={{ whiteSpace: 'nowrap' }}>
              Fee (USD)
            </label>
            <input
              type='text'
              className='form-control'
              value={formData.feeUsd ? Number(formData.feeUsd).toFixed(2) : ''}
              disabled
            />
          </div>
          <div className='col-md-2 col-sm-6'>
            <label className='form-label'>Meal Package</label>
            <select
              className='form-control'
              value={formData.mealPackage}
              onChange={(e) => handleFieldChange('mealPackage', e.target.value)}
            >
              <option value='1'>No Preference</option>
              <option value='2'>Vegan</option>
              <option value='3'>Gluten Free</option>
              <option value='4'>Vegan & Gluten Free</option>
            </select>
          </div>
          <div className='col-md-5 col-sm-6'>
            <label className='form-label'>Remark</label>
            <input
              type='text'
              className='form-control'
              value={formData.remark}
              onChange={(e) => handleFieldChange('remark', e.target.value)}
            />
          </div>
          {/* === Row 1: Lord's Day Morning Blending Meeting === */}
          <div className='row mt-4' style={{ fontWeight: '600' }}>
            <div className='col-md-6 col-sm-12 d-flex align-items-center'>
              <input
                type='checkbox'
                id='blendingMeeting'
                checked={formData.meeting}
                onChange={(e) => handleFieldChange('meeting', e.target.checked)}
                style={{
                  transform: 'scale(1.3)',
                  cursor: 'pointer',
                  marginRight: '8px',
                }}
              />
              <label
                htmlFor='blendingMeeting'
                className='mb-0'
                style={{ cursor: 'pointer' }}
              >
                Attending Lord's Day Morning Blending Meeting
              </label>
            </div>
          </div>
          {/* === Row 2: Churching and Blending Trip === */}
          {mode === 'edit' && formData.status === 3 && (
            <div
              className='text-danger'
              style={{ fontSize: '15px', fontWeight: '600' }}
            >
              Add-on editing is disabled until payment is confirmed.
            </div>
          )}

          <div className='row mt-3 ms-2' style={{ color: '#006ba1' }}>
            <label className='form-label'>Churching and Blending Trip</label>
          </div>
          <div className='row mt-1' style={{ fontWeight: '600' }}>
            <div className='col-md-12 col-sm-12 d-flex align-items-center'>
              <input
                type='checkbox'
                id='churchBlending'
                checked={formData.churchBlending || false}
                onChange={(e) =>
                  handleFieldChange('churchBlending', e.target.checked)
                }
                disabled={
                  mode === 'edit' &&
                  (formData.status === 3 || formData.churchBlendingPaid === 1)
                }
                style={{
                  transform: 'scale(1.3)',
                  cursor:
                    mode === 'edit' && formData.status === 3
                      ? 'not-allowed'
                      : 'pointer',
                  marginRight: '8px',
                }}
              />
              <label
                htmlFor='churchBlending'
                className='mb-0'
                style={{
                  cursor:
                    mode === 'edit' && formData.status === 3
                      ? 'not-allowed'
                      : 'pointer',
                }}
              >
                3 days 2 nights trip within Peninsular Malaysia from September
                28-30, 2025 ($350.00 USD per person)
              </label>
            </div>
          </div>
          <div className='row mt-2' style={{ fontWeight: '600' }}>
            <div className='col-md-12 col-sm-12 d-flex align-items-center'>
              <input
                type='checkbox'
                id='churchBlendingSabah'
                checked={formData.churchBlendingSabah || false}
                onChange={(e) =>
                  handleFieldChange('churchBlendingSabah', e.target.checked)
                }
                disabled={
                  mode === 'edit' &&
                  (formData.status === 3 ||
                    formData.churchBlendingSabahPaid === 1)
                }
                style={{
                  transform: 'scale(1.3)',
                  cursor:
                    mode === 'edit' && formData.status === 3
                      ? 'not-allowed'
                      : 'pointer',
                  marginRight: '8px',
                }}
              />
              <label
                htmlFor='churchBlendingSabah'
                className='mb-0'
                style={{
                  cursor:
                    mode === 'edit' && formData.status === 3
                      ? 'not-allowed'
                      : 'pointer',
                }}
              >
                4 days 3 nights trip within Sabah from September 28 – October 1,
                2025 ($450.00 USD per person)
              </label>
            </div>
          </div>
          <div className='row mt-2' style={{ fontWeight: '600' }}>
            <div className='col-md-12 col-sm-12 d-flex align-items-center'>
              <input
                type='checkbox'
                id='churchBlendingSarawak'
                checked={formData.churchBlendingSarawak || false}
                onChange={(e) =>
                  handleFieldChange('churchBlendingSarawak', e.target.checked)
                }
                disabled={
                  mode === 'edit' &&
                  (formData.status === 3 ||
                    formData.churchBlendingSarawakPaid === 1)
                }
                style={{
                  transform: 'scale(1.3)',
                  cursor:
                    mode === 'edit' && formData.status === 3
                      ? 'not-allowed'
                      : 'pointer',
                  marginRight: '8px',
                }}
              />
              <label
                htmlFor='churchBlendingSarawak'
                className='mb-0'
                style={{
                  cursor:
                    mode === 'edit' && formData.status === 3
                      ? 'not-allowed'
                      : 'pointer',
                }}
              >
                4 days 3 nights trip within Sarawak from September 28 – October
                1, 2025 ($450.00 USD per person)
              </label>
            </div>
          </div>

          <div className='row mt-3 ms-2' style={{ color: '#006ba1' }}>
            <label className='form-label'>
              Additional Night Stay at the Hotel
            </label>
          </div>
          <div className='row mt-1' style={{ fontWeight: '600' }}>
            <div className='col-md-6 col-sm-12 d-flex align-items-center'>
              <input
                type='checkbox'
                id='extraOneNight'
                checked={formData.extraOneNight || false}
                onChange={(e) =>
                  handleFieldChange('extraOneNight', e.target.checked)
                }
                disabled={
                  mode === 'edit' &&
                  (formData.status === 3 || formData.extraOnePaid === 1)
                }
                style={{
                  transform: 'scale(1.3)',
                  cursor:
                    mode === 'edit' && formData.status === 3
                      ? 'not-allowed'
                      : 'pointer',
                  marginRight: '8px',
                }}
              />
              <label
                htmlFor='extraOneNight'
                className='mb-0'
                style={{
                  cursor:
                    mode === 'edit' && formData.status === 3
                      ? 'not-allowed'
                      : 'pointer',
                }}
              >
                One Additional Night ($70.00 USD per room)
              </label>
            </div>
          </div>
          <div className='row mt-2' style={{ fontWeight: '600' }}>
            <div className='col-md-6 col-sm-12 d-flex align-items-center'>
              <input
                type='checkbox'
                id='extraTwoNight'
                checked={formData.extraTwoNight || false}
                onChange={(e) =>
                  handleFieldChange('extraTwoNight', e.target.checked)
                }
                disabled={
                  mode === 'edit' &&
                  (formData.status === 3 || formData.extraTwoPaid === 1)
                }
                style={{
                  transform: 'scale(1.3)',
                  cursor:
                    mode === 'edit' && formData.status === 3
                      ? 'not-allowed'
                      : 'pointer',
                  marginRight: '8px',
                }}
              />
              <label
                htmlFor='extraTwoNight'
                className='mb-0'
                style={{
                  cursor:
                    mode === 'edit' && formData.status === 3
                      ? 'not-allowed'
                      : 'pointer',
                }}
              >
                Two Additional Nights ($140.00 USD per room)
              </label>
            </div>
          </div>
        </div>

        {formData.spouse && (
          <>
            <hr className='my-3' />
            <h6 className='fw-bold mb-3 text-primary'>Spouse Information</h6>
            <div className='row g-3'>
              <div className='col-md-3 col-sm-6'>
                <label className='form-label'>
                  Name <span className='require'>*</span>
                </label>
                <input
                  type='text'
                  className={`form-control ${
                    errors.spouse?.name ? 'is-invalid' : ''
                  }`}
                  value={formData.spouse.name}
                  onChange={(e) =>
                    handleFieldChange('spouse.name', e.target.value)
                  }
                />
                {errors.spouse?.name && (
                  <div className='invalid-feedback'>{errors.spouse.name}</div>
                )}
              </div>

              <div className='col-md-2 col-sm-6'>
                <label className='form-label'>
                  Email <span className='require'>*</span>
                </label>
                <input
                  type='email'
                  className={`form-control ${
                    errors.spouse?.email ? 'is-invalid' : ''
                  }`}
                  value={formData.spouse.email}
                  onChange={(e) =>
                    handleFieldChange('spouse.email', e.target.value)
                  }
                />
                {errors.spouse?.email && (
                  <div className='invalid-feedback'>{errors.spouse.email}</div>
                )}
              </div>
              <div className='col-md-2 col-sm-6'>
                <label className='form-label'>
                  Passport <span className='require'>*</span>
                </label>
                <input
                  type='text'
                  className={`form-control ${
                    errors.spouse?.icNo ? 'is-invalid' : ''
                  }`}
                  value={formData.spouse.icNo}
                  onChange={(e) =>
                    handleFieldChange('spouse.icNo', e.target.value)
                  }
                />
                {errors.spouse?.icNo && (
                  <div className='invalid-feedback'>{errors.spouse.icNo}</div>
                )}
              </div>
              <div className='col-md-1 col-sm-6'>
                <label className='form-label'>
                  Age <span className='require'>*</span>
                </label>
                <input
                  type='text'
                  className={`form-control ${
                    errors.spouse?.age ? 'is-invalid' : ''
                  }`}
                  value={formData.spouse.age}
                  onChange={(e) =>
                    handleFieldChange('spouse.age', e.target.value)
                  }
                  inputMode='numeric'
                  pattern='[0-9]{1,2}'
                  maxLength='2'
                />
                {errors.spouse?.age && (
                  <div className='invalid-feedback'>{errors.spouse.age}</div>
                )}
              </div>

              <div className='col-md-4 col-sm-6'>
                <label className='form-label'>
                  Phone Number <span className='require'>*</span>
                </label>
                <div className='d-flex align-items-center'>
                  <div style={{ width: '35%' }}>
                    <Select
                      options={countryPhoneCodes}
                      value={
                        formData.spouse.phoneCode
                          ? countryPhoneCodes.find(
                              (c) => c.value === formData.spouse.phoneCode
                            )
                          : null
                      }
                      onChange={(selected) =>
                        handleFieldChange('spouse.phoneCode', selected.value)
                      }
                      isSearchable
                      placeholder='+Code'
                      styles={{
                        menu: (provided) => ({
                          ...provided,
                          width: '200px',
                        }),
                        control: (provided) => ({
                          ...provided,
                          minWidth: '110px',
                          borderColor:
                            errors.spouse?.phoneCode || errors.spouse?.phoneNo
                              ? 'red'
                              : provided.borderColor,
                          '&:hover': {
                            borderColor:
                              errors.spouse?.phoneCode || errors.spouse?.phoneNo
                                ? 'red'
                                : provided.borderColor,
                          },
                        }),
                      }}
                      menuPortalTarget={document.body}
                    />
                  </div>
                  <input
                    type='text'
                    className={`form-control ${
                      errors.spouse?.phoneNo || errors.spouse?.phoneCode
                        ? 'is-invalid'
                        : ''
                    }`}
                    value={formData.spouse.phoneNo}
                    onChange={(e) =>
                      handleFieldChange('spouse.phoneNo', e.target.value)
                    }
                  />
                </div>
                {(errors.spouse?.phoneCode || errors.spouse?.phoneNo) && (
                  <div
                    className='text-danger mt-1'
                    style={{ fontSize: '14px' }}
                  >
                    Phone number and country code are required
                  </div>
                )}
              </div>

              <div className='col-md-3 col-sm-6'>
                <label className='form-label'>Meal Package</label>
                <select
                  className='form-control'
                  value={formData.spouse.mealPackage}
                  onChange={(e) =>
                    handleFieldChange('spouse.mealPackage', e.target.value)
                  }
                >
                  <option value='1'>No Preference</option>
                  <option value='2'>Vegan</option>
                  <option value='3'>Gluten Free</option>
                  <option value='4'>Vegan & Gluten Free</option>
                </select>
              </div>

              <div className='col-md-4 col-sm-6'>
                <label className='form-label'>Remark</label>
                <input
                  type='text'
                  className='form-control'
                  value={formData.spouse.remark}
                  onChange={(e) =>
                    handleFieldChange('spouse.remark', e.target.value)
                  }
                />
              </div>

              <div
                className='col-md-5 col-sm-12 d-flex align-items-center justify-content-end mt-5'
                style={{ fontWeight: '600', gap: '8px' }}
              >
                <input
                  type='checkbox'
                  id='spouseBlendingMeeting'
                  style={{
                    transform: 'scale(1.3)',
                    cursor: 'pointer',
                  }}
                  checked={formData.spouse?.meeting || false}
                  onChange={(e) =>
                    handleFieldChange('spouse.meeting', e.target.checked)
                  }
                />
                <label
                  htmlFor='spouseBlendingMeeting'
                  className='mb-0 me-1'
                  style={{ cursor: 'pointer' }}
                >
                  Attending Lord's Day Morning Blending Meeting
                </label>
              </div>
            </div>
          </>
        )}
      </div>

      <div className='d-flex justify-content-center mt-3'>
        <button
          className={`btn ${mode === 'edit' ? 'btn-warning' : 'btn-primary'}`}
          onClick={handleSubmit}
          disabled={isSubmitting}
        >
          {isSubmitting ? (
            <span className='spinner-border spinner-border-sm'></span>
          ) : mode === 'edit' ? (
            'Update'
          ) : (
            <>
              Submit <i className='bi bi-send'></i>
            </>
          )}
        </button>

        {mode === 'edit' && (
          <button
            className='btn btn-secondary ms-4'
            onClick={() => navigate('/')}
            type='button'
          >
            Cancel
          </button>
        )}
      </div>
    </div>
  )
}

export default IntlRegisterForm
