import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Select from 'react-select'
import countryPhoneCodes from './../../../utils/countryPhoneCodes'
import { Tooltip } from 'antd'

const IntlRegistrationStayForm = ({ numParticipants }) => {
  const navigate = useNavigate()
  const [stayPackages, setStayPackages] = useState([])
  const [participantForms, setParticipantForms] = useState([])
  const [loading, setLoading] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [errors, setErrors] = useState([])
  const [checkingAttendee, setCheckingAttendee] = useState({})

  useEffect(() => {
    const fetchStayPackages = async () => {
      setLoading(true)
      try {
        const response = await axios.get('/api/v1/intl/form', {
          withCredentials: true,
        })
        setStayPackages(response.data)
      } catch (error) {
        console.error('Error fetching stay-in packages:', error)
      } finally {
        setLoading(false)
      }
    }

    fetchStayPackages()
  }, [])

  useEffect(() => {
    if (numParticipants > 0) {
      setParticipantForms(
        Array.from({ length: numParticipants }).map(() => ({
          name: '',
          email: '',
          phoneNo: '',
          phoneCode: '',
          age: '',
          icNo: '',
          remark: '',
          package: '',
          feeUsd: '',
          mealPackage: '1',
          spouse: null,
          isVerified: false,
          meeting: false,
          extraOneNight: false,
          extraTwoNight: false,
          churchBlending: false,
          churchBlendingSabah: false,
          churchBlendingSarawak: false,
        }))
      )
      setErrors(Array.from({ length: numParticipants }).map(() => ({})))
    }
  }, [numParticipants])

  const handleFieldChange = (index, field, value) => {
    setParticipantForms((prevForms) =>
      prevForms.map((form, i) => {
        if (i === index) {
          let updatedForm = { ...form }

          if (field === 'age') {
            updatedForm.age = value.replace(/\D/g, '').slice(0, 2)
          }
          if (field === 'spouse.age') {
            if (updatedForm.spouse) {
              updatedForm.spouse.age = value.replace(/\D/g, '').slice(0, 2)
            }
          }

          if (field.startsWith('spouse.')) {
            if (!updatedForm.spouse) return form

            const spouseField = field.split('.')[1]
            updatedForm.spouse = { ...updatedForm.spouse, [spouseField]: value }
            const isNonEmpty =
              (typeof value === 'string' && value.trim() !== '') ||
              typeof value === 'boolean' ||
              typeof value === 'number'

            if (isNonEmpty) {
              setErrors((prevErrors) =>
                prevErrors.map((err, j) =>
                  j === index
                    ? {
                        ...err,
                        spouse: {
                          ...(err.spouse || {}),
                          [spouseField]: '',
                        },
                      }
                    : err
                )
              )
            }
          } else {
            updatedForm[field] = value
            if (typeof value === 'string' && value.trim() !== '') {
              setErrors((prevErrors) =>
                prevErrors.map((err, j) =>
                  j === index ? { ...err, [field]: '' } : err
                )
              )
            }
          }

          if (field === 'package') {
            const selectedPkg = stayPackages.find((pkg) => pkg.pkgId === value)
            updatedForm.package = value
            updatedForm.feeUsd = selectedPkg
              ? parseFloat(selectedPkg.feeUsd).toFixed(2)
              : ''

            if (selectedPkg?.spouseInfo === 1) {
              updatedForm.spouse = updatedForm.spouse || {
                name: '',
                email: '',
                phoneNo: '',
                age: '',
                icNo: '',
                remark: '',
                mealPackage: '1',
              }
            } else {
              updatedForm.spouse = null
            }

            setErrors((prevErrors) =>
              prevErrors.map((err, j) =>
                j === index ? { ...err, [`package-${index}`]: '' } : err
              )
            )
          }

          if (field === 'phoneCode') {
            updatedForm.phoneCode = value
          } else if (field === 'phoneNo') {
            updatedForm.phoneNo = value
          } else {
            updatedForm[field] = value
          }

          if (field === 'meeting') {
            updatedForm.meeting = value
          }

          return updatedForm
        }
        return form
      })
    )
  }

  const isSubmitEnabled = participantForms.some(
    (form) => form.name.trim() !== ''
  )

  const validateFields = () => {
    let newErrors = participantForms.map((form, index) => {
      let errors = {
        name: form.name ? '' : 'Required!',
        phoneCode: form.phoneCode ? '' : 'Required!',
        phoneNo: form.phoneNo ? '' : 'Required!',
        age: !form.age
          ? 'Required!'
          : /^\d{2}$/.test(form.age)
          ? ''
          : 'Must be number!',
        icNo: form.icNo ? '' : 'Required!',
        email: !form.email
          ? 'Required!'
          : /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(form.email)
          ? ''
          : 'Invalid email!',
        mealPackage: form.mealPackage ? '' : 'Required!',
        [`package-${index}`]: form.package ? '' : 'Required!',
      }
      return errors
    })

    setErrors(newErrors)
    return newErrors.every((err) =>
      Object.values(err).every((msg) => msg === '')
    )
  }

  const validateSpouseFields = () => {
    let spouseErrors = participantForms.map((form) => {
      if (form.spouse) {
        return {
          spouse: {
            name: form.spouse.name ? '' : 'Required!',
            phoneCode: form.spouse.phoneCode ? '' : 'Required!',
            phoneNo: form.spouse.phoneNo ? '' : 'Required!',
            age: !form.spouse.age
              ? 'Required!'
              : /^\d{2}$/.test(form.spouse.age)
              ? ''
              : 'Must be number!',
            icNo: form.spouse.icNo ? '' : 'Required!',
            email: !form.spouse.email
              ? 'Required!'
              : /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(form.spouse.email)
              ? ''
              : 'Invalid email!',
            mealPackage: form.spouse.mealPackage ? '' : 'Required!',
          },
        }
      }
      return {}
    })

    setErrors((prevErrors) => {
      const updatedErrors = [...prevErrors]
      updatedErrors.forEach((errors, index) => {
        if (spouseErrors[index]) {
          updatedErrors[index].spouse = spouseErrors[index].spouse
        }
      })
      return updatedErrors
    })

    return spouseErrors.every((err) =>
      err.spouse ? Object.values(err.spouse).every((msg) => msg === '') : true
    )
  }

  const handleSubmit = async () => {
    if (!validateFields() || !validateSpouseFields() || !isSubmitEnabled) {
      return
    }
    setIsSubmitting(true)
    try {
      const formattedParticipants = participantForms.map((form) => ({
        attendeeId: form.attendeeId,
        name: form.name,
        email: form.email,
        phoneNo: form.phoneNo,
        phoneCode: form.phoneCode,
        icNo: form.icNo,
        age: form.age,
        packageId: form.package,
        remark: form.remark,
        mealPackage: form.mealPackage,
        spouse: form.spouse || null,
        meeting: form.meeting,
        extraOneNight: form.extraOneNight ? 1 : 0,
        extraTwoNight: form.extraTwoNight ? 1 : 0,
        churchBlending: form.churchBlending ? 1 : 0,
        churchBlendingSabah: form.churchBlendingSabah ? 1 : 0,
        churchBlendingSarawak: form.churchBlendingSarawak ? 1 : 0,
      }))

      await axios.post(
        '/api/v1/intl/form/stay',
        { participants: formattedParticipants },
        { withCredentials: true }
      )

      toast.success('Submitted Successfully!')
      setParticipantForms([])
      setErrors([])
      navigate('/itero/hotel-reservation/payment')
    } catch (error) {
      if (error.response && error.response.status === 400) {
        toast.error(error.response.data.message)
      } else {
        console.error('Error submitting registration:', error)
        toast.error('Failed to register!')
      }
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleRemoveParticipant = (index) => {
    setParticipantForms((prevForms) => prevForms.filter((_, i) => i !== index))
    setErrors((prevErrors) => prevErrors.filter((_, i) => i !== index))
  }

  const handleCheckAttendee = async (index) => {
    const attendeeId = participantForms[index]?.attendeeId?.trim()
    if (!attendeeId) {
      setErrors((prevErrors) =>
        prevErrors.map((err, i) =>
          i === index ? { ...err, attendeeId: 'Attendee ID is required!' } : err
        )
      )
      return
    }

    setCheckingAttendee((prev) => ({ ...prev, [index]: true }))
    try {
      const response = await axios.get(`/api/v1/intl/form/verify`, {
        params: { data: attendeeId },
        withCredentials: true,
      })

      const data = response.data
      if (data.success && data.user) {
        setParticipantForms((prevForms) =>
          prevForms.map((form, i) =>
            i === index
              ? {
                  ...form,
                  name: data.user.name || '',
                  email: data.user.email || '',
                  icNo: '',
                  age: '',
                  phoneCode: '',
                  phoneNo: '',
                  attendeeId: attendeeId,
                  isVerified: true,
                }
              : form
          )
        )
      } else {
        toast.error(data.message || 'Attendee not found.')
      }
    } catch (error) {
      console.error('Error fetching attendee:', error)
      toast.error(
        error.response?.data?.message || 'Failed to retrieve attendee info.'
      )
    } finally {
      setCheckingAttendee((prev) => ({ ...prev, [index]: false }))
    }
  }

  const handleClearForm = (index) => {
    setParticipantForms((prevForms) =>
      prevForms.map((form, i) =>
        i === index
          ? {
              name: '',
              email: '',
              phoneNo: '',
              phoneCode: '',
              age: '',
              icNo: '',
              remark: '',
              package: '',
              feeUsd: '',
              mealPackage: '1',
              spouse: null,
              attendeeId: '',
              isVerified: false,
            }
          : form
      )
    )
    setErrors((prevErrors) =>
      prevErrors.map((err, i) => (i === index ? {} : err))
    )
  }

  return (
    <div className='mb-5'>
      <div>
        {participantForms.map((form, index) => (
          <div
            key={index}
            className='p-3 mb-3 rounded bg-white shadow-sm'
            style={{
              border: '1px solid #a89163',
              boxShadow: '0 0 10px rgba(217, 83, 79, 0.5)',
            }}
          >
            {participantForms.length > 1 && (
              <h6 className='fw-bold mb-3' style={{ color: '#834506' }}>
                Participant {index + 1}
              </h6>
            )}

            {/* First Row */}
            <div className='col-md-3 col-sm-6'>
              <label className='form-label'>
                Attendee ID <span className='require'>*</span>
              </label>
              <div className='d-flex'>
                <input
                  type='text'
                  className={`form-control ${
                    errors[index]?.attendeeId ? 'is-invalid' : ''
                  }`}
                  value={form.attendeeId || ''}
                  onChange={(e) =>
                    handleFieldChange(index, 'attendeeId', e.target.value)
                  }
                />
                <button
                  type='button'
                  className={`btn ${
                    form.isVerified ? 'btn-secondary' : 'btn-primary'
                  }`}
                  onClick={() =>
                    form.isVerified
                      ? handleClearForm(index)
                      : handleCheckAttendee(index)
                  }
                  disabled={!form.attendeeId?.trim() || checkingAttendee[index]}
                >
                  {checkingAttendee[index] ? (
                    <span
                      className='spinner-border spinner-border-sm'
                      role='status'
                      aria-hidden='true'
                    ></span>
                  ) : form.isVerified ? (
                    'Clear'
                  ) : (
                    'Check'
                  )}
                </button>
              </div>
              {errors[index]?.attendeeId && (
                <div className='invalid-feedback d-block'>
                  {errors[index].attendeeId}
                </div>
              )}
            </div>

            <div className='row g-3 mt-1'>
              <div className='col-md-3 col-sm-6'>
                <label className='form-label'>Name</label>
                <input
                  type='text'
                  className={`form-control ${
                    errors[index]?.name ? 'is-invalid' : ''
                  }`}
                  value={form.name}
                  disabled
                  style={{
                    backgroundColor: '#fcfcfc',
                    color: '#006ba1',
                    fontWeight: '600',
                  }}
                />
                {errors[index]?.name && (
                  <div className='invalid-feedback'>{errors[index].name}</div>
                )}
              </div>
              <div className='col-md-2 col-sm-6'>
                <label className='form-label'>Email</label>
                <input
                  type='email'
                  className={`form-control ${
                    errors[index]?.email ? 'is-invalid' : ''
                  }`}
                  value={form.email}
                  disabled
                  style={{
                    backgroundColor: '#fcfcfc',
                    color: '#006ba1',
                    fontWeight: '600',
                  }}
                />
                {errors[index]?.email && (
                  <div className='invalid-feedback'>{errors[index].email}</div>
                )}
              </div>
              <div className='col-md-2 col-sm-6'>
                <label className='form-label'>
                  Passport <span className='require'>*</span>
                </label>
                <input
                  type='text'
                  className={`form-control ${
                    errors[index]?.icNo ? 'is-invalid' : ''
                  }`}
                  value={form.icNo}
                  onChange={(e) =>
                    handleFieldChange(index, 'icNo', e.target.value)
                  }
                  disabled={!form.isVerified}
                />
                {errors[index]?.icNo && (
                  <div className='invalid-feedback'>{errors[index].icNo}</div>
                )}
              </div>
              <div className='col-md-1 col-sm-6'>
                <label className='form-label'>
                  Age <span className='require'>*</span>
                </label>
                <input
                  type='text'
                  className={`form-control ${
                    errors[index]?.age ? 'is-invalid' : ''
                  }`}
                  value={form.age}
                  onChange={(e) =>
                    handleFieldChange(index, 'age', e.target.value)
                  }
                  inputMode='numeric'
                  pattern='[0-9]{1,2}'
                  maxLength='2'
                  disabled={!form.isVerified}
                />
                {errors[index]?.age && (
                  <div className='invalid-feedback'>{errors[index].age}</div>
                )}
              </div>
              <div className='col-md-4 col-sm-6'>
                <label className='form-label'>
                  Phone Number <span className='require'>*</span>
                </label>
                <div className='d-flex align-items-center'>
                  <div style={{ width: '35%' }}>
                    <Select
                      options={countryPhoneCodes}
                      value={
                        form.phoneCode
                          ? countryPhoneCodes.find(
                              (c) => c.value === form.phoneCode
                            )
                          : null
                      }
                      onChange={(selected) =>
                        handleFieldChange(index, 'phoneCode', selected.value)
                      }
                      isSearchable
                      placeholder='+Code'
                      styles={{
                        menu: (provided) => ({ ...provided, width: '220px' }),
                        control: (provided) => ({
                          ...provided,
                          minWidth: '110px',
                          borderColor:
                            errors[index]?.phoneCode || errors[index]?.phoneNo
                              ? 'red'
                              : provided.borderColor,
                          '&:hover': {
                            borderColor:
                              errors[index]?.phoneCode || errors[index]?.phoneNo
                                ? 'red'
                                : provided.borderColor,
                          },
                        }),
                      }}
                      menuPortalTarget={document.body}
                      isDisabled={!form.isVerified}
                    />
                  </div>
                  <input
                    type='text'
                    className={`form-control ${
                      errors[index]?.phoneNo || errors[index]?.phoneCode
                        ? 'is-invalid'
                        : ''
                    }`}
                    value={form.phoneNo}
                    onChange={(e) =>
                      handleFieldChange(index, 'phoneNo', e.target.value)
                    }
                    style={{ flex: 1 }}
                    disabled={!form.isVerified}
                  />
                </div>
                {(errors[index]?.phoneCode || errors[index]?.phoneNo) && (
                  <div
                    className='text-danger mt-1'
                    style={{ fontSize: '14px' }}
                  >
                    Phone number and country code are required
                  </div>
                )}
              </div>
            </div>

            {/* Second Row */}
            <div className='row g-3 mt-1'>
              <div className='col-md-4 col-sm-12'>
                <label className='form-label'>
                  Package <span className='require'>*</span>
                </label>
                <select
                  className={`form-select ${
                    errors[index]?.[`package-${index}`] ? 'is-invalid' : ''
                  }`}
                  value={form.package || ''}
                  onChange={(e) =>
                    handleFieldChange(index, 'package', e.target.value)
                  }
                  disabled={!form.isVerified}
                >
                  <option value=''>Select a Package</option>
                  {stayPackages.map((pkg) => (
                    <option key={pkg.pkgId} value={pkg.pkgId}>
                      {pkg.packageNmEn}
                    </option>
                  ))}
                </select>
                {errors[index]?.[`package-${index}`] && (
                  <div className='invalid-feedback'>
                    {errors[index][`package-${index}`]}
                  </div>
                )}
              </div>
              <div className='col-md-1 col-sm-6'>
                <label className='form-label' style={{ whiteSpace: 'nowrap' }}>
                  Fee (USD)
                </label>
                <input
                  type='text'
                  className='form-control'
                  value={form.feeUsd || ''}
                  disabled
                />
              </div>
              <div className='col-md-2 col-sm-6'>
                <label className='form-label'>Meal Package</label>
                <select
                  className='form-control'
                  value={form.mealPackage || '1'}
                  onChange={(e) =>
                    handleFieldChange(index, 'mealPackage', e.target.value)
                  }
                  disabled={!form.isVerified}
                >
                  <option value='1'>No Preference</option>
                  <option value='2'>Vegan</option>
                  <option value='3'>Gluten Free</option>
                  <option value='4'>Vegan & Gluten Free</option>
                </select>
              </div>
              <div className='col-md-5 col-sm-6'>
                <label className='form-label'>Remarks</label>
                <input
                  type='text'
                  className='form-control'
                  value={form.remark}
                  onChange={(e) =>
                    handleFieldChange(index, 'remark', e.target.value)
                  }
                  disabled={!form.isVerified}
                />
              </div>

              {/* === Add-on Options === */}
              <div className='row mt-4' style={{ fontWeight: '600' }}>
                <div className='col-md-6 col-sm-12 d-flex align-items-center'>
                  <input
                    type='checkbox'
                    id={`meeting-${index}`}
                    checked={form.meeting || false}
                    onChange={(e) =>
                      handleFieldChange(index, 'meeting', e.target.checked)
                    }
                    disabled={!form.isVerified}
                    style={{
                      transform: 'scale(1.3)',
                      cursor: form.isVerified ? 'pointer' : 'not-allowed',
                      marginRight: '8px',
                    }}
                  />
                  <label
                    htmlFor={`meeting-${index}`}
                    className='mb-0'
                    style={{
                      cursor: form.isVerified ? 'pointer' : 'not-allowed',
                    }}
                  >
                    Attending Lord's Day Morning Blending Meeting
                  </label>
                </div>
              </div>

              {/* Church and Blending Trip */}
              <div className='row mt-3 ms-2' style={{ color: '#006ba1' }}>
                <label className='form-label'>
                  Churching and Blending Trip
                </label>
              </div>
              <div className='row mt-1' style={{ fontWeight: '600' }}>
                <div className='col-md-12 col-sm-12 d-flex align-items-center'>
                  <input
                    type='checkbox'
                    id={`churchBlending-${index}`}
                    checked={form.churchBlending || false}
                    onChange={(e) =>
                      handleFieldChange(
                        index,
                        'churchBlending',
                        e.target.checked
                      )
                    }
                    disabled={!form.isVerified}
                    style={{
                      transform: 'scale(1.3)',
                      cursor: form.isVerified ? 'pointer' : 'not-allowed',
                      marginRight: '8px',
                    }}
                  />
                  <label
                    htmlFor={`churchBlending-${index}`}
                    className='mb-0'
                    style={{
                      cursor: form.isVerified ? 'pointer' : 'not-allowed',
                    }}
                  >
                    3 days 2 nights trip within Peninsular Malaysia from
                    September 28-30, 2025 ($350.00 USD per person)
                  </label>
                </div>
              </div>
              <div className='row mt-1' style={{ fontWeight: '600' }}>
                <div className='col-md-12 col-sm-12 d-flex align-items-center'>
                  <input
                    type='checkbox'
                    id={`churchBlendingSabah-${index}`}
                    checked={form.churchBlendingSabah || false}
                    onChange={(e) =>
                      handleFieldChange(
                        index,
                        'churchBlendingSabah',
                        e.target.checked
                      )
                    }
                    disabled={!form.isVerified}
                    style={{
                      transform: 'scale(1.3)',
                      cursor: form.isVerified ? 'pointer' : 'not-allowed',
                      marginRight: '8px',
                    }}
                  />
                  <label
                    htmlFor={`churchBlendingSabah-${index}`}
                    className='mb-0'
                    style={{
                      cursor: form.isVerified ? 'pointer' : 'not-allowed',
                    }}
                  >
                    4 days 3 nights trip within Sabah from September 28 –
                    October 1, 2025 ($450.00 USD per person)
                  </label>
                </div>
              </div>
              <div className='row mt-1' style={{ fontWeight: '600' }}>
                <div className='col-md-12 col-sm-12 d-flex align-items-center'>
                  <input
                    type='checkbox'
                    id={`churchBlendingSarawak-${index}`}
                    checked={form.churchBlendingSarawak || false}
                    onChange={(e) =>
                      handleFieldChange(
                        index,
                        'churchBlendingSarawak',
                        e.target.checked
                      )
                    }
                    disabled={!form.isVerified}
                    style={{
                      transform: 'scale(1.3)',
                      cursor: form.isVerified ? 'pointer' : 'not-allowed',
                      marginRight: '8px',
                    }}
                  />
                  <label
                    htmlFor={`churchBlendingSarawak-${index}`}
                    className='mb-0'
                    style={{
                      cursor: form.isVerified ? 'pointer' : 'not-allowed',
                    }}
                  >
                    4 days 3 nights trip within Sarawak from September 28 –
                    October 1, 2025 ($450.00 USD per person)
                  </label>
                </div>
              </div>

              <div className='row mt-3 ms-2' style={{ color: '#006ba1' }}>
                <label className='form-label'>
                  Additional Night Stay at the Hotel
                </label>
              </div>
              <div className='row mt-1' style={{ fontWeight: '600' }}>
                <div className='col-md-6 col-sm-12 d-flex align-items-center'>
                  <input
                    type='checkbox'
                    id={`extraOneNight-${index}`}
                    checked={form.extraOneNight || false}
                    onChange={(e) =>
                      handleFieldChange(
                        index,
                        'extraOneNight',
                        e.target.checked
                      )
                    }
                    disabled={!form.isVerified}
                    style={{
                      transform: 'scale(1.3)',
                      cursor: form.isVerified ? 'pointer' : 'not-allowed',
                      marginRight: '8px',
                    }}
                  />
                  <label
                    htmlFor={`extraOneNight-${index}`}
                    className='mb-0'
                    style={{
                      cursor: form.isVerified ? 'pointer' : 'not-allowed',
                    }}
                  >
                    One Additional Night ($70.00 USD per room)
                  </label>
                </div>
              </div>
              <div className='row mt-2' style={{ fontWeight: '600' }}>
                <div className='col-md-6 col-sm-12 d-flex align-items-center'>
                  <input
                    type='checkbox'
                    id={`extraTwoNight-${index}`}
                    checked={form.extraTwoNight || false}
                    onChange={(e) =>
                      handleFieldChange(
                        index,
                        'extraTwoNight',
                        e.target.checked
                      )
                    }
                    disabled={!form.isVerified}
                    style={{
                      transform: 'scale(1.3)',
                      cursor: form.isVerified ? 'pointer' : 'not-allowed',
                      marginRight: '8px',
                    }}
                  />
                  <label
                    htmlFor={`extraTwoNight-${index}`}
                    className='mb-0'
                    style={{
                      cursor: form.isVerified ? 'pointer' : 'not-allowed',
                    }}
                  >
                    Two Additional Nights ($140.00 USD per room)
                  </label>
                </div>
              </div>

              <div className='col-md-12 col-sm-12 d-flex align-items-end justify-content-end'>
                {index > 0 && (
                  <Tooltip title='Delete Form'>
                    <button
                      className='btn btn-danger btn-sm'
                      onClick={() => handleRemoveParticipant(index)}
                    >
                      <i className='bi bi-dash-lg'></i>
                    </button>
                  </Tooltip>
                )}
              </div>
            </div>

            {form.spouse && (
              <>
                <hr className='my-3' />
                <h6 className='fw-bold mb-3 text-primary'>
                  Spouse Information
                </h6>
                <div className='row g-3'>
                  <div className='col-md-3 col-sm-6'>
                    <label className='form-label'>
                      Name <span className='require'>*</span>
                    </label>
                    <input
                      type='text'
                      className={`form-control ${
                        errors[index]?.spouse?.name ? 'is-invalid' : ''
                      }`}
                      value={form.spouse.name || ''}
                      onChange={(e) =>
                        handleFieldChange(index, 'spouse.name', e.target.value)
                      }
                      disabled={!form.isVerified}
                    />
                    {errors[index]?.spouse?.name && (
                      <div className='invalid-feedback'>
                        {errors[index].spouse.name}
                      </div>
                    )}
                  </div>

                  <div className='col-md-2 col-sm-6'>
                    <label className='form-label'>
                      Email <span className='require'>*</span>
                    </label>
                    <input
                      type='email'
                      className={`form-control ${
                        errors[index]?.spouse?.email ? 'is-invalid' : ''
                      }`}
                      value={form.spouse.email || ''}
                      onChange={(e) =>
                        handleFieldChange(index, 'spouse.email', e.target.value)
                      }
                      disabled={!form.isVerified}
                    />
                    {errors[index]?.spouse?.email && (
                      <div className='invalid-feedback'>
                        {errors[index].spouse.email}
                      </div>
                    )}
                  </div>
                  <div className='col-md-2 col-sm-6'>
                    <label className='form-label'>
                      Passport <span className='require'>*</span>
                    </label>
                    <input
                      type='text'
                      className={`form-control ${
                        errors[index]?.spouse?.icNo ? 'is-invalid' : ''
                      }`}
                      value={form.spouse.icNo || ''}
                      onChange={(e) =>
                        handleFieldChange(index, 'spouse.icNo', e.target.value)
                      }
                      disabled={!form.isVerified}
                    />
                    {errors[index]?.spouse?.icNo && (
                      <div className='invalid-feedback'>
                        {errors[index].spouse.icNo}
                      </div>
                    )}
                  </div>
                  <div className='col-md-1 col-sm-6'>
                    <label className='form-label'>
                      Age <span className='require'>*</span>
                    </label>
                    <input
                      type='text'
                      className={`form-control ${
                        errors[index]?.spouse?.age ? 'is-invalid' : ''
                      }`}
                      value={form.spouse.age || ''}
                      onChange={(e) =>
                        handleFieldChange(index, 'spouse.age', e.target.value)
                      }
                      inputMode='numeric'
                      pattern='[0-9]{1,2}'
                      maxLength='2'
                      disabled={!form.isVerified}
                    />
                    {errors[index]?.spouse?.age && (
                      <div className='invalid-feedback'>
                        {errors[index].spouse.age}
                      </div>
                    )}
                  </div>

                  <div className='col-md-4 col-sm-6'>
                    <label className='form-label'>
                      Phone Number <span className='require'>*</span>
                    </label>
                    <div className='d-flex align-items-center'>
                      <div style={{ width: '35%' }}>
                        <Select
                          options={countryPhoneCodes}
                          value={
                            form.spouse.phoneCode
                              ? countryPhoneCodes.find(
                                  (c) => c.value === form.spouse.phoneCode
                                )
                              : null
                          }
                          onChange={(selected) =>
                            handleFieldChange(
                              index,
                              'spouse.phoneCode',
                              selected.value
                            )
                          }
                          isSearchable
                          placeholder='+Code'
                          styles={{
                            menu: (provided) => ({
                              ...provided,
                              width: '220px',
                            }),
                            control: (provided) => ({
                              ...provided,
                              minWidth: '110px',
                              borderColor:
                                errors[index]?.spouse?.phoneCode ||
                                errors[index]?.spouse?.phoneNo
                                  ? 'red'
                                  : provided.borderColor,
                              '&:hover': {
                                borderColor:
                                  errors[index]?.spouse?.phoneCode ||
                                  errors[index]?.spouse?.phoneNo
                                    ? 'red'
                                    : provided.borderColor,
                              },
                            }),
                          }}
                          menuPortalTarget={document.body}
                          isDisabled={!form.isVerified}
                        />
                      </div>
                      <input
                        type='text'
                        className={`form-control ${
                          errors[index]?.spouse?.phoneNo ||
                          errors[index]?.spouse?.phoneCode
                            ? 'is-invalid'
                            : ''
                        }`}
                        value={form.spouse.phoneNo}
                        onChange={(e) =>
                          handleFieldChange(
                            index,
                            'spouse.phoneNo',
                            e.target.value
                          )
                        }
                        style={{ flex: 1 }}
                        disabled={!form.isVerified}
                      />
                    </div>
                    {(errors[index]?.spouse?.phoneCode ||
                      errors[index]?.spouse?.phoneNo) && (
                      <div
                        className='text-danger mt-1'
                        style={{ fontSize: '14px' }}
                      >
                        Phone number and country code are required
                      </div>
                    )}
                  </div>
                  <div className='col-md-3 col-sm-6'>
                    <label className='form-label'>Meal Package</label>
                    <select
                      className={`form-control ${
                        errors[index]?.spouse?.mealPackage ? 'is-invalid' : ''
                      }`}
                      value={form.spouse?.mealPackage || '1'}
                      onChange={(e) =>
                        handleFieldChange(
                          index,
                          'spouse.mealPackage',
                          e.target.value
                        )
                      }
                      disabled={!form.isVerified}
                    >
                      <option value='1'>No Preference</option>
                      <option value='2'>Vegan</option>
                      <option value='3'>Gluten Free</option>
                      <option value='4'>Vegan & Gluten Free</option>
                    </select>
                    {errors[index]?.spouse?.mealPackage && (
                      <div className='invalid-feedback'>
                        {errors[index].spouse.mealPackage}
                      </div>
                    )}
                  </div>
                  <div className='col-md-4 col-sm-6'>
                    <label className='form-label'>Remarks</label>
                    <input
                      type='text'
                      className='form-control'
                      value={form.spouse?.remark}
                      onChange={(e) =>
                        handleFieldChange(
                          index,
                          'spouse.remark',
                          e.target.value
                        )
                      }
                      disabled={!form.isVerified}
                    />
                  </div>
                </div>
                <div
                  className='col-md-6 col-sm-12 d-flex align-items-center mt-3'
                  style={{ fontWeight: '600' }}
                >
                  <input
                    type='checkbox'
                    id={`spouseMeeting-${index}`}
                    checked={form.spouse.meeting || false}
                    onChange={(e) =>
                      handleFieldChange(
                        index,
                        'spouse.meeting',
                        e.target.checked
                      )
                    }
                    disabled={!form.isVerified}
                    style={{
                      transform: 'scale(1.3)',
                      cursor: form.isVerified ? 'pointer' : 'not-allowed',
                      marginRight: '8px',
                    }}
                  />
                  <label
                    htmlFor={`spouseMeeting-${index}`}
                    className='mb-0'
                    style={{
                      cursor: form.isVerified ? 'pointer' : 'not-allowed',
                    }}
                  >
                    Attending Lord's Day Morning Blending Meeting
                  </label>
                </div>
              </>
            )}
          </div>
        ))}

        <div className='d-flex justify-content-center mt-4'>
          {!isSubmitEnabled ? (
            <Tooltip title='Please fill in all visible forms above.'>
              <span>
                <button
                  className='btn btn-primary me-4'
                  onClick={handleSubmit}
                  disabled
                >
                  {isSubmitting ? (
                    <span className='spinner-border spinner-border-sm'></span>
                  ) : (
                    'Submit'
                  )}
                </button>
              </span>
            </Tooltip>
          ) : (
            <button
              className='btn btn-primary me-4'
              onClick={handleSubmit}
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <span className='spinner-border spinner-border-sm'></span>
              ) : (
                'Submit'
              )}
            </button>
          )}
          <button
            className='btn btn-outline-secondary'
            onClick={() => navigate('/')}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  )
}

export default IntlRegistrationStayForm
