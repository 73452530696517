import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import axios from 'axios'
import Layout from '../../../../components/Layout/Layout'
import { toast } from 'react-toastify'
import LoadingSpinner from './../../../../components/shared/LoadingSpinner'

const AdminEditEvent = () => {
  const { eventId } = useParams()
  const navigate = useNavigate()

  const [eventData, setEventData] = useState({
    nameEn: '',
    nameZh: '',
    nameBm: '',
    startDt: '',
    endDt: '',
    time: '',
    regType1: '',
    regType2: '',
    venue: '',
    attendance: '',
    attendanceCode: '',
    attendanceDesc: '',
    intlEvent: '0',
  })

  const [packages, setPackages] = useState([])
  const [banks, setBanks] = useState([])
  const [selectedBank, setSelectedBank] = useState(null)

  const [errors, setErrors] = useState({})
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const response = await axios.get(`/api/v1/admin/event/${eventId}`)
        const { event } = response.data
        setEventData(event)
        setPackages(event.packages || [])
        setSelectedBank(event.paymentAcc)
        setLoading(false)

        if (event.regType1 !== 2) {
          fetchBanks()
        }
      } catch (error) {
        console.error('Error fetching event:', error)
        setLoading(false)
      }
    }

    const fetchBanks = async () => {
      try {
        const response = await axios.get('/api/v1/admin/event/banks')
        setBanks(response.data)
      } catch (error) {
        console.error('Error fetching banks:', error)
      }
    }

    fetchEvent()
  }, [eventId])

  const handleInputChange = (field, value) => {
    setEventData({ ...eventData, [field]: value })

    if (errors[field]) {
      const updatedErrors = { ...errors }
      delete updatedErrors[field]
      setErrors(updatedErrors)
    }
  }

  const handleFileChange = (field, file) => {
    setEventData({ ...eventData, [field]: file })
  }

  const handlePackageChange = (index, field, value) => {
    const updatedPackages = [...packages]
    updatedPackages[index][field] = value
    setPackages(updatedPackages)

    const errorKey = `${field}-${index}`
    if (errors[errorKey]) {
      const updatedErrors = { ...errors }
      delete updatedErrors[errorKey]
      setErrors(updatedErrors)
    }
  }

  const handleAddPackage = () => {
    setPackages([
      ...packages,
      {
        packageNmEn: '',
        packageNmZh: '',
        packageNmBm: '',
        fee: '',
        roomCode: '',
        capacity: '',
        limit: '',
        packageType: '',
        children: '0',
      },
    ])
  }

  const handleRemovePackage = (index) => {
    const updatedPackages = packages.filter((_, i) => i !== index)
    setPackages(updatedPackages)
  }

  const validateForm = () => {
    const newErrors = {}
    const requiredFields = ['nameEn', 'nameZh', 'nameBm']

    requiredFields.forEach((field) => {
      if (
        !eventData[field] ||
        (typeof eventData[field] === 'string' && !eventData[field].trim())
      ) {
        newErrors[field] = 'Required'
      }
    })

    if (eventData.attendance === '1') {
      if (
        !eventData.attendanceCode ||
        (typeof eventData.attendanceCode === 'string' &&
          !eventData.attendanceCode.trim())
      ) {
        newErrors.attendanceCode = 'Required'
      }
      if (
        !eventData.attendanceDesc ||
        (typeof eventData.attendanceDesc === 'string' &&
          !eventData.attendanceDesc.trim())
      ) {
        newErrors.attendanceDesc = 'Required'
      }
    }

    setErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!validateForm()) return

    try {
      const formData = new FormData()
      let cleanedEventData = { ...eventData }

      // ❗ Remove 'packages' to avoid being appended as [object Object]
      delete cleanedEventData.packages

      // If Zoom event, remove physical-related fields
      if (String(cleanedEventData.regType1) === '2') {
        delete cleanedEventData.venue
        delete cleanedEventData.intlEvent
        delete cleanedEventData.attendance
        delete cleanedEventData.attendanceCode
        delete cleanedEventData.attendanceDesc
      }

      // Append all scalar (non-file, non-null) fields
      Object.entries(cleanedEventData).forEach(([key, value]) => {
        if (
          value !== null &&
          value !== undefined &&
          value !== '' &&
          !(value instanceof File)
        ) {
          formData.append(key, String(value))
        }
      })

      // Append selected bank
      if (selectedBank) {
        formData.append('paymentAcc', selectedBank)
      }

      // ✅ Append packages (only for physical event)
      if (String(eventData.regType1) === '1' && packages.length > 0) {
        const cleanedPackages = packages.map(({ _id, ...pkg }) => ({
          ...pkg,
          bedOption: Array.isArray(pkg.bedOption)
            ? pkg.bedOption.map(({ bedCode, bedName }) => ({
                bedCode,
                bedName,
              }))
            : [],
        }))
        formData.append('packages', JSON.stringify(cleanedPackages))
      }

      // Append files (only if they are File instances)
      ;[
        'letter',
        'form',
        'notice',
        'engOutline',
        'chiOutline',
        'bmOutline',
      ].forEach((key) => {
        if (eventData[key] instanceof File) {
          formData.append(key, eventData[key])
        }
      })

      // Submit to backend
      await axios.put(`/api/v1/admin/event/${eventId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })

      toast.success('Event updated successfully!')
      navigate('/admin/events')
    } catch (error) {
      console.error(
        'Error updating event:',
        error.response?.data || error.message
      )
      toast.error('Failed to update event')
    }
  }

  const handleReturn = () => {
    navigate('/admin/events')
  }

  return (
    <Layout title='Edit Event'>
      <div className='p-3'>
        <div className='d-flex justify-content-end mb-2'>
          <button
            type='button'
            className='btn btn-secondary btn-sm'
            onClick={handleReturn}
          >
            Return
          </button>
        </div>

        {loading ? (
          <div className='text-center'>
            <LoadingSpinner />
          </div>
        ) : (
          <div className='mb-3'>
            <form onSubmit={handleSubmit}>
              {/* Event Name */}
              <div className='mb-4'>
                <div className='fw-bold mb-2' style={{ color: '#3c57a8' }}>
                  Event Name
                </div>
                <div className='mx-3'>
                  <div className='mb-2'>
                    <label className='form-label'>Eng.</label>
                    <input
                      type='text'
                      className={`form-control ${
                        errors.nameEn ? 'is-invalid' : ''
                      }`}
                      value={eventData.nameEn}
                      onChange={(e) =>
                        handleInputChange('nameEn', e.target.value)
                      }
                    />
                    {errors.nameEn && (
                      <div className='invalid-feedback'>{errors.nameEn}</div>
                    )}
                  </div>

                  <div className='mb-2'>
                    <label className='form-label'>Chi.</label>
                    <input
                      type='text'
                      className={`form-control ${
                        errors.nameZh ? 'is-invalid' : ''
                      }`}
                      value={eventData.nameZh}
                      onChange={(e) =>
                        handleInputChange('nameZh', e.target.value)
                      }
                    />
                    {errors.nameZh && (
                      <div className='invalid-feedback'>{errors.nameZh}</div>
                    )}
                  </div>

                  <div className='mb-2'>
                    <label className='form-label'>BM</label>
                    <input
                      type='text'
                      className={`form-control ${
                        errors.nameBm ? 'is-invalid' : ''
                      }`}
                      value={eventData.nameBm}
                      onChange={(e) =>
                        handleInputChange('nameBm', e.target.value)
                      }
                    />
                    {errors.nameBm && (
                      <div className='invalid-feedback'>{errors.nameBm}</div>
                    )}
                  </div>
                </div>
              </div>

              {/* Date/Time Section */}
              <div className='mb-4'>
                <div className='fw-bold mb-2' style={{ color: '#3c57a8' }}>
                  Date/Time
                </div>
                <div className='row mx-2'>
                  <div className='col-md-4'>
                    <label className='form-label'>Date (From)</label>
                    <input
                      type='date'
                      className='form-control'
                      value={
                        eventData.startDt
                          ? new Date(eventData.startDt)
                              .toISOString()
                              .split('T')[0]
                          : ''
                      }
                      onChange={(e) =>
                        handleInputChange('startDt', e.target.value)
                      }
                    />
                  </div>
                  <div className='col-md-4'>
                    <label className='form-label'>Date (To)</label>
                    <input
                      type='date'
                      className='form-control'
                      value={
                        eventData.endDt
                          ? new Date(eventData.endDt)
                              .toISOString()
                              .split('T')[0]
                          : ''
                      }
                      onChange={(e) =>
                        handleInputChange('endDt', e.target.value)
                      }
                    />
                  </div>
                  <div className='col-md-4'>
                    <label className='form-label'>Time</label>
                    <input
                      type='text'
                      className='form-control'
                      name='time'
                      value={eventData.time || ''}
                      onChange={(e) =>
                        handleInputChange('time', e.target.value)
                      }
                    />
                  </div>
                </div>
              </div>

              {/* Files Section */}
              {eventData.regType1 === 1 && (
                <div className='mb-4'>
                  <div className='fw-bold mb-2' style={{ color: '#3c57a8' }}>
                    Upload Files
                  </div>
                  <div className='row mx-2'>
                    {/* Letter */}
                    <div className='col-md-4'>
                      <div className='d-flex justify-content-between align-items-center'>
                        <label className='form-label '>Letter</label>
                        {eventData.letter &&
                          typeof eventData.letter === 'string' && (
                            <a
                              href={`${process.env.REACT_APP_BACKEND_URL}/files/event/${eventId}/${eventData.letter}`}
                              target='_blank'
                              rel='noopener noreferrer'
                              className='text-primary'
                              style={{ fontSize: '0.9rem', marginLeft: 'auto' }}
                            >
                              {eventData.letter}
                            </a>
                          )}
                      </div>
                      <input
                        type='file'
                        className='form-control'
                        name='letter'
                        onChange={(e) =>
                          handleFileChange('letter', e.target.files[0])
                        }
                      />
                    </div>

                    {/* Form */}
                    <div className='col-md-4'>
                      <div className='d-flex justify-content-between align-items-center'>
                        <label className='form-label '>Form</label>
                        {eventData.form &&
                          typeof eventData.form === 'string' && (
                            <a
                              href={`${process.env.REACT_APP_BACKEND_URL}/files/event/${eventId}/${eventData.form}`}
                              target='_blank'
                              rel='noopener noreferrer'
                              className='text-primary'
                              style={{ fontSize: '0.9rem', marginLeft: 'auto' }}
                            >
                              {eventData.form}
                            </a>
                          )}
                      </div>
                      <input
                        type='file'
                        className='form-control'
                        name='form'
                        onChange={(e) =>
                          handleFileChange('form', e.target.files[0])
                        }
                      />
                    </div>

                    {/* Notice */}
                    <div className='col-md-4'>
                      <div className='d-flex justify-content-between align-items-center'>
                        <label className='form-label '>Notification</label>
                        {eventData.notice &&
                          typeof eventData.notice === 'string' && (
                            <a
                              href={`${process.env.REACT_APP_BACKEND_URL}/files/event/${eventId}/${eventData.notice}`}
                              target='_blank'
                              rel='noopener noreferrer'
                              className='text-primary'
                              style={{ fontSize: '0.9rem', marginLeft: 'auto' }}
                            >
                              {eventData.notice}
                            </a>
                          )}
                      </div>
                      <input
                        type='file'
                        className='form-control'
                        name='notice'
                        onChange={(e) =>
                          handleFileChange('notice', e.target.files[0])
                        }
                      />
                    </div>
                  </div>
                </div>
              )}

              {/* Registration Section */}
              <div className='mb-4'>
                <div className='fw-bold mb-2' style={{ color: '#3c57a8' }}>
                  Registration
                </div>

                <div className='mx-3'>
                  <div className='mb-2 d-flex align-items-center'>
                    <label className='me-2'>Meeting: </label>
                    <span>
                      {eventData.regType1 === 1 ? 'Physical' : 'Zoom'}
                    </span>
                  </div>

                  {eventData.regType1 === 1 && (
                    <>
                      <div className='mb-2 d-flex align-items-center'>
                        <label className='me-3'>(2) Register: </label>
                        <div className='mt-1'>
                          <label>
                            <input
                              className='me-1'
                              type='radio'
                              name='regType2'
                              value='1'
                              checked={String(eventData.regType2) === '1'}
                              onChange={(e) =>
                                handleInputChange('regType2', e.target.value)
                              }
                            />
                            Personal
                          </label>
                          <label className='ms-3'>
                            <input
                              className='me-1'
                              type='radio'
                              name='regType2'
                              value='2'
                              checked={String(eventData.regType2) === '2'}
                              onChange={(e) =>
                                handleInputChange('regType2', e.target.value)
                              }
                            />
                            Church
                          </label>
                          <label className='ms-3'>
                            <input
                              className='me-1'
                              type='radio'
                              name='regType2'
                              value='3'
                              checked={String(eventData.regType2) === '3'}
                              onChange={(e) =>
                                handleInputChange('regType2', e.target.value)
                              }
                            />
                            Full-timers Blending & Perfecting
                          </label>
                          {errors.regType2 && (
                            <span className='text-danger ms-3'>
                              {errors.regType2}
                            </span>
                          )}
                        </div>
                      </div>

                      <div className='mb-2 d-flex align-items-center'>
                        <label className='me-3'>(3) Venue: </label>
                        <div className='mt-1'>
                          <label>
                            <input
                              className='me-1'
                              type='radio'
                              name='venue'
                              value='1'
                              checked={String(eventData.venue) === '1'}
                              onChange={(e) =>
                                handleInputChange('venue', e.target.value)
                              }
                            />
                            Eden Residence (or other locality)
                          </label>
                          <label className='ms-3'>
                            <input
                              className='me-1'
                              type='radio'
                              name='venue'
                              value='2'
                              checked={String(eventData.venue) === '2'}
                              onChange={(e) =>
                                handleInputChange('venue', e.target.value)
                              }
                            />
                            Hotel
                          </label>
                          {errors.venue && (
                            <span className='text-danger ms-3'>
                              {errors.venue}
                            </span>
                          )}
                        </div>
                      </div>

                      <div className='mb-1 d-flex align-items-center'>
                        <label className='me-3'>
                          (4) Attendance via QR Code:
                        </label>
                        <div className='mt-1'>
                          <label>
                            <input
                              className='me-1'
                              type='radio'
                              name='attendance'
                              value='1'
                              checked={String(eventData.attendance) === '1'}
                              onChange={(e) =>
                                handleInputChange('attendance', e.target.value)
                              }
                            />
                            Yes
                          </label>
                          <label className='ms-3'>
                            <input
                              className='me-1'
                              type='radio'
                              name='attendance'
                              value='0'
                              checked={String(eventData.attendance) === '0'}
                              onChange={(e) =>
                                handleInputChange('attendance', e.target.value)
                              }
                            />
                            No
                          </label>
                          {errors.attendance && (
                            <span className='text-danger ms-3'>
                              {errors.attendance}
                            </span>
                          )}
                        </div>
                      </div>
                      {String(eventData.attendance) === '1' && (
                        <div className='ms-4'>
                          <div className='mb-1'>Please enter the details:</div>
                          <input
                            type='text'
                            className={`form-control ${
                              errors.attendanceCode ? 'is-invalid' : ''
                            }`}
                            name='attendanceCode'
                            placeholder='Event Code (E.g. BCKVEDM24)'
                            value={eventData.attendanceCode}
                            onChange={(e) =>
                              handleInputChange(
                                'attendanceCode',
                                e.target.value
                              )
                            }
                          />
                          {errors.attendanceCode && (
                            <div className='invalid-feedback mt-0'>
                              {errors.attendanceCode}
                            </div>
                          )}
                          <input
                            type='text'
                            className={`form-control ${
                              errors.attendanceDesc ? 'is-invalid' : ''
                            }`}
                            name='attendanceDesc'
                            placeholder='Event Short Description (E.g. Blending Conference in Klang Valley)'
                            value={eventData.attendanceDesc}
                            onChange={(e) =>
                              handleInputChange(
                                'attendanceDesc',
                                e.target.value
                              )
                            }
                          />
                          {errors.attendanceDesc && (
                            <div className='invalid-feedback mt-0'>
                              {errors.attendanceDesc}
                            </div>
                          )}
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>

              {eventData.regType1 === 1 && (
                <>
                  {/* Packages Section */}
                  <div className='mb-2'>
                    <div className='fw-bold mb-2' style={{ color: '#3c57a8' }}>
                      Packages
                    </div>
                    {packages.map((pkg, index) => (
                      <div key={index} className='mb-3 mx-2'>
                        <div
                          className='mb-2'
                          style={{
                            ...(index > 0 && {
                              borderTop: '1px solid #3c57a8',
                              paddingTop: '1rem',
                            }),
                            fontStyle: 'italic',
                            color: '#00a684',
                          }}
                        >
                          Package {index + 1}
                        </div>
                        {/* Package Selection */}
                        <div className='d-flex align-items-center mx-3 mb-3'>
                          <label className='me-3'>Select One: </label>
                          <label className='me-3'>
                            <input
                              type='radio'
                              name={`packageType-${index}`}
                              className={`me-1 ${
                                errors[`packageType-${index}`]
                                  ? 'is-invalid'
                                  : ''
                              }`}
                              value='1'
                              onChange={(e) =>
                                handlePackageChange(
                                  index,
                                  'packageType',
                                  e.target.value
                                )
                              }
                              checked={String(pkg.packageType) === '1'}
                            />
                            Stay-In
                          </label>
                          <label className='me-3'>
                            <input
                              type='radio'
                              name={`packageType-${index}`}
                              className={`me-1 ${
                                errors[`packageType-${index}`]
                                  ? 'is-invalid'
                                  : ''
                              }`}
                              value='2'
                              onChange={(e) =>
                                handlePackageChange(
                                  index,
                                  'packageType',
                                  e.target.value
                                )
                              }
                              checked={String(pkg.packageType) === '2'}
                            />
                            Non-Stay-In (With Meal)
                          </label>
                          <label className='me-3'>
                            <input
                              type='radio'
                              name={`packageType-${index}`}
                              className={`me-1 ${
                                errors[`packageType-${index}`]
                                  ? 'is-invalid'
                                  : ''
                              }`}
                              value='0'
                              onChange={(e) =>
                                handlePackageChange(
                                  index,
                                  'packageType',
                                  e.target.value
                                )
                              }
                              checked={String(pkg.packageType) === '0'}
                            />
                            Register Only
                          </label>
                          {errors[`packageType-${index}`] && (
                            <div className='text-danger ms-3'>
                              {errors[`packageType-${index}`]}
                            </div>
                          )}

                          <div className='separator'></div>

                          <label className='me-3'>
                            <input
                              type='checkbox'
                              name={`children-${index}`}
                              className='me-1'
                              value='1'
                              onChange={(e) =>
                                handlePackageChange(
                                  index,
                                  'children',
                                  e.target.checked ? '1' : '0'
                                )
                              }
                              checked={String(pkg.children) === '1'}
                            />
                            Children
                          </label>
                        </div>
                        {/* Package Details */}
                        <div className='row mx-2'>
                          <div className='col-md-3 mb-3'>
                            <label className='form-label'>
                              Package Name (Eng.)
                            </label>
                            <input
                              type='text'
                              className={`form-control ${
                                errors[`packageNmEn-${index}`]
                                  ? 'is-invalid'
                                  : ''
                              }`}
                              value={pkg.packageNmEn}
                              onChange={(e) =>
                                handlePackageChange(
                                  index,
                                  'packageNmEn',
                                  e.target.value
                                )
                              }
                            />
                            {errors[`packageNmEn-${index}`] && (
                              <div className='invalid-feedback'>
                                {errors[`packageNmEn-${index}`]}
                              </div>
                            )}
                          </div>
                          <div className='col-md-3 mb-3'>
                            <label className='form-label'>
                              Package Name (Chi.)
                            </label>
                            <input
                              type='text'
                              className={`form-control ${
                                errors[`packageNmZh-${index}`]
                                  ? 'is-invalid'
                                  : ''
                              }`}
                              value={pkg.packageNmZh}
                              onChange={(e) =>
                                handlePackageChange(
                                  index,
                                  'packageNmZh',
                                  e.target.value
                                )
                              }
                            />
                            {errors[`packageNmZh-${index}`] && (
                              <div className='invalid-feedback'>
                                {errors[`packageNmZh-${index}`]}
                              </div>
                            )}
                          </div>
                          <div className='col-md-3 mb-3'>
                            <label className='form-label'>
                              Package Name (BM)
                            </label>
                            <input
                              type='text'
                              className={`form-control ${
                                errors[`packageNmBm-${index}`]
                                  ? 'is-invalid'
                                  : ''
                              }`}
                              value={pkg.packageNmBm}
                              onChange={(e) =>
                                handlePackageChange(
                                  index,
                                  'packageNmBm',
                                  e.target.value
                                )
                              }
                            />
                            {errors[`packageNmBm-${index}`] && (
                              <div className='invalid-feedback'>
                                {errors[`packageNmBm-${index}`]}
                              </div>
                            )}
                          </div>

                          <div
                            className={`mb-3 col-md-${
                              eventData.intlEvent === 1 ? '2' : '3'
                            }`}
                          >
                            <label className='form-label'>Fee (RM)</label>
                            <input
                              type='number'
                              className={`form-control ${
                                errors[`fee-${index}`] ? 'is-invalid' : ''
                              }`}
                              value={pkg.fee}
                              onChange={(e) =>
                                handlePackageChange(
                                  index,
                                  'fee',
                                  e.target.value
                                )
                              }
                            />
                            {errors[`fee-${index}`] && (
                              <div className='invalid-feedback'>
                                {errors[`fee-${index}`]}
                              </div>
                            )}
                          </div>

                          {eventData.intlEvent === 1 && (
                            <div className='col-md-1'>
                              <label className='form-label'>Fee (USD)</label>
                              <input
                                type='number'
                                className={`form-control ${
                                  errors[`feeUsd-${index}`] ? 'is-invalid' : ''
                                }`}
                                value={pkg.feeUsd}
                                onChange={(e) =>
                                  handlePackageChange(
                                    index,
                                    'feeUsd',
                                    e.target.value
                                  )
                                }
                              />
                              {errors[`feeUsd-${index}`] && (
                                <div className='invalid-feedback'>
                                  {errors[`feeUsd-${index}`]}
                                </div>
                              )}
                            </div>
                          )}
                        </div>

                        {/* Room Details for Stay-In */}
                        {String(pkg.packageType) === '1' && (
                          <div className='row mx-2'>
                            {pkg.bedOption.length === 0 && (
                              <div className='col-md-3'>
                                <label className='form-label'>Room Code</label>
                                <input
                                  type='text'
                                  className={`form-control ${
                                    errors[`roomCode-${index}`]
                                      ? 'is-invalid'
                                      : ''
                                  }`}
                                  value={pkg.roomCode}
                                  onChange={(e) =>
                                    handlePackageChange(
                                      index,
                                      'roomCode',
                                      e.target.value
                                    )
                                  }
                                />
                                {errors[`roomCode-${index}`] && (
                                  <div className='invalid-feedback'>
                                    {errors[`roomCode-${index}`]}
                                  </div>
                                )}
                              </div>
                            )}

                            <div className='col-md-4 mb-3'>
                              <label className='form-label'>
                                Room Capacity
                              </label>
                              <input
                                type='number'
                                className={`form-control ${
                                  errors[`capacity-${index}`]
                                    ? 'is-invalid'
                                    : ''
                                }`}
                                value={pkg.capacity}
                                onChange={(e) =>
                                  handlePackageChange(
                                    index,
                                    'capacity',
                                    e.target.value
                                  )
                                }
                              />
                              {errors[`capacity-${index}`] && (
                                <div className='invalid-feedback'>
                                  {errors[`capacity-${index}`]}
                                </div>
                              )}
                            </div>
                            <div className='col-md-4 mb-3'>
                              <label className='form-label'>Room Limit</label>
                              <input
                                type='number'
                                className={`form-control ${
                                  errors[`limit-${index}`] ? 'is-invalid' : ''
                                }`}
                                value={pkg.limit}
                                onChange={(e) =>
                                  handlePackageChange(
                                    index,
                                    'limit',
                                    e.target.value
                                  )
                                }
                              />
                              {errors[`limit-${index}`] && (
                                <div className='invalid-feedback'>
                                  {errors[`limit-${index}`]}
                                </div>
                              )}
                            </div>
                            {pkg.bedOption.length > 0 &&
                              pkg.packageType === 1 && (
                                <div className='mt-2'>
                                  {pkg.bedOption.map((bed, idx) => (
                                    <div className='row mb-2' key={idx}>
                                      <div className='col-md-3'>
                                        <label className='form-label'>
                                          Bed Code
                                        </label>
                                        <input
                                          type='text'
                                          className='form-control'
                                          value={bed.bedCode}
                                          onChange={(e) =>
                                            handlePackageChange(
                                              index,
                                              `bedOption-bedCode-${idx}`,
                                              e.target.value
                                            )
                                          }
                                        />
                                      </div>
                                      <div className='col-md-3'>
                                        <label className='form-label'>
                                          Bed Name
                                        </label>
                                        <input
                                          type='text'
                                          className='form-control'
                                          value={bed.bedName}
                                          onChange={(e) =>
                                            handlePackageChange(
                                              index,
                                              `bedOption-bedName-${idx}`,
                                              e.target.value
                                            )
                                          }
                                        />
                                      </div>
                                      <div className='col-md-1 d-flex align-items-end'>
                                        <button
                                          type='button'
                                          className='btn btn-warning btn-sm'
                                          onClick={() => {
                                            const updatedPackages = [
                                              ...packages,
                                            ]
                                            updatedPackages[index].bedOption =
                                              updatedPackages[
                                                index
                                              ].bedOption.filter(
                                                (_, i) => i !== idx
                                              )
                                            setPackages(updatedPackages)
                                          }}
                                        >
                                          <i className='bi bi-dash-lg'></i>
                                        </button>
                                      </div>
                                    </div>
                                  ))}

                                  <div className='row'>
                                    <div className='col-md-3'>
                                      <button
                                        type='button'
                                        className='btn btn-info btn-sm mt-2'
                                        onClick={() => {
                                          const updatedPackages = [...packages]
                                          updatedPackages[index].bedOption.push(
                                            {
                                              bedCode: '',
                                              bedName: '',
                                            }
                                          )
                                          setPackages(updatedPackages)
                                        }}
                                      >
                                        <i className='bi bi-plus-lg'></i> Add
                                        Bed Option
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              )}
                          </div>
                        )}
                        {/* Remove Button */}
                        <div className='text-end'>
                          {index > 0 && (
                            <button
                              type='button'
                              className='btn btn-danger btn-sm'
                              onClick={() => handleRemovePackage(index)}
                            >
                              <i className='bi bi-dash-lg'></i>
                            </button>
                          )}
                        </div>
                      </div>
                    ))}

                    {/* Add Package Button */}
                    <div className='text-end mt-3'>
                      <button
                        type='button'
                        className='btn btn-success btn-sm'
                        onClick={handleAddPackage}
                      >
                        <i className='bi bi-plus-lg'></i>
                      </button>
                    </div>
                  </div>

                  {/* Payment Section */}
                  <div className='mb-4'>
                    <div className='fw-bold mb-2' style={{ color: '#3c57a8' }}>
                      Bank Account
                    </div>
                    <div className='mx-3'>
                      {banks.length > 0 ? (
                        <div className='d-flex flex-wrap'>
                          {banks.map((bank) => (
                            <div
                              key={bank._id}
                              className='form-check me-3 d-flex align-items-center'
                            >
                              <input
                                type='radio'
                                id={`bank-${bank._id}`}
                                name='paymentAcc'
                                value={bank._id}
                                className='form-check-input me-2'
                                onChange={() => setSelectedBank(bank._id)}
                                checked={selectedBank === bank._id}
                              />
                              <label
                                htmlFor={`bank-${bank._id}`}
                                className='form-check-label'
                              >
                                {bank.accName} ({bank.accNo})
                              </label>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <p className='text-muted'>No banks available</p>
                      )}
                    </div>
                  </div>
                </>
              )}

              {eventData.regType1 === 2 && (
                <>
                  <div className='mt-4'>
                    <div className='fw-bold mb-2' style={{ color: '#3c57a8' }}>
                      Outline
                    </div>
                    <div className='mt-2 mx-3'>
                      {/* English Outline */}
                      <div className='mb-3'>
                        <div
                          className='mb-2'
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          <label
                            className='form-label'
                            style={{ marginRight: '10px', marginBottom: 0 }}
                          >
                            English
                          </label>
                          {eventData.engOutline &&
                            typeof eventData.engOutline === 'string' && (
                              <span>
                                File:{' '}
                                <a
                                  href={`${process.env.REACT_APP_BACKEND_URL}/files/outline/${eventId}/${eventData.engOutline}`}
                                  target='_blank'
                                  rel='noopener noreferrer'
                                  className='text-primary'
                                  style={{ textDecoration: 'none' }}
                                >
                                  {eventData.engOutline}
                                </a>
                              </span>
                            )}
                        </div>
                        <input
                          type='file'
                          className='form-control'
                          onChange={(e) =>
                            handleFileChange('engOutline', e.target.files[0])
                          }
                        />
                      </div>

                      {/* Chinese Outline */}
                      <div className='mb-3'>
                        <div
                          className='mb-2'
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          <label
                            className='form-label'
                            style={{ marginRight: '10px', marginBottom: 0 }}
                          >
                            Chinese
                          </label>
                          {eventData.chiOutline &&
                            typeof eventData.chiOutline === 'string' && (
                              <span>
                                File:{' '}
                                <a
                                  href={`${process.env.REACT_APP_BACKEND_URL}/files/outline/${eventId}/${eventData.chiOutline}`}
                                  target='_blank'
                                  rel='noopener noreferrer'
                                  className='text-primary'
                                  style={{ textDecoration: 'none' }}
                                >
                                  {eventData.chiOutline}
                                </a>
                              </span>
                            )}
                        </div>
                        <input
                          type='file'
                          className='form-control'
                          onChange={(e) =>
                            handleFileChange('chiOutline', e.target.files[0])
                          }
                        />
                      </div>

                      {/* BM Outline */}
                      <div className='mb-2'>
                        <div
                          className='mb-1'
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          <label
                            className='form-label'
                            style={{ marginRight: '10px', marginBottom: 0 }}
                          >
                            BM
                          </label>
                          {eventData.bmOutline &&
                            typeof eventData.bmOutline === 'string' && (
                              <span>
                                File:{' '}
                                <a
                                  href={`${process.env.REACT_APP_BACKEND_URL}/files/outline/${eventId}/${eventData.bmOutline}`}
                                  target='_blank'
                                  rel='noopener noreferrer'
                                  className='text-primary'
                                  style={{ textDecoration: 'none' }}
                                >
                                  {eventData.bmOutline}
                                </a>
                              </span>
                            )}
                        </div>
                        <input
                          type='file'
                          className='form-control'
                          onChange={(e) =>
                            handleFileChange('bmOutline', e.target.files[0])
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <div className='mt-4'>
                    <div className='fw-bold mb-2' style={{ color: '#3c57a8' }}>
                      Meeting Info
                    </div>
                    <div className='mt-2 mx-3'>
                      <div className='mb-3'>
                        <label className='form-label'>Zoom Meeting URL</label>
                        <input
                          type='text'
                          className='form-control'
                          value={eventData.meetingUrl || ''}
                          onChange={(e) =>
                            handleInputChange('meetingUrl', e.target.value)
                          }
                        />
                      </div>

                      <div className='mb-3'>
                        <label className='form-label'>
                          BM Call-In Meeting ID
                        </label>
                        <input
                          type='text'
                          className='form-control'
                          value={eventData.meetingCallId || ''}
                          onChange={(e) =>
                            handleInputChange('meetingCallId', e.target.value)
                          }
                        />
                      </div>

                      <div className='mb-3'>
                        <label className='form-label'>
                          BM Call-In Meeting Password
                        </label>
                        <input
                          type='text'
                          className='form-control'
                          value={eventData.meetingCallPw || ''}
                          onChange={(e) =>
                            handleInputChange('meetingCallPw', e.target.value)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}

              <div className='text-end'>
                <button
                  type='submit'
                  className='btn btn-warning btn-sm'
                  disabled={loading} // Disable while updating
                >
                  {loading ? (
                    <>
                      <span
                        className='spinner-border spinner-border-sm me-1'
                        role='status'
                        aria-hidden='true'
                      ></span>
                      Updating...
                    </>
                  ) : (
                    'Update'
                  )}
                </button>

                <button
                  type='button'
                  className='btn btn-secondary ms-3 btn-sm'
                  onClick={handleReturn}
                  disabled={loading}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    </Layout>
  )
}

export default AdminEditEvent
