import React from 'react'
import { Link } from 'react-router-dom'

const AdminMenuBar = () => {
  return (
    <nav
      className='navbar navbar-expand-lg navbar-light shadow py-0'
      style={{ backgroundColor: '#7a6844' }}
    >
      <ul
        className='navbar-nav mx-auto'
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <li className='nav-item me-3'>
          <Link className='nav-link text-white' to='/admin/dashboard'>
            Dashboard
          </Link>
        </li>
        <li className='nav-item me-3'>
          <Link className='nav-link text-white' to='/admin/announcement'>
            Announcement
          </Link>
        </li>

        <li className='nav-item me-3 dropdown'>
          <span
            className='nav-link dropdown-toggle text-white'
            id='usersDropdown'
            data-bs-toggle='dropdown'
            style={{ cursor: 'pointer' }}
          >
            Users
          </span>
          <ul className='dropdown-menu' aria-labelledby='usersDropdown'>
            <li>
              <Link className='dropdown-item' to='/admin/personal-account'>
                Personal Account
              </Link>
            </li>
            <li>
              <Link className='dropdown-item' to='/users/waiting-approve'>
                Waiting Approve
              </Link>
            </li>
            <li>
              <Link className='dropdown-item' to='/users/deactivate-account'>
                Deactivate Account
              </Link>
            </li>
          </ul>
        </li>

        <li className='nav-item me-3 dropdown'>
          <span
            className='nav-link dropdown-toggle text-white'
            id='eventsDropdown'
            data-bs-toggle='dropdown'
            style={{ cursor: 'pointer' }}
          >
            Events
          </span>
          <ul className='dropdown-menu' aria-labelledby='eventsDropdown'>
            <li>
              <Link className='dropdown-item' to='/admin/events'>
                List
              </Link>
            </li>
            <li>
              <Link className='dropdown-item' to='/admin/bank-account'>
                Bank Account
              </Link>
            </li>
            <li>
              <Link className='dropdown-item' to='/admin/events/message'>
                MP3 Messages
              </Link>
            </li>
          </ul>
        </li>
        <li className='nav-item me-3'>
          <Link className='nav-link text-white' to='/admin/church-account'>
            Churches
          </Link>
        </li>
        {/* <li className='nav-item me-3'>
          <Link className='nav-link text-white' to='/admin/migrate'>
            DB Migrate
          </Link>
        </li> */}
      </ul>
    </nav>
  )
}

export default AdminMenuBar
