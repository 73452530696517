import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Layout from '../../../components/Layout/Layout'
import IntlPaymentRecords from './Records'
import { Modal, Tooltip } from 'antd'
import axios from 'axios'
import { toast } from 'react-toastify'
import Select from 'react-select'
import { stateProvinceList } from '../../../utils/stateProvinceList'
import { countries } from '../../../utils/countryList'

const IntlPaymentList = () => {
  const navigate = useNavigate()
  const [payments, setPayments] = useState([])
  const [loading, setLoading] = useState(true)
  const [selectedPayments, setSelectedPayments] = useState([])
  const [totalFee, setTotalFee] = useState(0)
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null)
  const [expandedMethod, setExpandedMethod] = useState(null)
  const [errorMessage, setErrorMessage] = useState('')
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [fileUploads, setFileUploads] = useState({})
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isQrModalVisible, setIsQrModalVisible] = useState(false)
  const [payerEmail, setPayerEmail] = useState('')
  const [isProcessingCredit, setIsProcessingCredit] = useState(false)
  const [creditRate, setCreditRate] = useState(1)

  const countryOptions = countries.map((c) => ({
    value: c.code,
    label: c.name,
  }))

  const [billingInfo, setBillingInfo] = useState({
    firstName: '',
    lastName: '',
    email: '',
  })

  const validatePostalCode = (country, code) => {
    if (country === 'US') {
      return /^\d{5}-\d{4}$/.test(code)
    }
    if (country === 'CA') {
      return /^[A-Za-z]\d[A-Za-z] \d[A-Za-z]\d$/.test(code)
    }
    return true
  }

  const handleCreditPayment = async () => {
    const totalConverted = totalFee * creditRate
    if (totalConverted > 10000) {
      setErrorMessage('Total payment amount exceeds the allowed limit')
      return
    }

    setIsProcessingCredit(true)
    const {
      firstName,
      lastName,
      email,
      address1,
      city,
      state,
      postalCode,
      country,
    } = billingInfo

    if (totalFee <= 0) {
      setErrorMessage('Total fee must be greater than 0.')
      setIsProcessingCredit(false)
      return
    }

    if (!firstName || !lastName || !email || !address1 || !city || !country) {
      setErrorMessage('Please fill in all required billing fields.')
      setIsProcessingCredit(false)
      return
    }

    if ((country === 'US' || country === 'CA') && (!state || !postalCode)) {
      setErrorMessage('State and postal code are required for US/Canada.')
      setIsProcessingCredit(false)
      return
    }

    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      setErrorMessage('Invalid email format.')
      setIsProcessingCredit(false)
      return
    }

    if (
      (country === 'US' || country === 'CA') &&
      !validatePostalCode(country, postalCode)
    ) {
      setErrorMessage(
        country === 'US'
          ? 'Invalid US postal code format. Use 12345-6789.'
          : 'Invalid Canadian postal code format. Use A1B 2C3.'
      )
      setIsProcessingCredit(false)
      return
    }

    try {
      const { data } = await axios.post(
        '/api/v1/intl/payment/credit-debit-card',
        {
          amount: totalFee,
          bill_to_address_city: city,
          bill_to_address_country: country,
          bill_to_address_line1: address1,
          bill_to_address_postal_code: postalCode,
          bill_to_address_state: state,
          bill_to_email: email,
          bill_to_forename: firstName,
          bill_to_surname: lastName,
          group: true,
          selectedPayments,
        },
        { withCredentials: true }
      )

      const form = document.createElement('form')
      form.method = 'POST'
      form.action = data.actionUrl

      Object.entries(data.params).forEach(([name, value]) => {
        const input = document.createElement('input')
        input.type = 'hidden'
        input.name = name
        input.value = value
        form.appendChild(input)
      })

      document.body.appendChild(form)
      form.submit()
    } catch (error) {
      console.error('Error redirecting to payment gateway:', error)
      toast.error('Failed to initiate payment.')
    } finally {
      setIsProcessingCredit(false)
    }
  }

  const paymentMethods = [
    {
      value: '2',
      label: 'Credit / Debit Card',
      icon: 'bi bi-credit-card',
      description: (
        <div>
          <div className='d-flex align-items-center gap-2 mb-1'>
            <span style={{ color: '#2f4483' }}>
              Total Amount for Selected Payments: USD {totalFee.toFixed(2)}
            </span>
          </div>
          Pay securely using your credit or debit card via our trusted payment
          gateway. We accept Visa, Mastercard.
          <div className='mt-2'>
            <div
              style={{ fontWeight: '600' }}
              className='text-danger ms-3 mb-2'
            >
              Please complete all required billing fields before proceeding.
            </div>
            <div className='px-3'>
              <div className='row'>
                <div className='col-md-4 mb-2'>
                  <label className='form-label'>
                    First Name <span className='require'>*</span>
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    value={billingInfo.firstName}
                    onChange={(e) =>
                      setBillingInfo({
                        ...billingInfo,
                        firstName: e.target.value,
                      })
                    }
                  />
                </div>
                <div className='col-md-4 mb-2'>
                  <label className='form-label'>
                    Last Name <span className='require'>*</span>
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    value={billingInfo.lastName}
                    onChange={(e) =>
                      setBillingInfo({
                        ...billingInfo,
                        lastName: e.target.value,
                      })
                    }
                  />
                </div>
                <div className='col-md-4 mb-2'>
                  <label className='form-label'>
                    Email Address <span className='require'>*</span>
                  </label>
                  <input
                    type='email'
                    className='form-control'
                    value={billingInfo.email}
                    onChange={(e) =>
                      setBillingInfo({ ...billingInfo, email: e.target.value })
                    }
                  />
                </div>

                <div className='col-md-4 mb-2'>
                  <label className='form-label'>
                    Address Line 1 <span className='require'>*</span>
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    maxLength={60}
                    value={billingInfo.address1}
                    onChange={(e) =>
                      setBillingInfo({
                        ...billingInfo,
                        address1: e.target.value,
                      })
                    }
                  />
                </div>

                <div className='col-md-4 mb-2'>
                  <label className='form-label'>
                    City <span className='require'>*</span>
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    maxLength={50}
                    value={billingInfo.city}
                    onChange={(e) =>
                      setBillingInfo({
                        ...billingInfo,
                        city: e.target.value,
                      })
                    }
                  />
                </div>

                <div className='col-md-4 mb-2'>
                  <label className='form-label'>
                    Country <span className='require'>*</span>
                  </label>
                  <Select
                    options={countryOptions}
                    value={countryOptions.find(
                      (opt) => opt.value === billingInfo.country
                    )}
                    onChange={(selectedOption) =>
                      setBillingInfo({
                        ...billingInfo,
                        country: selectedOption ? selectedOption.value : '',
                      })
                    }
                    placeholder='-- Select Country --'
                    isClearable
                  />
                </div>

                {(billingInfo.country === 'US' ||
                  billingInfo.country === 'CA') && (
                  <>
                    <div className='col-md-4'>
                      <label className='form-label'>
                        State <span className='require'>*</span>
                      </label>
                      <Select
                        options={stateProvinceList[billingInfo.country] || []}
                        value={
                          stateProvinceList[billingInfo.country]?.find(
                            (opt) => opt.value === billingInfo.state
                          ) || null
                        }
                        onChange={(selectedOption) =>
                          setBillingInfo({
                            ...billingInfo,
                            state: selectedOption?.value || '',
                          })
                        }
                        isClearable
                        placeholder='-- Select State --'
                      />
                    </div>

                    <div className='col-md-4'>
                      <label className='form-label'>
                        Postal Code <span className='require'>*</span>
                        {billingInfo.country === 'US' && (
                          <small className='text-muted ms-2'>
                            (e.g. 12345-6789)
                          </small>
                        )}
                        {billingInfo.country === 'CA' && (
                          <small className='text-muted ms-2'>
                            (e.g. A1B 2C3)
                          </small>
                        )}
                      </label>
                      <input
                        type='text'
                        className='form-control'
                        value={billingInfo.postalCode}
                        onChange={(e) =>
                          setBillingInfo({
                            ...billingInfo,
                            postalCode: e.target.value,
                          })
                        }
                      />
                    </div>
                  </>
                )}
              </div>

              <div className='mt-3 text-end mb-2'>
                <button
                  className='btn btn-primary btn-sm'
                  onClick={handleCreditPayment}
                  disabled={isProcessingCredit}
                >
                  {isProcessingCredit ? (
                    <>
                      <span className='spinner-border spinner-border-sm me-2'></span>
                      Processing...
                    </>
                  ) : (
                    'Proceed to Payment'
                  )}
                </button>

                {!billingInfo.firstName ||
                  !billingInfo.lastName ||
                  !billingInfo.email ||
                  !billingInfo.address1 ||
                  !billingInfo.country ||
                  ((billingInfo.country === 'US' ||
                    billingInfo.country === 'CA') &&
                    (!billingInfo.city ||
                      !billingInfo.state ||
                      !billingInfo.postalCode))}
              </div>
            </div>
          </div>
        </div>
      ),
      requiresUpload: false,
    },

    {
      value: '1',
      label: 'Telegraphic Transfer',
      icon: 'bi bi-bank',
      description: (
        <>
          <div className='d-flex align-items-center gap-2'>
            <span style={{ color: '#2f4483' }}>
              Total Amount for Selected Payments: USD {totalFee.toFixed(2)}
            </span>
          </div>

          <div className='d-flex gap-2 mt-2'>
            <i
              className='bi bi-info-circle-fill'
              style={{ color: '#0d6efd' }}
            ></i>
            <span>
              Please select the participants below for this payment. Then
              transfer the funds to our bank account via Telegraphic Transfer
              (TT), upload the proof of payment, and submit.
            </span>
          </div>

          <div className='d-flex mt-1'>
            <div className='ms-4'>
              <div>Bank Account Information:</div>
              <ul className='m-0'>
                <li>Name of Bank: Public Bank Berhad</li>
                <li>Account Name: The Christian Association of Subang Jaya</li>
                <li>Account Number: 3242177619</li>
                <li>Branch: Subang Jaya Branch</li>
                <li>
                  Branch Address: B1 - B4, Jalan SS 15/4D, Subang Jaya, 47500
                  Petaling Jaya, Selangor, Malaysia
                </li>
                <li>Swift Code: PBBEMYKL</li>
              </ul>
            </div>
          </div>

          <div className='mt-3'>
            <label htmlFor='payerEmail' className='form-label'>
              Please enter your email to receive notice from us:
            </label>
            <input
              type='email'
              className='form-control'
              id='payerEmail'
              placeholder='yourname@example.com'
              onChange={(e) => setPayerEmail(e.target.value)}
            />
          </div>

          <div className='mt-3'>
            <label htmlFor='paymentSlip' className='form-label'>
              Upload your payment slip by clicking below:
            </label>
          </div>
        </>
      ),
      requiresUpload: true,
    },
    {
      value: '3',
      label: 'QR eWallet',
      icon: 'bi bi-qr-code',
      description: (
        <>
          <div className='d-flex align-items-center gap-2'>
            <span style={{ color: '#2f4483' }}>
              Total Amount for Selected Payments: USD {totalFee.toFixed(2)}
            </span>
          </div>
          <div className='d-flex gap-2 mt-2'>
            <i
              className='bi bi-info-circle-fill'
              style={{ color: '#0d6efd' }}
            ></i>
            <span>
              Please select the participants below for this payment. Then scan
              the QR code with your eWallet app and upload the proof of payment,
              and submit. (Applicable for Indonesia, Singapore, Thailand, China,
              South Korea, and Cambodia only.)
            </span>
          </div>
          <div className='d-flex mt-1'>
            <div className='ms-4'>
              <div
                onClick={() => setIsQrModalVisible(true)}
                style={{ cursor: 'pointer', color: '#0d6efd' }}
              >
                Click here to view QR code <i className='bi bi-qr-code'></i>
              </div>
            </div>
          </div>
          <div className='mt-3'>
            <label htmlFor='payerEmail' className='form-label'>
              Please enter your email to receive notice from us:
            </label>
            <input
              type='email'
              className='form-control'
              placeholder='yourname@example.com'
              id='payerEmail'
              onChange={(e) => setPayerEmail(e.target.value)}
            />
          </div>

          <div className='mt-3'>
            <label htmlFor='paymentSlip' className='form-label'>
              Upload your payment slip by clicking below:
            </label>
          </div>
        </>
      ),
      requiresUpload: true,
    },
  ]

  const handleCheckboxChange = (paymentId, isChecked) => {
    setSelectedPayments((prevSelected) => {
      let updatedSelectedPayments
      if (isChecked) {
        updatedSelectedPayments = [...prevSelected, paymentId]
      } else {
        updatedSelectedPayments = prevSelected.filter((id) => id !== paymentId)
      }
      updateTotalFee(updatedSelectedPayments)
      return updatedSelectedPayments
    })
  }

  const updateTotalFee = (selectedIds) => {
    const total = payments
      .filter((payment) => selectedIds.includes(payment._id))
      .reduce((acc, payment) => {
        const baseFee = payment.feeUsd || 0

        const extraOne =
          payment.extraOneNight === 1 && payment.extraOnePaid === 0 ? 70 : 0
        const extraTwo =
          payment.extraTwoNight === 1 && payment.extraTwoPaid === 0 ? 140 : 0
        const blend =
          payment.churchBlending === 1 && payment.churchBlendingPaid === 0
            ? payment.spouseInfo
              ? 700
              : 350
            : 0
        const blendSabah =
          payment.churchBlendingSabah === 1 &&
          payment.churchBlendingSabahPaid === 0
            ? payment.spouseInfo
              ? 900
              : 450
            : 0
        const blendSarawak =
          payment.churchBlendingSarawak === 1 &&
          payment.churchBlendingSarawakPaid === 0
            ? payment.spouseInfo
              ? 900
              : 450
            : 0

        return (
          acc +
          baseFee +
          extraOne +
          extraTwo +
          blend +
          blendSabah +
          blendSarawak
        )
      }, 0)

    setTotalFee(total)
  }

  const fetchPayments = async () => {
    try {
      const response = await fetch('/api/v1/intl/payment/unpaid-list', {
        withCredentials: true,
      })
      if (response.ok) {
        const data = await response.json()
        setPayments(data.payments || [])
        setCreditRate(data.creditRate || 1)
      } else {
        const errorResponse = await response.json()
        console.error('Error fetching payments:', errorResponse.message)
      }
    } catch (error) {
      console.error('Error fetching payment data:', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchPayments()
  }, [])

  useEffect(() => {
    updateTotalFee(selectedPayments)
  }, [selectedPayments, payments])

  const handleFileChange = (methodValue, event) => {
    const file = event.target.files[0]
    setFileUploads((prev) => ({
      ...prev,
      [methodValue]: file,
    }))
  }

  const handleUploadSubmit = async (methodValue) => {
    if (!selectedPayments.length) {
      setErrorMessage('Please select at least one participant before submit.')
      return
    }
    if (!fileUploads[methodValue]) {
      setErrorMessage('Please upload a file before submit.')
      return
    }
    setIsSubmitting(true)
    setErrorMessage('')

    const formData = new FormData()
    formData.append('paySlip', fileUploads[methodValue])
    formData.append('selectedPayments', JSON.stringify(selectedPayments))
    formData.append('paymentMethod', methodValue)
    formData.append('totalAmount', totalFee)
    formData.append('payerEmail', payerEmail)

    try {
      await axios.post('/api/v1/intl/payment/submit', formData, {
        withCredentials: true,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })

      fetchPayments()
      setSelectedPayments([])
      setErrorMessage('')
      setSelectedPaymentMethod(null)
      setExpandedMethod(null)
      setFileUploads((prev) => ({ ...prev, [methodValue]: null }))
      toast.success('Payment submitted successfully.')
    } catch (error) {
      toast.error('Error uploading payment data:', error)
      if (error.response) {
        setErrorMessage(
          error.response.data.message || 'Error uploading payment proof.'
        )
      } else {
        setErrorMessage('An error occurred while uploading. Please try again.')
      }
    } finally {
      setIsSubmitting(false)
    }
  }

  const toggleExpand = (value) => {
    setExpandedMethod(expandedMethod === value ? null : value)
  }

  const showModal = () => setIsModalVisible(true)
  const handleCancel = () => setIsModalVisible(false)
  const handleQrModalCancel = () => setIsQrModalVisible(false)

  return (
    <Layout title={'Event Registration System (ERS)'}>
      <div className='p-2' style={{ marginBottom: '100px' }}>
        <div
          className='fw-bold mb-3 text-uppercase'
          style={{
            color: '#5d3104',
            textAlign: 'center',
            fontSize: '18px',
            borderBottom: '1px solid #d3c4a7',
            paddingBottom: '10px',
          }}
        >
          2025 September International Training for Elders and Responsible Ones
        </div>
        <div className='d-flex justify-content-end gap-4 mb-2'>
          <button
            className='btn btn-success btn-sm'
            style={{ textTransform: 'uppercase', fontWeight: 600 }}
            onClick={() => navigate('/')}
          >
            Lodging & Meal List
          </button>
          <button
            className='btn btn-success btn-sm'
            style={{ textTransform: 'uppercase', fontWeight: 600 }}
            onClick={() => navigate('/itero/hotel-reservation/form')}
          >
            Lodging & Meal Form
          </button>
          <button
            className='btn btn-success btn-sm'
            disabled
            style={{
              textTransform: 'uppercase',
              fontWeight: 600,
              cursor: 'not-allowed',
            }}
          >
            Payment
          </button>
          <button
            className='btn btn-success btn-sm'
            style={{ textTransform: 'uppercase', fontWeight: 600 }}
            onClick={() => navigate('/itero/hotel-reservation/transportation')}
          >
            Transportation
          </button>
        </div>
        <div
          className='fw-bold text-center mb-2'
          style={{
            fontSize: '17px',
            color: '#219119',
            textTransform: 'uppercase',
          }}
        >
          Payment
        </div>
        <div className='mb-1 d-flex justify-content-between align-items-center'>
          <div style={{ fontWeight: 600, color: '#951a26', fontSize: '18px' }}>
            <i className='bi bi-exclamation-circle-fill'></i> All payments are
            non-refundable
          </div>
          <button className='btn btn-info btn-sm' onClick={showModal}>
            Payment Records
          </button>
        </div>

        <div className='mb-4'>
          <div
            className='mt-2 p-3'
            style={{
              border: '1px solid #ccc',
              borderRadius: '8px',
            }}
          >
            <div style={{ marginBottom: '10px' }}>
              <div className='d-flex justify-content-between align-items-center'>
                <div style={{ fontWeight: 'bold' }}>
                  Please choose a payment method from the options below, and
                  select the participants with pending payments from the list
                  below to complete your payment.
                </div>
              </div>

              {errorMessage && (
                <div className='text-end' style={{ marginTop: '5px' }}>
                  <div style={{ color: 'red', fontWeight: 'bold' }}>
                    {errorMessage}
                  </div>
                </div>
              )}
            </div>

            <div className='d-flex flex-column gap-3'>
              {paymentMethods.map((method) => (
                <div
                  key={method.value}
                  className={`p-3 border rounded ${
                    selectedPaymentMethod === method.value
                      ? 'border-primary bg-light'
                      : 'border-secondary'
                  }`}
                  style={{
                    cursor: 'pointer',
                    transition: 'all 0.2s ease',
                  }}
                >
                  <div
                    className='d-flex align-items-center justify-content-between'
                    onClick={() => {
                      setSelectedPaymentMethod(method.value)
                      setErrorMessage('')
                      toggleExpand(method.value)
                    }}
                  >
                    <div className='d-flex align-items-center'>
                      <input
                        type='radio'
                        name='paymentMethod'
                        value={method.value}
                        checked={selectedPaymentMethod === method.value}
                        onChange={(e) => {
                          setSelectedPaymentMethod(e.target.value)
                          setErrorMessage('')
                        }}
                        style={{ marginRight: '10px' }}
                      />
                      <span style={{ fontWeight: 'bold' }}>{method.label}</span>
                      <i className={`${method.icon} ms-2`}></i>
                    </div>
                    <i
                      className={`bi ${
                        expandedMethod === method.value
                          ? 'bi-chevron-up'
                          : 'bi-chevron-down'
                      }`}
                    ></i>
                  </div>

                  {/* Expanded Section */}
                  {expandedMethod === method.value && (
                    <div
                      className='mt-2 p-2'
                      style={{
                        backgroundColor: '#fff',
                        borderRadius: '4px',
                        border: '1px solid #ddd',
                      }}
                    >
                      <div className='m-0' style={{ fontWeight: '500' }}>
                        {method.description}
                      </div>
                      {method.requiresUpload && (
                        <div className='d-flex align-items-center'>
                          <div className='custom-file' style={{ flex: '1' }}>
                            <input
                              type='file'
                              accept='image/*,application/pdf'
                              id={`file-upload-${method.value}`}
                              onChange={(e) =>
                                handleFileChange(method.value, e)
                              }
                              className='custom-file-input'
                              style={{ display: 'none' }}
                            />
                            <label
                              className='btn btn-light w-100 text-start'
                              htmlFor={`file-upload-${method.value}`}
                              style={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                              }}
                            >
                              {fileUploads[method.value]
                                ? fileUploads[method.value].name
                                : 'Upload Payment Slip'}
                            </label>
                          </div>

                          {!fileUploads[method.value] ||
                          selectedPayments.length === 0 ? (
                            <Tooltip title='Upload payment slip and select a participant below.'>
                              <span>
                                <button
                                  className='btn btn-primary'
                                  onClick={() =>
                                    handleUploadSubmit(method.value)
                                  }
                                  disabled
                                >
                                  {isSubmitting ? (
                                    <span className='spinner-border spinner-border-sm me-2'></span>
                                  ) : (
                                    'Submit'
                                  )}
                                </button>
                              </span>
                            </Tooltip>
                          ) : (
                            <button
                              className='btn btn-primary'
                              onClick={() => handleUploadSubmit(method.value)}
                              disabled={isSubmitting}
                            >
                              {isSubmitting ? (
                                <span className='spinner-border spinner-border-sm me-2'></span>
                              ) : (
                                'Submit'
                              )}
                            </button>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>

        <div
          className='text-center fw-bold'
          style={{ fontSize: '17px', color: '#1a7414' }}
        >
          Participants with Pending Payments
        </div>

        <div className='table-responsive'>
          <table className='table'>
            <thead>
              <tr>
                <th>Name</th>
                <th className='text-end'>Fee (USD)</th>
                <th className='text-end'>
                  <input
                    type='checkbox'
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectedPayments(
                          payments.map((payment) => payment._id)
                        )
                      } else {
                        setSelectedPayments([])
                      }
                    }}
                    checked={
                      selectedPayments.length === payments.length &&
                      payments.length > 0
                    }
                    className='checkbox-large'
                    style={{ transform: 'scale(1.5)', cursor: 'pointer' }}
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan='5' className='text-center'>
                    Loading...
                  </td>
                </tr>
              ) : payments.length === 0 ? (
                <tr>
                  <td colSpan='5' className='text-center text-muted'>
                    No Pending Payment
                  </td>
                </tr>
              ) : (
                payments.map((payment) => (
                  <tr key={payment._id}>
                    <td>
                      <div
                        style={{
                          fontWeight: 'bold',
                          textTransform: 'uppercase',
                          color: '#2f4483',
                        }}
                      >
                        {payment.name}
                      </div>
                      {payment.spouseInfo && payment.spouseInfo.name && (
                        <>
                          (Spouse:{' '}
                          <span
                            style={{
                              fontWeight: 'bold',
                              textTransform: 'uppercase',
                              color: '#78003c',
                            }}
                          >
                            {payment.spouseInfo.name}
                          </span>
                          )
                        </>
                      )}
                    </td>
                    <td className='text-end'>
                      {(() => {
                        const baseFee = payment.feeUsd || 0

                        const extraOne =
                          payment.extraOneNight === 1 &&
                          payment.extraOnePaid === 0
                            ? 70
                            : 0
                        const extraTwo =
                          payment.extraTwoNight === 1 &&
                          payment.extraTwoPaid === 0
                            ? 140
                            : 0
                        const blend =
                          payment.churchBlending === 1 &&
                          payment.churchBlendingPaid === 0
                            ? payment.spouseInfo
                              ? 700
                              : 350
                            : 0
                        const blendSabah =
                          payment.churchBlendingSabah === 1 &&
                          payment.churchBlendingSabahPaid === 0
                            ? payment.spouseInfo
                              ? 900
                              : 450
                            : 0
                        const blendSarawak =
                          payment.churchBlendingSarawak === 1 &&
                          payment.churchBlendingSarawakPaid === 0
                            ? payment.spouseInfo
                              ? 900
                              : 450
                            : 0

                        const totalExtra =
                          extraOne +
                          extraTwo +
                          blend +
                          blendSabah +
                          blendSarawak

                        return (
                          <>
                            {baseFee.toFixed(2)}
                            {totalExtra > 0 && (
                              <Tooltip title='Extra Charges'>
                                <span style={{ cursor: 'help' }}>
                                  {' '}
                                  (+{totalExtra.toFixed(2)})
                                </span>
                              </Tooltip>
                            )}
                          </>
                        )
                      })()}
                    </td>

                    <td className='text-end'>
                      <input
                        type='checkbox'
                        checked={selectedPayments.includes(payment._id)}
                        onChange={(e) =>
                          handleCheckboxChange(payment._id, e.target.checked)
                        }
                        style={{ transform: 'scale(1.5)', cursor: 'pointer' }}
                      />
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        <Modal
          open={isModalVisible}
          onCancel={handleCancel}
          footer={null}
          width={850}
          destroyOnClose
        >
          <IntlPaymentRecords onCancel={handleCancel} />
        </Modal>

        <Modal
          open={isQrModalVisible}
          onCancel={handleQrModalCancel}
          footer={
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <button className='close-btn' onClick={handleQrModalCancel}>
                Close
              </button>
            </div>
          }
          title={<div style={{ textAlign: 'center' }}>QR Code for Payment</div>}
          centered
        >
          <div className='d-flex justify-content-center'>
            <img
              src='/mbb_qr.jpg'
              alt='QR Code for eWallet Payment'
              style={{ maxWidth: '70%', height: 'auto' }}
            />
          </div>
        </Modal>
      </div>
    </Layout>
  )
}

export default IntlPaymentList
