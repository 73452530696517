import React from 'react'
import { useNavigate } from 'react-router-dom'

const IntlIndividualTransportView = ({ data }) => {
  const navigate = useNavigate()
  return (
    <div
      className='bg-white py-3 px-4 rounded shadow-sm border mb-4'
      style={{ maxWidth: '70%', margin: '0 auto' }}
    >
      <div className='d-flex justify-content-between align-items-center mb-4'>
        <div className='text-uppercase fw-bold text-success'>Details</div>
      </div>

      <div className='row g-3'>
        {/* ARRIVAL */}
        <div className='col-md-4'>
          <label className='fw-semibold text-muted'>Arrival Date</label>
          <div>{data.arrivalDate?.slice(0, 10) || '-'}</div>
        </div>
        <div className='col-md-4'>
          <label className='fw-semibold text-muted'>Arrival Time</label>
          <div>{data.arrivalTime || '-'}</div>
        </div>
        <div className='col-md-4'>
          <label className='fw-semibold text-muted'>Arrival Info</label>
          <div>{data.arrivalInfo || '-'}</div>
        </div>

        {/* DEPARTURE */}
        <div className='col-md-4'>
          <label className='fw-semibold text-muted'>Departure Date</label>
          <div>{data.departDate?.slice(0, 10) || '-'}</div>
        </div>
        <div className='col-md-4'>
          <label className='fw-semibold text-muted'>Departure Time</label>
          <div>{data.departTime || '-'}</div>
        </div>
        <div className='col-md-4'>
          <label className='fw-semibold text-muted'>Departure Info</label>
          <div>{data.departInfo || '-'}</div>
        </div>

        <div className='col-md-12'>
          <div className='d-flex justify-content-between align-items-end'>
            <div>
              <label className='fw-semibold text-muted d-block'>Remark</label>
              <div>{data.remark || '-'}</div>
            </div>
            <button
              className='btn btn-primary'
              onClick={() =>
                navigate(
                  `/itero/hotel-reservation/transportation/edit${
                    data.spouse ? '?type=spouse' : ''
                  }`
                )
              }
            >
              Edit
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default IntlIndividualTransportView
