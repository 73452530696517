import React, { useEffect, useState } from 'react'
import Layout from '../../components/Layout/Layout'
import axios from 'axios'
import PaginationButtons from '../../components/shared/PaginationButtons'
import { Link } from 'react-router-dom'
import { Tooltip } from 'antd'
import LoadingSpinner from '../../components/shared/LoadingSpinner'
import { toast } from 'react-toastify'

const AdminDashboard = () => {
  const [events, setEvents] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [loading, setLoading] = useState(false)
  const [totalUsers, setTotalUsers] = useState(0)
  const [totalBro, setTotalBro] = useState(0)
  const [totalSis, setTotalSis] = useState(0)
  const [totalLocalities, setTotalLocalities] = useState(0)
  const [currencyInput, setCurrencyInput] = useState(0)
  const [updatedAt, setUpdatedAt] = useState(null)
  const [updatedName, setUpdatedName] = useState('')

  const fetchDashboardData = async (page = 1) => {
    setLoading(true)
    try {
      const { data } = await axios.get(
        `/api/v1/admin/dashboard/summary?page=${page}`
      )
      const processedEvents = data.events.map((event) => ({
        ...event,
        nameEn: event.nameEn
          .toLowerCase()
          .split(' ')
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' '),
      }))

      setEvents(processedEvents)
      setCurrentPage(data.currentPage)
      setTotalPages(data.totalPages)
      setTotalUsers(data.totalUsers)
      setTotalBro(data.totalBro)
      setTotalSis(data.totalSis)
      setTotalLocalities(data.totalLocalities)
      setCurrencyInput(data.currency?.amount || 0)
      setUpdatedAt(data.currency?.updatedAt || null)
      setUpdatedName(data.currency?.name || '')
    } catch (error) {
      console.error('Error fetching dashboard data:', error)
    }
    setLoading(false)
  }

  useEffect(() => {
    fetchDashboardData()
  }, [])

  const handleCurrencySubmit = async (e) => {
    e.preventDefault()
    const parsed = parseFloat(currencyInput)

    if (!parsed || parsed <= 0) {
      toast.error('Please enter a valid currency amount.')
      return
    }
    const confirmUpdate = window.confirm(
      'Confirm update the USD currency exchange rate?'
    )
    if (!confirmUpdate) return
    const fixedAmount = Number(parsed.toFixed(2))
    try {
      const response = await axios.post(
        '/api/v1/admin/dashboard/currency/update',
        { amount: fixedAmount }
      )

      setCurrencyInput(response.data.amount.toFixed(2))
      setUpdatedAt(response.data.updatedAt)
      setUpdatedName(response.data.name)
      toast.success('Currency updated successfully!')
    } catch (error) {
      toast.error('Failed to update currency')
    }
  }

  return (
    <Layout title={'Admin Dashboard'}>
      <div className='content flex-grow-1 py-3 px-2'>
        <div
          className='stats d-flex justify-content-between align-items-end mb-3'
          style={{ flexWrap: 'wrap' }}
        >
          <div className='d-flex'>
            <Link to='/admin/personal-account' className='text-decoration-none'>
              <div className='stat-box bg-success text-white p-3 me-4 rounded text-center'>
                <h2 className='m-0'>
                  {totalUsers}{' '}
                  <span style={{ fontSize: '17px' }}>
                    (Bro-{totalBro} | Sis-{totalSis})
                  </span>
                </h2>
                <p className='m-0'>Personal Account</p>
              </div>
            </Link>

            <Link to='/admin/church-account' className='text-decoration-none'>
              <div className='stat-box bg-primary text-white p-3 me-4 rounded text-center'>
                <h2 className='m-0'>{totalLocalities}</h2>
                <p className='m-0'>Local Church Account</p>
              </div>
            </Link>
          </div>

          <div className='text-end'>
            <form onSubmit={handleCurrencySubmit} style={{ minWidth: '250px' }}>
              <div className='mb-2'>
                <label htmlFor='currency' className='fw-bold mb-1'>
                  USD currency exchange rate:
                </label>
                <div className='d-flex align-items-center justify-content-end'>
                  <input
                    type='number'
                    step='0.0001'
                    id='currency'
                    value={currencyInput}
                    onChange={(e) => setCurrencyInput(e.target.value)}
                    className='form-control form-control-sm'
                    style={{ width: '100px' }}
                    min='0'
                    required
                  />
                  <button type='submit' className='btn btn-sm btn-warning'>
                    Update
                  </button>
                </div>
              </div>
            </form>
            <div className='text-muted' style={{ fontSize: '13px' }}>
              Updated by {updatedName} at{' '}
              {updatedAt
                ? (() => {
                    const date = new Date(updatedAt)
                    const day = String(date.getUTCDate()).padStart(2, '0')
                    const month = String(date.getUTCMonth() + 1).padStart(
                      2,
                      '0'
                    )
                    const year = date.getUTCFullYear()
                    const hours = String(date.getUTCHours()).padStart(2, '0')
                    const minutes = String(date.getUTCMinutes()).padStart(
                      2,
                      '0'
                    )
                    const seconds = String(date.getUTCSeconds()).padStart(
                      2,
                      '0'
                    )
                    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`
                  })()
                : 'N/A'}
            </div>
          </div>
        </div>

        <div className='event-stat'>
          <table className='table'>
            <thead>
              <tr>
                <th>Date</th>
                <th>Event</th>
                <th className='text-center'>Participants</th>
                <th className='text-center'>Transport</th>
                {/* status 1-confirmed 2-unpaid 3-paid */}
                <th>Payment</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan='5' className='text-center'>
                    <LoadingSpinner />
                  </td>
                </tr>
              ) : events.length > 0 ? (
                events.map((event) => (
                  <tr key={event._id}>
                    <td>
                      {(() => {
                        const startDt = new Date(event.startDt)
                        const endDt = new Date(event.endDt)

                        const options = {
                          month: 'short',
                          day: 'numeric',
                          year: 'numeric',
                        }

                        const shortMonth = startDt.toLocaleDateString(
                          undefined,
                          { month: 'short' }
                        )

                        // Same day
                        if (
                          startDt.getFullYear() === endDt.getFullYear() &&
                          startDt.getMonth() === endDt.getMonth() &&
                          startDt.getDate() === endDt.getDate()
                        ) {
                          return startDt.toLocaleDateString(undefined, options)
                        }

                        if (
                          startDt.getFullYear() === endDt.getFullYear() &&
                          startDt.getMonth() === endDt.getMonth()
                        ) {
                          return `${shortMonth} ${startDt.getDate()}–${endDt.getDate()}, ${startDt.getFullYear()}`
                        }

                        return `${startDt.toLocaleDateString(
                          undefined,
                          options
                        )} – ${endDt.toLocaleDateString(undefined, options)}`
                      })()}
                    </td>

                    <td>
                      {event.participants > 0 ? (
                        <Link
                          to={
                            event.regType1 === 1
                              ? `/admin/registration/data/${event._id}`
                              : `/admin/registration/online/data/${event._id}`
                          }
                          className='text-decoration-none'
                          style={{ color: '#000' }}
                        >
                          {event.nameEn}
                        </Link>
                      ) : (
                        <span>{event.nameEn || 0}</span>
                      )}
                    </td>
                    <td className='text-center'>
                      {event.participants > 0 ? (
                        <Link
                          to={
                            event.regType1 === 1
                              ? `/admin/registration/data/${event._id}`
                              : `/admin/registration/online/data/${event._id}`
                          }
                          className='text-decoration-none fw-bold'
                          style={{ color: '#006ba1' }}
                        >
                          {event.participants}
                        </Link>
                      ) : (
                        <span>{event.participants || 0}</span>
                      )}
                    </td>

                    <td className='text-center'>
                      {event.regType1 === 1 && event.transports > 0 ? (
                        <Link
                          to={`/admin/registration/transport/${event._id}`}
                          className='text-decoration-none fw-bold'
                          style={{ color: '#006ba1' }}
                        >
                          {event.transports}
                        </Link>
                      ) : event.regType1 === 1 ? (
                        event.transports || 0
                      ) : (
                        ''
                      )}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' }}>
                      {event.regType1 === 1 ? (
                        <>
                          <Link
                            to={`/admin/registration/payment/${event._id}`}
                            className='text-decoration-none '
                          >
                            <span className='me-2' style={{ color: '#1e7e34' }}>
                              <Tooltip title='Paid & Confirmed'>
                                <span
                                  style={{
                                    display: 'inline-block',
                                    width: '10px',
                                    height: '10px',
                                    borderRadius: '50%',
                                    backgroundColor: '#1e7e34',
                                    cursor: 'pointer',
                                  }}
                                ></span>{' '}
                                {event.paid || 0}
                              </Tooltip>
                            </span>

                            <span className='me-2' style={{ color: '#ba8b00' }}>
                              <Tooltip title='Processing'>
                                <span
                                  style={{
                                    display: 'inline-block',
                                    width: '10px',
                                    height: '10px',
                                    borderRadius: '50%',
                                    backgroundColor: '#ba8b00',
                                    cursor: 'pointer',
                                  }}
                                ></span>{' '}
                                {event.processing || 0}
                              </Tooltip>
                            </span>
                            <span style={{ color: '#bd2130' }}>
                              <Tooltip title='Pending Payment'>
                                <span
                                  style={{
                                    display: 'inline-block',
                                    width: '10px',
                                    height: '10px',
                                    borderRadius: '50%',
                                    backgroundColor: '#bd2130',
                                    cursor: 'pointer',
                                  }}
                                ></span>{' '}
                                {event.unpaid || 0}
                              </Tooltip>
                            </span>
                          </Link>
                        </>
                      ) : (
                        ''
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan='5' className='text-center'>
                    No Records
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <PaginationButtons
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={fetchDashboardData}
        />
      </div>
    </Layout>
  )
}

export default AdminDashboard
