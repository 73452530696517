import React, { useEffect, useState } from 'react'
import { Tooltip } from 'antd'

const ChurchPaymentRecords = ({ id, onCancel }) => {
  const [records, setRecords] = useState([])
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [expandedRows, setExpandedRows] = useState([])

  const slipRecords = records.filter((record) => record.paySlip)
  const nonSlipRecords = records.filter((record) => !record.paySlip)

  const isIntlEventStatic = id === '67cf0e922ea8904cab38ae7b'

  useEffect(() => {
    fetchPaymentRecords()
  }, [page])

  const fetchPaymentRecords = async () => {
    setLoading(true)
    try {
      const endpoint = isIntlEventStatic
        ? `/api/v1/church/intl/event/payment/records?page=${page}&limit=20`
        : `/api/v1/church/payment/records/${id}?page=${page}&limit=20`

      const response = await fetch(endpoint, { credentials: 'include' })

      if (response.ok) {
        const data = await response.json()
        setRecords(data.payments)
        setTotalPages(data.totalPages)
      } else {
        console.error('Error fetching payment records')
      }
    } catch (error) {
      console.error('Error:', error)
    } finally {
      setLoading(false)
    }
  }

  const toggleRow = (id) => {
    setExpandedRows((prev) =>
      prev.includes(id) ? prev.filter((rowId) => rowId !== id) : [...prev, id]
    )
  }

  return (
    <div>
      <h5 className='text-center'>Payment Records</h5>
      {loading ? (
        <div
          className='d-flex justify-content-center align-items-center'
          style={{ minHeight: '200px' }}
        >
          Loading payment records...
        </div>
      ) : records.length === 0 ? (
        <div
          className='d-flex justify-content-center align-items-center'
          style={{ minHeight: '200px' }}
        >
          No payment records found.
        </div>
      ) : (
        <>
          {/* First Table */}
          {slipRecords.length > 0 && (
            <>
              <table className='table'>
                <thead>
                  <tr>
                    <th>Payment Method</th>
                    <th className='text-end'>Amount</th>
                    <th className='text-center'>Paid For</th>
                    <th className='text-center'>Status</th>
                    <th className='text-end'>Date</th>
                  </tr>
                </thead>
                <tbody>
                  {slipRecords.map((record) => (
                    <React.Fragment key={record._id}>
                      <tr key={record._id}>
                        <td>
                          {record.paymentMethod === 1
                            ? 'Bank Transfer'
                            : record.paymentMethod === 2
                            ? 'Credit/Debit Card'
                            : record.paymentMethod === 3
                            ? 'QR Code'
                            : ''}

                          {record.paySlipSystem && (
                            <>
                              <a
                                href={`${process.env.REACT_APP_BACKEND_URL}/files/eventpay/${record.eventId}/${record.paySlipSystem}`}
                                target='_blank'
                                rel='noopener noreferrer'
                                style={{
                                  textDecoration: 'none',
                                  color: '#0d6efd',
                                }}
                                className='ms-2'
                              >
                                <i className='bi bi-file-earmark-pdf'></i>
                              </a>
                            </>
                          )}
                        </td>
                        <td className='text-end'>
                          {record.amount != null
                            ? parseFloat(record.amount).toFixed(2)
                            : ''}
                        </td>
                        <td className='text-center'>
                          <i
                            className={`bi ${
                              expandedRows.includes(record._id)
                                ? 'bi-people-fill'
                                : 'bi-people-fill text-primary'
                            }`}
                            style={{ cursor: 'pointer' }}
                            onClick={() => toggleRow(record._id)}
                          ></i>
                        </td>
                        <td className='text-center'>
                          <Tooltip
                            title={
                              record.paymentStatus === 1
                                ? 'Paid & Confirmed'
                                : record.paymentStatus === 3
                                ? 'Processing'
                                : ''
                            }
                          >
                            <span
                              style={{
                                display: 'inline-block',
                                width: '14px',
                                height: '14px',
                                borderRadius: '50%',
                                backgroundColor:
                                  record.paymentStatus === 1
                                    ? '#1e7e34'
                                    : record.paymentStatus === 3
                                    ? '#ba8b00'
                                    : 'transparent',
                                marginRight: '8px',
                                cursor: 'pointer',
                              }}
                            ></span>
                          </Tooltip>
                        </td>
                        <td className='text-end'>
                          {(() => {
                            const date = new Date(record.createdAt)
                            const day = String(date.getDate()).padStart(2, '0')
                            const month = String(date.getMonth() + 1).padStart(
                              2,
                              '0'
                            )
                            const year = date.getFullYear()
                            const time = date
                              .toISOString()
                              .split('T')[1]
                              .split('.')[0]
                            return `${day}/${month}/${year} ${time}`
                          })()}
                        </td>
                      </tr>
                      {expandedRows.includes(record._id) && (
                        <tr>
                          <td colSpan='6'>
                            <div>
                              <strong>Participants:</strong>
                              <ul className='mb-0'>
                                {(isIntlEventStatic
                                  ? record.userPayIntlLocal
                                  : record.userPay
                                )?.map((p, idx) => (
                                  <li key={idx}>{p.name}</li>
                                ))}
                              </ul>
                            </div>
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </>
          )}

          {/* Second Table */}
          {nonSlipRecords.length > 0 && (
            <>
              <table className='table'>
                <thead>
                  <tr>
                    <th>Reference No.</th>
                    <th>Transaction No.</th>
                    <th className='text-end'>Amount (RM)</th>
                    <th>Status</th>
                    <th className='text-end'>Date</th>
                  </tr>
                </thead>
                <tbody>
                  {nonSlipRecords.map((record) => (
                    <tr key={record._id}>
                      <td>{record.refNo || '-'}</td>
                      <td>{record.transactionId || '-'}</td>
                      <td className='text-end'>
                        {record.amount ? record.amount.toFixed(2) : '0.00'}
                      </td>
                      <td
                        style={{
                          color: record.status === '00' ? 'green' : 'orange',
                        }}
                      >
                        {record.status === '00' ? 'Successful' : 'Pending'}
                      </td>
                      <td className='text-end'>
                        {(() => {
                          const date = new Date(record.createdAt)
                          const day = String(date.getDate()).padStart(2, '0')
                          const month = String(date.getMonth() + 1).padStart(
                            2,
                            '0'
                          )
                          const year = date.getFullYear()
                          const time = date
                            .toISOString()
                            .split('T')[1]
                            .split('.')[0]
                          return `${day}/${month}/${year} ${time}`
                        })()}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}

          {/* Pagination */}
          {totalPages > 0 && (
            <div className='d-flex justify-content-center mt-3'>
              <button
                className='btn btn-outline-primary btn-sm me-2'
                disabled={page === 1}
                onClick={() => setPage(page - 1)}
              >
                Previous
              </button>

              {[...Array(totalPages)].map((_, index) => (
                <button
                  key={index + 1}
                  className={`btn btn-sm mx-1 px-2 ${
                    page === index + 1 ? 'btn-primary' : 'btn-outline-primary'
                  }`}
                  onClick={() => setPage(index + 1)}
                >
                  {index + 1}
                </button>
              ))}

              <button
                className='btn btn-outline-primary btn-sm ms-2'
                disabled={page === totalPages}
                onClick={() => setPage(page + 1)}
              >
                Next
              </button>
            </div>
          )}

          <div className='text-end mt-3'>
            <button
              className='btn btn-outline-secondary btn-sm'
              onClick={onCancel}
            >
              Close
            </button>
          </div>
        </>
      )}
    </div>
  )
}

export default ChurchPaymentRecords
