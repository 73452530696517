import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Layout from '../../../components/Layout/Layout'
import axios from 'axios'
import LoadingSpinner from '../../../components/shared/LoadingSpinner'
import IntlIndividualTransportView from './TransportView'
import IntlIndividualTransportForm from './TransportForm'
import useIsMobile from '../../../hooks/useIsMobile'

const IntlIndividualTransport = () => {
  const navigate = useNavigate()
  const isMobile = useIsMobile()
  const [participantTransport, setParticipantTransport] = useState(null)
  const [spouseTransport, setSpouseTransport] = useState(null)
  const [participantName, setParticipantName] = useState('')
  const [spouseName, setSpouseName] = useState('')
  const [hasSpouse, setHasSpouse] = useState(false)
  const [participantPaid, setParticipantPaid] = useState(true)
  const [eligibility, setEligibility] = useState({
    status: null,
    reason: '',
    message: '',
  })

  const checkEligibility = async () => {
    try {
      const res = await axios.get('/api/v1/intl/transport/individual/check', {
        withCredentials: true,
      })

      if (res.data.status === 2) {
        setEligibility({
          status: false,
          reason: 'not_paid',
          message:
            'Please complete your registration payment before submitting transportation details.',
        })
        return
      }

      setEligibility({
        status: res.data.success,
        reason: res.data.reason || '',
        message: res.data.message,
        isStay: res.data.isStay || false,
      })

      setParticipantTransport(res.data.data?.participant || null)
      setSpouseTransport(res.data.data?.spouse || null)
      setParticipantName(res.data.participantName || '')
      setSpouseName(res.data.spouseName || '')
      setHasSpouse(res.data.hasSpouse || false)
      setParticipantPaid(res.data.participantPaid !== false)
    } catch (error) {
      setEligibility({
        status: false,
        reason: 'error',
        message:
          'Please fill in lodging and meal details and complete your payment before submitting transport.',
      })
    }
  }

  useEffect(() => {
    checkEligibility()
  }, [])

  return (
    <Layout title={'Event Registration System (ERS)'}>
      <div className='p-3'>
        <div
          className='fw-bold mb-3 text-uppercase text-center'
          style={{
            color: '#5d3104',
            fontSize: '18px',
            borderBottom: '1px solid #d3c4a7',
            paddingBottom: '10px',
          }}
        >
          2025 September International Training for Elders and Responsible Ones
        </div>

        <div
          className={`d-flex flex-column flex-md-row justify-content-end gap-2 gap-md-4 ${
            isMobile ? 'mb-4' : 'mb-2'
          }`}
        >
          <button
            className='btn btn-success btn-sm'
            onClick={() => navigate('/')}
            style={{
              textTransform: 'uppercase',
              fontWeight: 600,
              paddingTop: isMobile ? '8px' : '6px',
              paddingBottom: isMobile ? '8px' : '6px',
              fontSize: isMobile ? '1rem' : '0.875rem',
            }}
          >
            Lodging & Meal
          </button>

          <button
            className='btn btn-success btn-sm'
            onClick={() => navigate('/itero/hotel-reservation/payment')}
            style={{
              textTransform: 'uppercase',
              fontWeight: 600,
              paddingTop: isMobile ? '8px' : '6px',
              paddingBottom: isMobile ? '8px' : '6px',
              fontSize: isMobile ? '1rem' : '0.875rem',
            }}
          >
            Payment
          </button>

          <button
            className='btn btn-success btn-sm'
            disabled
            style={{
              textTransform: 'uppercase',
              fontWeight: 600,
              cursor: 'not-allowed',
              paddingTop: isMobile ? '8px' : '6px',
              paddingBottom: isMobile ? '8px' : '6px',
              fontSize: isMobile ? '1rem' : '0.875rem',
            }}
          >
            Transportation
          </button>
        </div>

        <div
          className='fw-bold text-center mb-2'
          style={{
            fontSize: '17px',
            color: '#219119',
            textTransform: 'uppercase',
          }}
        >
          Transportation
        </div>

        {eligibility.status === null ? (
          <LoadingSpinner />
        ) : eligibility.status ? (
          <>
            {/* Info for non-stay users */}
            {eligibility.isStay === false && (
              <div
                className='mb-3'
                style={{
                  fontWeight: '600',
                  backgroundColor: '#f3f9fe',
                  border: '1px solid #006ba1',
                  color: '#006ba1',
                  padding: '12px 20px',
                  borderRadius: '6px',
                  maxWidth: '70%',
                  margin: '0 auto',
                  textAlign: 'center',
                  fontSize: '16px',
                }}
              >
                Note: Transportation is only provided between the airport and
                KSL Hotel (both to and from).
              </div>
            )}

            {/* PARTICIPANT */}
            {participantTransport ? (
              <>
                {hasSpouse && (
                  <div
                    className='fw-bold mb-2'
                    style={{
                      maxWidth: '70%',
                      margin: '0 auto',
                      color: '#2f4483',
                    }}
                  >
                    {participantTransport.name}
                  </div>
                )}

                <IntlIndividualTransportView
                  data={participantTransport}
                  onEdit={() =>
                    navigate(
                      '/itero/hotel-reservation/transport/edit?type=participant'
                    )
                  }
                />
              </>
            ) : (
              <>
                {hasSpouse && (
                  <div
                    className='fw-bold mb-2'
                    style={{
                      maxWidth: '70%',
                      margin: '0 auto',
                      color: '#2f4483',
                    }}
                  >
                    {participantName}
                  </div>
                )}

                <IntlIndividualTransportForm
                  name={participantName}
                  hasSpouse={false}
                  isSpouse={false}
                  onSuccess={checkEligibility}
                />
              </>
            )}

            {/* SPOUSE */}
            {hasSpouse &&
              (participantPaid ? (
                spouseTransport ? (
                  <>
                    <div
                      className='fw-bold mb-2'
                      style={{
                        maxWidth: '70%',
                        margin: '0 auto',
                        color: '#78003c',
                      }}
                    >
                      {spouseTransport.name}
                    </div>
                    <IntlIndividualTransportView
                      data={spouseTransport}
                      onEdit={() =>
                        navigate(
                          '/itero/hotel-reservation/transport/edit?type=spouse'
                        )
                      }
                    />
                  </>
                ) : (
                  <>
                    <div
                      className='fw-bold mb-2'
                      style={{
                        maxWidth: '70%',
                        margin: '0 auto',
                        color: '#78003c',
                      }}
                    >
                      {spouseName}
                    </div>
                    <IntlIndividualTransportForm
                      name={spouseName}
                      isSpouse={true}
                      onSuccess={checkEligibility}
                    />
                  </>
                )
              ) : (
                <div
                  className='text-center fw-bold'
                  style={{ color: '#006ba1', fontSize: '17px' }}
                >
                  Please complete your payment before submitting spouse
                  transportation details.
                </div>
              ))}
          </>
        ) : (
          <div
            className='text-center fw-bold'
            style={{ color: '#006ba1', fontSize: '17px' }}
          >
            {eligibility.message}
          </div>
        )}
      </div>
    </Layout>
  )
}

export default IntlIndividualTransport
