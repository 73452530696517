import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Modal } from 'antd'
import ContactUs from '../../../pages/General/ContactUs'

const ChurchMenuBar = ({ t }) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const showModal = () => {
    setIsModalVisible(true)
  }
  const handleCancel = () => {
    setIsModalVisible(false)
  }

  return (
    <nav
      className='navbar navbar-expand-lg navbar-light shadow px-3 py-0'
      style={{ backgroundColor: '#7a6844' }}
    >
      <ul
        className='navbar-nav mx-auto'
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <li className='nav-item me-3'>
          <Link className='nav-link text-white' to='/'>
            Homepage
          </Link>
        </li>
        <li className='nav-item me-3'>
          <span
            className='nav-link'
            style={{ cursor: 'pointer', color: '#fff' }}
            onClick={showModal}
          >
            Contact Us
          </span>
        </li>
        {/* <li className='nav-item me-3'>
          <Link className='nav-link text-white' to=''>
            User Guidelines
          </Link>
        </li> */}
      </ul>
      <Modal open={isModalVisible} onCancel={handleCancel} footer={null}>
        <ContactUs onCancel={handleCancel} />
      </Modal>
    </nav>
  )
}

export default ChurchMenuBar
