import React, { useEffect, useState } from 'react'
import Layout from '../../../components/Layout/Layout'
import axios from 'axios'
import PageTitle from '../../../components/shared/PageTitle'
import PaginationButtons from '../../../components/shared/PaginationButtons'
import { useNavigate } from 'react-router-dom'
import LoadingSpinner from '../../../components/shared/LoadingSpinner'

const AdminUserList = () => {
  const navigate = useNavigate()
  const [users, setUsers] = useState([])
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [loading, setLoading] = useState(false)
  const [searchData, setSearchData] = useState('')

  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      const fetchUsers = async () => {
        try {
          setLoading(true)
          const params = { page, limit: 30 }
          if (searchData.trim().length > 3) {
            params.search = searchData.trim()
          }

          const response = await axios.get('/api/v1/admin/user/list', {
            params,
          })
          setUsers(response.data.users)
          setTotalPages(Math.ceil(response.data.total / 30) || 1)
          setLoading(false)
        } catch (error) {
          console.error('Error fetching users:', error)
          setLoading(false)
        }
      }

      fetchUsers()
    }, 100)

    return () => clearTimeout(delayDebounce)
  }, [page, searchData])

  const handleClearSearch = () => {
    setSearchData('')
    setPage(1)
  }

  const handleSearchChange = (e) => {
    const value = e.target.value
    setSearchData(value)
    setPage(1)
  }

  return (
    <Layout title={'Personal Account'}>
      <div className='py-3 px-2'>
        <PageTitle title='Personal Account' />

        <div
          className='search-bar'
          style={{ display: 'flex', width: '50%', marginLeft: 'auto' }}
        >
          <input
            type='text'
            className='form-control'
            value={searchData}
            placeholder='Search Name / Email / Phone'
            onChange={handleSearchChange}
          />
          <button
            type='button'
            className='btn btn-secondary'
            disabled={!searchData}
            onClick={handleClearSearch}
          >
            Clear
          </button>
        </div>

        <div className='table-responsive mt-3'>
          <table className='table'>
            <thead>
              <tr>
                <th scope='col'>Name</th>
                <th scope='col'>Email / Phone</th>
                <th scope='col'>Locality</th>
                <th className='text-end' scope='col'>
                  Register Date
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user._id}>
                  <td>
                    <span
                      className='fw-bold'
                      style={{
                        color: user.gender === 1 ? '#2f4483' : '#78003c',
                      }}
                    >
                      {user.name.toUpperCase()}
                    </span>
                    {user.chiName && <div>{user.chiName}</div>}
                  </td>
                  <td>
                    <div>{user.email}</div>
                    <div>{user.phoneNo}</div>
                  </td>
                  <td>{user.locality.name}</td>
                  <td className='text-end'>
                    {user.registerDate
                      ? new Date(user.registerDate)
                          .toISOString()
                          .slice(0, 19)
                          .replace('T', ' ')
                      : 'N/A'}
                  </td>
                  <td className='text-end'>
                    <i
                      className='bi bi-pencil-square'
                      style={{ color: '#3c8dbc', cursor: 'pointer' }}
                      onClick={() =>
                        navigate(`/admin/personal-account/edit/${user._id}`)
                      }
                    ></i>
                  </td>
                </tr>
              ))}
              {!loading && users.length === 0 && (
                <tr>
                  <td colSpan='5' className='text-center text-muted'>
                    No records found.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {loading && <LoadingSpinner />}

        <PaginationButtons
          currentPage={page}
          totalPages={totalPages}
          onPageChange={(newPage) => setPage(newPage)}
        />
      </div>
    </Layout>
  )
}

export default AdminUserList
