const countryPhoneCodes = [
  { label: '+93 (Afghanistan)', value: '+93', country: 'AF' },
  { label: '+355 (Albania)', value: '+355', country: 'AL' },
  { label: '+376 (Andorra)', value: '+376', country: 'AD' },
  { label: '+374 (Armenia)', value: '+374', country: 'AM' },
  { label: '+54 (Argentina)', value: '+54', country: 'AR' },
  { label: '+61 (Australia)', value: '+61', country: 'AU' },
  { label: '+43 (Austria)', value: '+43', country: 'AT' },
  { label: '+994 (Azerbaijan)', value: '+994', country: 'AZ' },
  { label: '+1-242 (Bahamas)', value: '+1-242', country: 'BS' },
  { label: '+973 (Bahrain)', value: '+973', country: 'BH' },
  { label: '+880 (Bangladesh)', value: '+880', country: 'BD' },
  { label: '+1-246 (Barbados)', value: '+1-246', country: 'BB' },
  { label: '+375 (Belarus)', value: '+375', country: 'BY' },
  { label: '+32 (Belgium)', value: '+32', country: 'BE' },
  { label: '+501 (Belize)', value: '+501', country: 'BZ' },
  { label: '+975 (Bhutan)', value: '+975', country: 'BT' },
  { label: '+591 (Bolivia)', value: '+591', country: 'BO' },
  { label: '+55 (Brazil)', value: '+55', country: 'BR' },
  { label: '+673 (Brunei)', value: '+673', country: 'BN' },
  { label: '+359 (Bulgaria)', value: '+359', country: 'BG' },
  { label: '+855 (Cambodia)', value: '+855', country: 'KH' },
  { label: '+1 (Canada)', value: '+1', country: 'CA' },
  { label: '+56 (Chile)', value: '+56', country: 'CL' },
  { label: '+86 (China)', value: '+86', country: 'CN' },
  { label: '+57 (Colombia)', value: '+57', country: 'CO' },
  { label: '+506 (Costa Rica)', value: '+506', country: 'CR' },
  { label: '+385 (Croatia)', value: '+385', country: 'HR' },
  { label: '+53 (Cuba)', value: '+53', country: 'CU' },
  { label: '+357 (Cyprus)', value: '+357', country: 'CY' },
  { label: '+420 (Czech Republic)', value: '+420', country: 'CZ' },
  { label: '+45 (Denmark)', value: '+45', country: 'DK' },
  { label: '+593 (Ecuador)', value: '+593', country: 'EC' },
  { label: '+20 (Egypt)', value: '+20', country: 'EG' },
  { label: '+503 (El Salvador)', value: '+503', country: 'SV' },
  { label: '+372 (Estonia)', value: '+372', country: 'EE' },
  { label: '+358 (Finland)', value: '+358', country: 'FI' },
  { label: '+33 (France)', value: '+33', country: 'FR' },
  { label: '+995 (Georgia)', value: '+995', country: 'GE' },
  { label: '+49 (Germany)', value: '+49', country: 'DE' },
  { label: '+30 (Greece)', value: '+30', country: 'GR' },
  { label: '+502 (Guatemala)', value: '+502', country: 'GT' },
  { label: '+592 (Guyana)', value: '+592', country: 'GY' },
  { label: '+509 (Haiti)', value: '+509', country: 'HT' },
  { label: '+504 (Honduras)', value: '+504', country: 'HN' },
  { label: '+852 (Hong Kong)', value: '+852', country: 'HK' },
  { label: '+36 (Hungary)', value: '+36', country: 'HU' },
  { label: '+354 (Iceland)', value: '+354', country: 'IS' },
  { label: '+91 (India)', value: '+91', country: 'IN' },
  { label: '+62 (Indonesia)', value: '+62', country: 'ID' },
  { label: '+98 (Iran)', value: '+98', country: 'IR' },
  { label: '+353 (Ireland)', value: '+353', country: 'IE' },
  { label: '+39 (Italy)', value: '+39', country: 'IT' },
  { label: '+1-876 (Jamaica)', value: '+1-876', country: 'JM' },
  { label: '+81 (Japan)', value: '+81', country: 'JP' },
  { label: '+254 (Kenya)', value: '+254', country: 'KE' },
  { label: '+383 (Kosovo)', value: '+383', country: 'XK' },
  { label: '+965 (Kuwait)', value: '+965', country: 'KW' },
  { label: '+856 (Laos)', value: '+856', country: 'LA' },
  { label: '+371 (Latvia)', value: '+371', country: 'LV' },
  { label: '+423 (Liechtenstein)', value: '+423', country: 'LI' },
  { label: '+370 (Lithuania)', value: '+370', country: 'LT' },
  { label: '+352 (Luxembourg)', value: '+352', country: 'LU' },
  { label: '+60 (Malaysia)', value: '+60', country: 'MY' },
  { label: '+356 (Malta)', value: '+356', country: 'MT' },
  { label: '+52 (Mexico)', value: '+52', country: 'MX' },
  { label: '+373 (Moldova)', value: '+373', country: 'MD' },
  { label: '+377 (Monaco)', value: '+377', country: 'MC' },
  { label: '+976 (Mongolia)', value: '+976', country: 'MN' },
  { label: '+382 (Montenegro)', value: '+382', country: 'ME' },
  { label: '+212 (Morocco)', value: '+212', country: 'MA' },
  { label: '+95 (Myanmar)', value: '+95', country: 'MM' },
  { label: '+977 (Nepal)', value: '+977', country: 'NP' },
  { label: '+31 (Netherlands)', value: '+31', country: 'NL' },
  { label: '+64 (New Zealand)', value: '+64', country: 'NZ' },
  { label: '+505 (Nicaragua)', value: '+505', country: 'NI' },
  { label: '+389 (North Macedonia)', value: '+389', country: 'MK' },
  { label: '+47 (Norway)', value: '+47', country: 'NO' },
  { label: '+968 (Oman)', value: '+968', country: 'OM' },
  { label: '+92 (Pakistan)', value: '+92', country: 'PK' },
  { label: '+507 (Panama)', value: '+507', country: 'PA' },
  { label: '+675 (Papua New Guinea)', value: '+675', country: 'PG' },
  { label: '+595 (Paraguay)', value: '+595', country: 'PY' },
  { label: '+51 (Peru)', value: '+51', country: 'PE' },
  { label: '+63 (Philippines)', value: '+63', country: 'PH' },
  { label: '+48 (Poland)', value: '+48', country: 'PL' },
  { label: '+351 (Portugal)', value: '+351', country: 'PT' },
  { label: '+974 (Qatar)', value: '+974', country: 'QA' },
  { label: '+40 (Romania)', value: '+40', country: 'RO' },
  { label: '+7 (Russia)', value: '+7', country: 'RU' },
  { label: '+1-758 (Saint Lucia)', value: '+1-758', country: 'LC' },
  { label: '+378 (San Marino)', value: '+378', country: 'SM' },
  { label: '+966 (Saudi Arabia)', value: '+966', country: 'SA' },
  { label: '+381 (Serbia)', value: '+381', country: 'RS' },
  { label: '+65 (Singapore)', value: '+65', country: 'SG' },
  { label: '+421 (Slovakia)', value: '+421', country: 'SK' },
  { label: '+386 (Slovenia)', value: '+386', country: 'SI' },
  { label: '+27 (South Africa)', value: '+27', country: 'ZA' },
  { label: '+82 (South Korea)', value: '+82', country: 'KR' },
  { label: '+34 (Spain)', value: '+34', country: 'ES' },
  { label: '+94 (Sri Lanka)', value: '+94', country: 'LK' },
  { label: '+597 (Suriname)', value: '+597', country: 'SR' },
  { label: '+46 (Sweden)', value: '+46', country: 'SE' },
  { label: '+41 (Switzerland)', value: '+41', country: 'CH' },
  { label: '+886 (Taiwan)', value: '+886', country: 'TW' },
  { label: '+66 (Thailand)', value: '+66', country: 'TH' },
  { label: '+670 (Timor-Leste)', value: '+670', country: 'TL' },
  { label: '+1-868 (Trinidad and Tobago)', value: '+1-868', country: 'TT' },
  { label: '+216 (Tunisia)', value: '+216', country: 'TN' },
  { label: '+90 (Turkey)', value: '+90', country: 'TR' },
  { label: '+971 (UAE)', value: '+971', country: 'AE' },
  { label: '+44 (UK)', value: '+44', country: 'GB' },
  { label: '+380 (Ukraine)', value: '+380', country: 'UA' },
  { label: '+1 (USA)', value: '+1', country: 'US' },
  { label: '+598 (Uruguay)', value: '+598', country: 'UY' },
  { label: '+998 (Uzbekistan)', value: '+998', country: 'UZ' },
  { label: '+379 (Vatican City)', value: '+379', country: 'VA' },
  { label: '+58 (Venezuela)', value: '+58', country: 'VE' },
  { label: '+84 (Vietnam)', value: '+84', country: 'VN' },
  { label: '+263 (Zimbabwe)', value: '+263', country: 'ZW' },
]

export default countryPhoneCodes
