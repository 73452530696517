import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import ChurchRegistrationStayForm from './StayForm'
import ChurchRegistrationNonStayForm from './NonStayForm'
import ChurchIndex from '../Index'
import { useEvent } from '../../../context/event'

const ChurchRegistrationFormIndex = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { event } = useEvent()

  let defaultActiveTab = 'stayIn'
  if (event?.stayForm === 0 && event?.nonStayForm === 1) {
    defaultActiveTab = 'nonStayIn'
  }
  const [activeTab, setActiveTab] = useState(defaultActiveTab)

  if (event?.regTypeStatus !== 1) {
    return (
      <ChurchIndex>
        <div className='text-center fw-bold mb-3'>
          <span>
            <i className='bi bi-info-circle-fill text-danger'></i> Registration
            is Closed. If you want to register, please email us at
          </span>{' '}
          <a href='mailto:registration@localchurch.my'>
            registration@localchurch.my
          </a>
        </div>
        <div className='d-flex justify-content-center'>
          <button
            className='btn btn-warning btn-sm'
            onClick={() => navigate(`/event/${id}`)}
          >
            Return
          </button>
        </div>
      </ChurchIndex>
    )
  }

  return (
    <ChurchIndex>
      <div className='d-flex justify-content-end gap-4 mb-3'>
        <button
          className='btn btn-success btn-sm'
          style={{ textTransform: 'uppercase' }}
          onClick={() => navigate(`/event/${id}`)}
        >
          Registration List
        </button>
        <button
          className='btn btn-success btn-sm'
          disabled
          style={{
            textTransform: 'uppercase',
            fontWeight: 600,
            cursor: 'not-allowed',
            paddingTop: '6px',
            paddingBottom: '6px',
          }}
        >
          Registration Form
        </button>

        <button
          className='btn btn-success btn-sm'
          style={{ textTransform: 'uppercase' }}
          onClick={() => navigate(`/event/${id}/payment`)}
        >
          Payment
        </button>

        <button
          className='btn btn-success btn-sm'
          style={{ textTransform: 'uppercase' }}
          onClick={() => navigate(`/event/${id}/transportation`)}
        >
          Transportation
        </button>
      </div>

      <div
        className='fw-bold text-center mb-2'
        style={{
          fontSize: '17px',
          color: '#219119',
          textTransform: 'uppercase',
        }}
      >
        Registration Form
      </div>

      {event?.stayForm === 1 && event?.nonStayForm === 1 && (
        <div className='d-flex justify-content-left gap-4 mb-3'>
          <button
            className={`btn btn-sm ${
              activeTab === 'stayIn' ? 'btn-success' : 'btn-outline-secondary'
            }`}
            onClick={() => setActiveTab('stayIn')}
          >
            Stay-In
          </button>
          <button
            className={`btn btn-sm ${
              activeTab === 'nonStayIn'
                ? 'btn-success'
                : 'btn-outline-secondary'
            }`}
            onClick={() => setActiveTab('nonStayIn')}
          >
            Non-Stay In
          </button>
        </div>
      )}

      {event?.stayForm === 1 && activeTab === 'stayIn' && (
        <ChurchRegistrationStayForm />
      )}
      {event?.nonStayForm === 1 && activeTab === 'nonStayIn' && (
        <ChurchRegistrationNonStayForm />
      )}
    </ChurchIndex>
  )
}

export default ChurchRegistrationFormIndex
