import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import ChurchIndex from '../Index'
import { Modal, Tooltip } from 'antd'
import ChurchPaymentRecords from './Records'
import LoadingSpinner from '../../../components/shared/LoadingSpinner'
import axios from 'axios'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useEvent } from '../../../context/event'

const ChurchPaymentIndex = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const isIntlEventStatic = id === '67cf0e922ea8904cab38ae7b'

  const { event, loading } = useEvent()

  const [payments, setPayments] = useState([])
  const [selectedPayments, setSelectedPayments] = useState([])
  const [searchQuery, setSearchQuery] = useState('')

  const [bankInfo, setBankInfo] = useState(null)
  const fileInputRef = useRef()
  const [selectedFile, setSelectedFile] = useState(null)
  const [uploading, setUploading] = useState(false)

  const [errorMessage, setErrorMessage] = useState('')
  const [isModalVisible, setIsModalVisible] = useState(false)

  const [initialLoading, setInitialLoading] = useState(true)
  const [loadingMore, setLoadingMore] = useState(false)
  const [page, setPage] = useState(1)
  const [hasMore, setHasMore] = useState(true)

  const [isQrModalVisible, setIsQrModalVisible] = useState(false)
  const [expandedMethod, setExpandedMethod] = useState(null)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const observer = useRef()

  const handleCheckboxChange = (paymentId, isChecked) => {
    setSelectedPayments((prevSelected) => {
      let updatedSelectedPayments
      if (isChecked) {
        updatedSelectedPayments = [...prevSelected, paymentId]
      } else {
        updatedSelectedPayments = prevSelected.filter((id) => id !== paymentId)
      }

      return updatedSelectedPayments
    })
  }

  const totalSelectedAmount = useMemo(() => {
    const selectedSet = new Set(selectedPayments)
    return payments
      .filter((p) => selectedSet.has(p._id))
      .reduce((sum, p) => sum + (p.fee || 0), 0)
      .toFixed(2)
  }, [selectedPayments, payments])

  const fetchPayments = async (pageNumber = 1, search = '') => {
    if (pageNumber === 1) {
      setInitialLoading(true)
    } else {
      setLoadingMore(true)
    }

    try {
      const endpoint = isIntlEventStatic
        ? '/api/v1/church/intl/event/payment/unpaid-list'
        : `/api/v1/church/payment/unpaid-list/${id}`

      const response = await fetch(
        `${endpoint}?page=${pageNumber}&search=${encodeURIComponent(search)}`,
        { credentials: 'include' }
      )

      if (response.ok) {
        const data = await response.json()
        setPayments((prev) => (pageNumber === 1 ? data : [...prev, ...data]))
        if (data.length < 20) setHasMore(false)
      } else {
        const errorResponse = await response.json()
        console.error('Error fetching payments:', errorResponse.message)
      }
    } catch (error) {
      console.error('Error fetching payment data:', error)
    } finally {
      if (pageNumber === 1) {
        setInitialLoading(false)
      } else {
        setLoadingMore(false)
      }
    }
  }

  useEffect(() => {
    fetchPayments(page)
  }, [page])

  useEffect(() => {
    if (event?.paymentAcc) {
      axios
        .get(`/api/v1/church/payment/bank/${event.paymentAcc}`)
        .then((res) => setBankInfo(res.data))
        .catch((err) => console.error(err))
    }
  }, [event])

  const lastPaymentRef = useCallback(
    (node) => {
      if (loadingMore) return
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((prevPage) => prevPage + 1)
        }
      })
      if (node) observer.current.observe(node)
    },
    [loadingMore, hasMore]
  )

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0])
  }

  const handleUploadSubmit = async (paymentMethod) => {
    if (!selectedFile) {
      setErrorMessage('Please upload a file before submit.')
      return
    }
    if (!selectedPayments.length) {
      setErrorMessage('Please select at least one participant before submit.')
      return
    }
    setIsSubmitting(true)
    setErrorMessage('')

    const confirm = window.confirm('Confirm submit the payment?')
    if (!confirm) return

    try {
      setUploading(true)
      const formData = new FormData()
      formData.append('paySlip', selectedFile)
      formData.append('eventId', id)
      formData.append('paymentIds', JSON.stringify(selectedPayments))
      formData.append('amount', totalSelectedAmount)
      formData.append('paymentMethod', paymentMethod)

      const endpoint = isIntlEventStatic
        ? '/api/v1/church/intl/event/payment/transfer-qr'
        : '/api/v1/church/payment/transfer-qr'

      await axios.post(endpoint, formData, {
        withCredentials: true,
        headers: { 'Content-Type': 'multipart/form-data' },
      })

      toast.success('Payment submitted successfully!')
      setSelectedFile(null)
      fileInputRef.current && (fileInputRef.current.value = null)
      setPayments((prev) =>
        prev.filter((p) => !selectedPayments.includes(p._id))
      )
      setSelectedPayments([])
    } catch (error) {
      console.error('Upload failed:', error)
      toast.error(
        'Upload failed: ' + (error.response?.data?.message || 'Unknown error')
      )
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleSearchChange = (e) => {
    const value = e.target.value
    setSearchQuery(value)

    if (value.length > 3 || value.length === 0) {
      setPage(1)
      setHasMore(true)
      fetchPayments(1, value)
    }
  }

  const handleClearSearch = () => {
    setSearchQuery('')
    setPage(1)
    setHasMore(true)
    fetchPayments(1, '')
  }

  const showModal = () => setIsModalVisible(true)
  const handleCancel = () => setIsModalVisible(false)

  const toggleExpand = (value) => {
    setExpandedMethod((prev) => (prev === value ? null : value))
  }

  const handleQrModalCancel = () => setIsQrModalVisible(false)

  return (
    <ChurchIndex>
      {loading || !event ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className='d-flex justify-content-end gap-4 mb-3'>
            <button
              className='btn btn-success btn-sm'
              style={{ textTransform: 'uppercase' }}
              onClick={() => navigate(`/event/${id}`)}
            >
              Registration List
            </button>
            <button
              className='btn btn-success btn-sm'
              style={{ textTransform: 'uppercase' }}
              onClick={() => navigate(`/event/${id}/registration`)}
              disabled={event.regTypeStatus !== 1}
            >
              Registration Form
            </button>
            <button
              className='btn btn-success btn-sm'
              disabled
              style={{
                textTransform: 'uppercase',
                fontWeight: 600,
                cursor: 'not-allowed',
                paddingTop: '6px',
                paddingBottom: '6px',
              }}
            >
              Payment
            </button>

            <button
              className='btn btn-success btn-sm'
              style={{ textTransform: 'uppercase' }}
              onClick={() => navigate(`/event/${id}/transportation`)}
            >
              Transportation
            </button>
          </div>

          <div
            className='fw-bold text-center mb-2'
            style={{
              fontSize: '17px',
              color: '#219119',
              textTransform: 'uppercase',
            }}
          >
            Payment
          </div>

          <div
            className='mb-1 text-center'
            style={{ fontWeight: 600, color: '#951a26', fontSize: '17px' }}
          >
            <i className='bi bi-exclamation-circle-fill me-2'></i>
            All payments are non-refundable.
          </div>
          <div className='mb-3'>
            <div className='d-flex justify-content-between align-items-center mb-2'>
              <div
                style={{
                  color: '#2f4483',
                  fontWeight: '600',
                  fontSize: '17px',
                }}
              >
                Total Amount for Selected Payments: RM {totalSelectedAmount}
              </div>
              <button className='btn btn-info btn-sm' onClick={showModal}>
                Payment Records
              </button>
            </div>

            <div
              className='mt-1 p-2'
              style={{
                border: '1px solid #ccc',
                borderRadius: '8px',
                backgroundColor: '#fafafa',
              }}
            >
              <div className='d-flex justify-content-between align-items-center'>
                <div style={{ fontWeight: 'bold' }}>
                  Please choose a payment method from the options below, and
                  select the participants with pending payments from the list
                  below to complete your payment.
                </div>
              </div>
              {bankInfo && (
                <>
                  {[
                    ...(bankInfo.qrCode
                      ? [
                          {
                            value: '3',
                            label: 'QR eWallet',
                            icon: 'bi bi-qr-code',
                            description: (
                              <>
                                <div className='d-flex gap-2 mt-2'>
                                  <i
                                    className='bi bi-info-circle-fill'
                                    style={{ color: '#0d6efd' }}
                                  ></i>
                                  <span>
                                    Please select the participants below for
                                    this payment. Then scan the QR code with
                                    your eWallet app, upload the proof of
                                    payment, and submit.
                                  </span>
                                </div>
                                <div className='d-flex mt-1'>
                                  <div className='ms-4'>
                                    <div style={{ display: 'none' }}>
                                      Failed to load QR code
                                    </div>
                                    <div
                                      onClick={() => setIsQrModalVisible(true)}
                                      style={{
                                        cursor: 'pointer',
                                        color: '#0d6efd',
                                      }}
                                    >
                                      Click here to view QR code{' '}
                                      <i className='bi bi-qr-code'></i>
                                    </div>
                                  </div>
                                </div>
                                <div className='mt-3'>
                                  <label
                                    htmlFor='paymentSlip'
                                    className='form-label'
                                  >
                                    Upload your payment slip:
                                  </label>
                                </div>
                              </>
                            ),
                            requiresUpload: true,
                          },
                        ]
                      : []),

                    // Always include Bank Transfer
                    {
                      value: '1',
                      label: 'Bank Transfer',
                      icon: 'bi bi-bank',
                      description: (
                        <>
                          <div className='d-flex gap-2 mt-2'>
                            <i
                              className='bi bi-info-circle-fill'
                              style={{ color: '#0d6efd' }}
                            ></i>
                            <span>
                              Please select the participants below for this
                              payment, transfer the funds to our bank account,
                              upload the proof of payment, and then submit your
                              payment details.
                            </span>
                          </div>
                          <div
                            className='d-flex mt-1'
                            style={{ color: '#000' }}
                          >
                            <div className='ms-4'>
                              <div>Bank Account Information:</div>
                              <ul className='m-0' style={{ fontSize: '17px' }}>
                                {bankInfo?.bankName && (
                                  <li>Name of Bank: {bankInfo.bankName}</li>
                                )}
                                {bankInfo?.accName && (
                                  <li>Account Name: {bankInfo.accName}</li>
                                )}
                                {bankInfo?.accNo && (
                                  <li>Account Number: {bankInfo.accNo}</li>
                                )}
                                {bankInfo?.branch && (
                                  <li>Branch: {bankInfo.branch}</li>
                                )}
                                {bankInfo?.branchAddr && (
                                  <li>Branch Address: {bankInfo.branchAddr}</li>
                                )}
                                {bankInfo?.swiftCode && (
                                  <li>Swift Code: {bankInfo.swiftCode}</li>
                                )}
                              </ul>
                            </div>
                          </div>
                          <div className='mt-2'>
                            <label htmlFor='paymentSlip' className='form-label'>
                              Upload your payment slip:
                            </label>
                          </div>
                        </>
                      ),
                      requiresUpload: true,
                    },
                  ].map((method) => (
                    <div
                      key={method.value}
                      className='p-3 my-2 border rounded bg-white'
                      style={{ border: '1px solid #ccc', cursor: 'pointer' }}
                    >
                      <div
                        className='d-flex align-items-center justify-content-between'
                        onClick={() => toggleExpand(method.value)}
                      >
                        <div className='d-flex align-items-center'>
                          <i className={`${method.icon} me-2`} />
                          <strong>{method.label}</strong>
                        </div>
                        <i
                          className={`bi ${
                            expandedMethod === method.value
                              ? 'bi-chevron-up'
                              : 'bi-chevron-down'
                          }`}
                        ></i>
                      </div>

                      {expandedMethod === method.value && (
                        <>
                          <div className='mt-2'>{method.description}</div>
                          <div className='d-flex'>
                            <input
                              type='file'
                              className='form-control'
                              onChange={handleFileChange}
                            />
                            {!selectedFile || selectedPayments.length === 0 ? (
                              <Tooltip title='Upload payment slip and select a participant below.'>
                                <span>
                                  <button
                                    className='btn btn-primary'
                                    onClick={() =>
                                      handleUploadSubmit(method.value)
                                    }
                                    disabled
                                  >
                                    {isSubmitting ? (
                                      <span className='spinner-border spinner-border-sm me-2'></span>
                                    ) : (
                                      'Submit'
                                    )}
                                  </button>
                                </span>
                              </Tooltip>
                            ) : (
                              <button
                                className='btn btn-primary'
                                onClick={() => handleUploadSubmit(method.value)}
                                disabled={isSubmitting}
                              >
                                {isSubmitting ? (
                                  <span className='spinner-border spinner-border-sm me-2'></span>
                                ) : (
                                  'Submit'
                                )}
                              </button>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  ))}
                </>
              )}
            </div>

            {errorMessage && (
              <div
                style={{
                  color: 'red',
                  fontWeight: 'bold',
                  marginTop: '5px',
                  textAlign: 'right',
                }}
              >
                {errorMessage}
              </div>
            )}
          </div>

          <div
            className='text-center fw-bold'
            style={{ fontSize: '17px', color: '#1a7414' }}
          >
            Participants with Pending Payments
          </div>
          {/* <div className='d-flex justify-content-end align-items-center'>
            <div
              className='d-flex align-items-center'
              style={{ textAlign: 'right' }}
            >
              <div style={{ width: '300px' }}>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Search English Name'
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
              </div>

              <button
                className='btn btn-outline-secondary'
                onClick={handleClearSearch}
                disabled={searchQuery.length === 0}
              >
                Clear
              </button>
            </div>
          </div> */}

          <div
            className='table-responsive'
            style={{ maxHeight: '500px', overflowY: 'auto' }}
          >
            <table className='table'>
              <thead>
                <tr>
                  <th
                    style={{
                      position: 'sticky',
                      top: 0,
                      background: '#fff',
                      zIndex: 1,
                    }}
                  >
                    First / Last Name
                  </th>
                  <th
                    className='text-end'
                    style={{
                      position: 'sticky',
                      top: 0,
                      background: '#fff',
                      zIndex: 1,
                    }}
                  >
                    Fee (RM)
                  </th>

                  <th
                    className='text-end'
                    style={{
                      position: 'sticky',
                      top: 0,
                      background: '#fff',
                      zIndex: 1,
                    }}
                  >
                    <input
                      type='checkbox'
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedPayments(
                            payments.map((payment) => payment._id)
                          )
                        } else {
                          setSelectedPayments([])
                        }
                      }}
                      checked={
                        selectedPayments.length === payments.length &&
                        payments.length > 0
                      }
                      className='checkbox-large me-2'
                      style={{
                        transform: 'scale(1.5)',
                        cursor: 'pointer',
                      }}
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {initialLoading ? (
                  <tr>
                    <td colSpan='4' className='text-center'>
                      <LoadingSpinner />
                    </td>
                  </tr>
                ) : payments.length === 0 ? (
                  <tr>
                    <td colSpan='4' className='text-center'>
                      {searchQuery.length > 3
                        ? 'No records found.'
                        : 'No Pending Payment'}
                    </td>
                  </tr>
                ) : (
                  payments.map((payment, index) => (
                    <tr
                      key={payment._id}
                      ref={
                        payments.length === index + 1 ? lastPaymentRef : null
                      }
                    >
                      <td>
                        <div>
                          <span
                            style={{
                              fontWeight: 'bold',
                              textTransform: 'uppercase',
                              color:
                                payment.gender === 1 ? '#2f4483' : '#78003c',
                            }}
                          >
                            {payment.name} {payment.lastName}
                          </span>
                          {payment.chiName && (
                            <span className='ms-3'>{payment.chiName}</span>
                          )}
                        </div>
                      </td>

                      <td className='text-end'>
                        {payment.fee ? payment.fee.toFixed(2) : '0.00'}
                      </td>

                      <td className='text-end'>
                        <input
                          className='me-2'
                          type='checkbox'
                          checked={selectedPayments.includes(payment._id)}
                          onChange={(e) =>
                            handleCheckboxChange(payment._id, e.target.checked)
                          }
                          style={{
                            transform: 'scale(1.5)',
                            cursor: 'pointer',
                          }}
                        />
                      </td>
                    </tr>
                  ))
                )}
                {loadingMore && (
                  <tr>
                    <td colSpan='4' className='text-center'>
                      Loading more...
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <Modal
            open={isModalVisible}
            onCancel={handleCancel}
            footer={null}
            width={800}
            destroyOnClose
          >
            <ChurchPaymentRecords
              key={isModalVisible}
              id={id}
              onCancel={handleCancel}
            />
          </Modal>

          <Modal
            open={isQrModalVisible}
            onCancel={handleQrModalCancel}
            footer={
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <button className='close-btn' onClick={handleQrModalCancel}>
                  Close
                </button>
              </div>
            }
            title={
              <div style={{ textAlign: 'center' }}>QR Code for Payment</div>
            }
            centered
          >
            <div className='d-flex justify-content-center'>
              {bankInfo?.qrCode ? (
                <img
                  src={`/files/bank/${encodeURIComponent(bankInfo.qrCode)}`}
                  alt='QR Code for eWallet Payment'
                  style={{ maxWidth: '70%', height: 'auto' }}
                  onError={(e) => {
                    console.error('QR Code failed to load:', e)
                    e.target.style.display = 'none'
                  }}
                />
              ) : (
                <div>Loading QR Code...</div>
              )}
            </div>
          </Modal>
        </>
      )}
    </ChurchIndex>
  )
}

export default ChurchPaymentIndex
