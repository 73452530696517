import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Layout from '../../../components/Layout/Layout'
import axios from 'axios'
import { toast } from 'react-toastify'
import LoadingSpinner from '../../../components/shared/LoadingSpinner'
import Select from 'react-select'
import countryPhoneCodes from '../../../utils/countryPhoneCodes'

const IntlGroupRegisterEdit = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const [formData, setFormData] = useState(null)
  const [stayPackages, setStayPackages] = useState([])
  const [loading, setLoading] = useState(true)
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [pkgRes, regRes] = await Promise.all([
          axios.get('/api/v1/intl/form', { withCredentials: true }),
          axios.get(`/api/v1/intl/group/form/${id}`, { withCredentials: true }),
        ])

        setStayPackages(pkgRes.data)

        const data = regRes.data.data

        setFormData({
          name: data.name || '',
          email: data.email || '',
          icNo: data.icNo || '',
          age: data.age || '',
          phoneCode: data.phoneCode || '',
          phoneNo: data.phoneNo || '',
          packageId: data.packageId || '',
          feeUsd: data.feeUsd || '',
          remark: data.remark || '',
          mealPackage: data.mealPackage || '',
          onBehalf: data.onBehalf?.toString() || '',
          meeting: data.meeting || false,
          extraOneNight: data.extraOneNight || false,
          extraTwoNight: data.extraTwoNight || false,
          churchBlending: data.churchBlending || false,
          churchBlendingSabah: data.churchBlendingSabah || false,
          churchBlendingSarawak: data.churchBlendingSarawak || false,
          extraOnePaid: data.extraOnePaid || 0,
          extraTwoPaid: data.extraTwoPaid || 0,
          churchBlendingPaid: data.churchBlendingPaid || 0,
          churchBlendingSabahPaid: data.churchBlendingSabahPaid || 0,
          churchBlendingSarawakPaid: data.churchBlendingSarawakPaid || 0,
          spouseInfo: data.spouseInfo || null,
          status: data.status,
        })
      } catch (err) {
        toast.error('Failed to load data')
        navigate('/')
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [id, navigate])

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target
    setFormData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsSubmitting(true)
    try {
      await axios.put(`/api/v1/intl/group/form/${id}`, formData, {
        withCredentials: true,
      })
      toast.success('Updated successfully!')
      navigate('/')
    } catch (error) {
      toast.error('Update failed.')
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <Layout title={'2025 September ITERO'}>
      <div className='p-2'>
        <div
          className='mb-3 text-uppercase'
          style={{
            textAlign: 'center',
            color: '#5d3104',
            fontSize: '18px',
            borderBottom: '1px solid #d3c4a7',
            paddingBottom: '10px',
            fontWeight: 'bold',
          }}
        >
          2025 September International Training for Elders and Responsible Ones
        </div>

        <div className='d-flex justify-content-end gap-4 mb-2'>
          <button
            className='btn btn-success btn-sm'
            style={{ textTransform: 'uppercase', fontWeight: 600 }}
            onClick={() => navigate('/')}
          >
            Lodging & Meal List
          </button>
          <button
            className='btn btn-success btn-sm'
            disabled
            style={{
              textTransform: 'uppercase',
              fontWeight: 600,
              cursor: 'not-allowed',
            }}
          >
            Lodging & Meal Form
          </button>
          <button
            className='btn btn-success btn-sm'
            style={{ textTransform: 'uppercase', fontWeight: 600 }}
            onClick={() => navigate('/itero/hotel-reservation/payment')}
          >
            Payment
          </button>
          <button
            className='btn btn-success btn-sm'
            style={{ textTransform: 'uppercase', fontWeight: 600 }}
            onClick={() => navigate('/itero/hotel-reservation/transportation')}
          >
            Transportation
          </button>
        </div>
        <div
          className='fw-bold text-center mb-2'
          style={{ fontSize: '17px', color: '#219119' }}
        >
          Edit Lodging & Meal
        </div>

        {loading || !formData ? (
          <LoadingSpinner />
        ) : (
          <form className='border rounded p-3'>
            <div className='row g-3'>
              <div className='col-md-3 col-sm-6'>
                <label className='form-label'>Name</label>
                <input
                  type='text'
                  className='form-control'
                  value={formData.name}
                  disabled
                  style={{
                    backgroundColor: '#fcfcfc',
                    color: '#006ba1',
                    fontWeight: '600',
                  }}
                />
              </div>
              <div className='col-md-2 col-sm-6'>
                <label className='form-label'>Email</label>
                <input
                  type='email'
                  className='form-control'
                  value={formData.email}
                  disabled
                  style={{
                    backgroundColor: '#fcfcfc',
                    color: '#006ba1',
                    fontWeight: '600',
                  }}
                />
              </div>

              <div className='col-md-2 col-sm-6'>
                <label className='form-label'>
                  Passport <span className='require'>*</span>
                </label>
                <input
                  type='text'
                  className='form-control'
                  name='icNo'
                  value={formData.icNo}
                  onChange={handleChange}
                />
              </div>
              <div className='col-md-1 col-sm-6'>
                <label className='form-label'>
                  Age <span className='require'>*</span>
                </label>
                <input
                  type='number'
                  className='form-control'
                  name='age'
                  value={formData.age}
                  onChange={handleChange}
                />
              </div>

              <div className='col-md-4 col-sm-6'>
                <label className='form-label'>
                  Phone Number <span className='require'>*</span>
                </label>
                <div className='d-flex align-items-center'>
                  <div style={{ width: '35%' }}>
                    <Select
                      options={countryPhoneCodes}
                      value={
                        formData.phoneCode
                          ? countryPhoneCodes.find(
                              (c) => c.value === formData.phoneCode
                            )
                          : null
                      }
                      onChange={(selected) =>
                        setFormData((prev) => ({
                          ...prev,
                          phoneCode: selected.value,
                        }))
                      }
                      isSearchable
                      placeholder='+Code'
                      styles={{
                        menu: (provided) => ({ ...provided, width: '220px' }),
                        control: (provided) => ({
                          ...provided,
                          minWidth: '110px',
                        }),
                      }}
                      menuPortalTarget={document.body}
                    />
                  </div>
                  <input
                    type='text'
                    className='form-control'
                    value={formData.phoneNo}
                    onChange={(e) => {
                      const value = e.target.value.replace(/\D/g, '')
                      setFormData((prev) => ({ ...prev, phoneNo: value }))
                    }}
                    style={{ flex: 1 }}
                  />
                </div>
              </div>
            </div>
            <div className='row g-3 mt-1'>
              <div className='col-md-4 col-sm-12'>
                <label className='form-label'>
                  Package <span className='require'>*</span>
                </label>
                <select
                  className='form-select'
                  name='packageId'
                  value={formData.packageId}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      packageId: e.target.value,
                      feeUsd:
                        stayPackages.find((pkg) => pkg.pkgId === e.target.value)
                          ?.feeUsd || '',
                    }))
                  }
                  disabled={formData.status !== 2}
                >
                  {stayPackages
                    .filter((pkg) => {
                      if (formData.status !== 1) return true
                      const isCurrent = pkg.pkgId === formData.packageId
                      const currentFee = parseFloat(formData.feeUsd)
                      const pkgFee = parseFloat(pkg.feeUsd)
                      return isCurrent || pkgFee >= currentFee
                    })
                    .map((pkg) => (
                      <option key={pkg.pkgId} value={pkg.pkgId}>
                        {pkg.packageNmEn}
                      </option>
                    ))}
                </select>
                {formData.status === 3 && (
                  <div
                    className='text-danger mt-1'
                    style={{ fontSize: '0.875rem', fontWeight: '500' }}
                  >
                    Package editing or upgrading is disabled until payment is
                    confirmed.
                  </div>
                )}
              </div>

              <div className='col-md-1 col-sm-6'>
                <label className='form-label' style={{ whiteSpace: 'nowrap' }}>
                  Fee (USD)
                </label>
                <input
                  type='text'
                  className='form-control'
                  value={
                    formData.feeUsd !== undefined && formData.feeUsd !== null
                      ? Number(formData.feeUsd).toFixed(2)
                      : ''
                  }
                  disabled
                />
              </div>

              <div className='col-md-2 col-sm-6'>
                <label className='form-label'>Meal Package</label>
                <select
                  className='form-select'
                  value={formData.mealPackage || '1'}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      mealPackage: e.target.value,
                    }))
                  }
                >
                  <option value='1'>No Preference</option>
                  <option value='2'>Vegan</option>
                  <option value='3'>Gluten Free</option>
                  <option value='4'>Vegan & Gluten Free</option>
                </select>
              </div>

              <div className='col-md-5 col-sm-6'>
                <label className='form-label'>Remarks</label>
                <input
                  type='text'
                  className='form-control'
                  value={formData.remark || ''}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      remark: e.target.value,
                    }))
                  }
                />
              </div>
            </div>

            {/* Lord's Day Morning Blending Meeting */}
            <div className='row mt-4' style={{ fontWeight: '600' }}>
              <div className='col-md-6 col-sm-12 d-flex align-items-center'>
                <input
                  type='checkbox'
                  id='meeting'
                  checked={formData.meeting || false}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      meeting: e.target.checked,
                    }))
                  }
                  style={{
                    transform: 'scale(1.3)',
                    cursor: 'pointer',
                    marginRight: '8px',
                  }}
                />
                <label
                  htmlFor='meeting'
                  className='mb-0'
                  style={{
                    cursor: 'pointer',
                  }}
                >
                  Attending Lord's Day Morning Blending Meeting
                </label>
              </div>
            </div>

            {/* Lock Message (if any field is disabled due to lock) */}
            {formData.status === 3 && (
              <div
                className='text-danger mt-2'
                style={{ fontSize: '0.875rem', fontWeight: '500' }}
              >
                Add-on editing is disabled until payment is confirmed.
              </div>
            )}
            <>
              {/* Church and Blending Trip */}
              <div className='row mt-3 ms-2' style={{ color: '#006ba1' }}>
                <label className='form-label'>
                  Churching and Blending Trip
                </label>
              </div>
              <div className='row mt-1' style={{ fontWeight: '600' }}>
                <div className='col-md-12 col-sm-12 d-flex align-items-center'>
                  <input
                    type='checkbox'
                    id='churchBlending'
                    checked={formData.churchBlending || false}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        churchBlending: e.target.checked,
                      }))
                    }
                    disabled={
                      formData.status !== 2 ||
                      (formData.churchBlending === 1 &&
                        formData.churchBlendingPaid === 1)
                    }
                    style={{
                      transform: 'scale(1.3)',
                      cursor:
                        formData.status !== 2 ||
                        (formData.churchBlending === 1 &&
                          formData.churchBlendingPaid === 1)
                          ? 'not-allowed'
                          : 'pointer',
                      marginRight: '8px',
                    }}
                  />
                  <label
                    htmlFor='churchBlending'
                    className='mb-0'
                    style={{
                      cursor:
                        formData.status !== 2 ||
                        (formData.churchBlending === 1 &&
                          formData.churchBlendingPaid === 1)
                          ? 'not-allowed'
                          : 'pointer',
                    }}
                  >
                    3 days 2 nights trip within Peninsular Malaysia from
                    September 28-30, 2025 ($350.00 USD per person)
                  </label>
                </div>
              </div>
              <div className='row mt-2' style={{ fontWeight: '600' }}>
                <div className='col-md-12 col-sm-12 d-flex align-items-center'>
                  <input
                    type='checkbox'
                    id='churchBlendingSabah'
                    checked={formData.churchBlendingSabah || false}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        churchBlendingSabah: e.target.checked,
                      }))
                    }
                    disabled={
                      formData.status !== 2 ||
                      (formData.churchBlendingSabah === 1 &&
                        formData.churchBlendingSabahPaid === 1)
                    }
                    style={{
                      transform: 'scale(1.3)',
                      cursor:
                        formData.status !== 2 ||
                        (formData.churchBlendingSabah === 1 &&
                          formData.churchBlendingSabahPaid === 1)
                          ? 'not-allowed'
                          : 'pointer',
                      marginRight: '8px',
                    }}
                  />
                  <label
                    htmlFor='churchBlendingSabah'
                    className='mb-0'
                    style={{
                      cursor:
                        formData.status !== 2 ||
                        (formData.churchBlendingSabah === 1 &&
                          formData.churchBlendingSabahPaid === 1)
                          ? 'not-allowed'
                          : 'pointer',
                    }}
                  >
                    4 days 3 nights trip within Sabah from September 28 –
                    October 1, 2025 ($450.00 USD per person)
                  </label>
                </div>
              </div>
              <div className='row mt-2' style={{ fontWeight: '600' }}>
                <div className='col-md-12 col-sm-12 d-flex align-items-center'>
                  <input
                    type='checkbox'
                    id='churchBlendingSarawak'
                    checked={formData.churchBlendingSarawak || false}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        churchBlendingSarawak: e.target.checked,
                      }))
                    }
                    disabled={
                      formData.status !== 2 ||
                      (formData.churchBlendingSarawak === 1 &&
                        formData.churchBlendingSarawakPaid === 1)
                    }
                    style={{
                      transform: 'scale(1.3)',
                      cursor:
                        formData.status !== 2 ||
                        (formData.churchBlendingSarawak === 1 &&
                          formData.churchBlendingSarawakPaid === 1)
                          ? 'not-allowed'
                          : 'pointer',
                      marginRight: '8px',
                    }}
                  />
                  <label
                    htmlFor='churchBlendingSarawak'
                    className='mb-0'
                    style={{
                      cursor:
                        formData.status !== 2 ||
                        (formData.churchBlendingSarawak === 1 &&
                          formData.churchBlendingSarawakPaid === 1)
                          ? 'not-allowed'
                          : 'pointer',
                    }}
                  >
                    4 days 3 nights trip within Sarawak from September 28 –
                    October 1, 2025 ($450.00 USD per person)
                  </label>
                </div>
              </div>

              <div className='row mt-3 ms-2' style={{ color: '#006ba1' }}>
                <label className='form-label'>
                  Additional Night Stay at the Hotel
                </label>
              </div>
              <div className='row mt-1' style={{ fontWeight: '600' }}>
                <div className='col-md-6 col-sm-12 d-flex align-items-center'>
                  <input
                    type='checkbox'
                    id='extraOneNight'
                    checked={formData.extraOneNight || false}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        extraOneNight: e.target.checked,
                      }))
                    }
                    disabled={
                      formData.status !== 2 ||
                      (formData.extraOneNight === 1 &&
                        formData.extraOnePaid === 1)
                    }
                    style={{
                      transform: 'scale(1.3)',
                      cursor:
                        formData.status !== 2 ||
                        (formData.extraOneNight === 1 &&
                          formData.extraOnePaid === 1)
                          ? 'not-allowed'
                          : 'pointer',
                      marginRight: '8px',
                    }}
                  />
                  <label
                    htmlFor='extraOneNight'
                    className='mb-0'
                    style={{
                      cursor:
                        formData.status !== 2 ||
                        (formData.extraOneNight === 1 &&
                          formData.extraOnePaid === 1)
                          ? 'not-allowed'
                          : 'pointer',
                    }}
                  >
                    One Additional Night ($70.00 USD per room)
                  </label>
                </div>
              </div>

              {/* Two Additional Nights Stay */}
              <div className='row mt-2' style={{ fontWeight: '600' }}>
                <div className='col-md-6 col-sm-12 d-flex align-items-center'>
                  <input
                    type='checkbox'
                    id='extraTwoNight'
                    checked={formData.extraTwoNight || false}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        extraTwoNight: e.target.checked,
                      }))
                    }
                    disabled={
                      formData.status !== 2 ||
                      (formData.extraTwoNight === 1 &&
                        formData.extraTwoPaid === 1)
                    }
                    style={{
                      transform: 'scale(1.3)',
                      cursor:
                        formData.status !== 2 ||
                        (formData.extraTwoNight === 1 &&
                          formData.extraTwoPaid === 1)
                          ? 'not-allowed'
                          : 'pointer',
                      marginRight: '8px',
                    }}
                  />
                  <label
                    htmlFor='extraTwoNight'
                    className='mb-0'
                    style={{
                      cursor:
                        formData.status !== 2 ||
                        (formData.extraTwoNight === 1 &&
                          formData.extraTwoPaid === 1)
                          ? 'not-allowed'
                          : 'pointer',
                    }}
                  >
                    Two Additional Nights ($140.00 USD per room)
                  </label>
                </div>
              </div>
            </>

            {formData.spouseInfo && (
              <>
                <hr className='my-3' />
                <h6 className='fw-bold mb-3 text-primary'>
                  Spouse Information
                </h6>

                <div className='row g-3'>
                  <div className='col-md-3 col-sm-6'>
                    <label className='form-label'>Name</label>
                    <input
                      type='text'
                      className='form-control'
                      value={formData.spouseInfo.name}
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          spouseInfo: {
                            ...prev.spouseInfo,
                            name: e.target.value,
                          },
                        }))
                      }
                    />
                  </div>

                  <div className='col-md-2 col-sm-6'>
                    <label className='form-label'>Email</label>
                    <input
                      type='email'
                      className='form-control'
                      value={formData.spouseInfo.email}
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          spouseInfo: {
                            ...prev.spouseInfo,
                            email: e.target.value,
                          },
                        }))
                      }
                    />
                  </div>

                  <div className='col-md-2 col-sm-6'>
                    <label className='form-label'>Passport</label>
                    <input
                      type='text'
                      className='form-control'
                      value={formData.spouseInfo.icNo}
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          spouseInfo: {
                            ...prev.spouseInfo,
                            icNo: e.target.value,
                          },
                        }))
                      }
                    />
                  </div>

                  <div className='col-md-1 col-sm-6'>
                    <label className='form-label'>Age</label>
                    <input
                      type='number'
                      className='form-control'
                      value={formData.spouseInfo.age}
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          spouseInfo: {
                            ...prev.spouseInfo,
                            age: e.target.value,
                          },
                        }))
                      }
                    />
                  </div>

                  <div className='col-md-4 col-sm-6'>
                    <label className='form-label'>Phone Number</label>
                    <div className='d-flex align-items-center'>
                      <div style={{ width: '35%' }}>
                        <Select
                          options={countryPhoneCodes}
                          value={
                            formData.spouseInfo.phoneCode
                              ? countryPhoneCodes.find(
                                  (c) =>
                                    c.value === formData.spouseInfo.phoneCode
                                )
                              : null
                          }
                          onChange={(selected) =>
                            setFormData((prev) => ({
                              ...prev,
                              spouseInfo: {
                                ...prev.spouseInfo,
                                phoneCode: selected.value,
                              },
                            }))
                          }
                          isSearchable
                          placeholder='+Code'
                          styles={{
                            menu: (provided) => ({
                              ...provided,
                              width: '220px',
                            }),
                            control: (provided) => ({
                              ...provided,
                              minWidth: '110px',
                            }),
                          }}
                          menuPortalTarget={document.body}
                        />
                      </div>
                      <input
                        type='text'
                        className='form-control'
                        value={formData.spouseInfo.phoneNo}
                        onChange={(e) => {
                          const value = e.target.value.replace(/\D/g, '')
                          setFormData((prev) => ({
                            ...prev,
                            spouseInfo: {
                              ...prev.spouseInfo,
                              phoneNo: value,
                            },
                          }))
                        }}
                        style={{ flex: 1 }}
                      />
                    </div>
                  </div>

                  <div className='col-md-3 col-sm-6'>
                    <label className='form-label'>Meal Package</label>
                    <select
                      className='form-select'
                      value={formData.spouseInfo.mealPackage || '1'}
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          spouseInfo: {
                            ...prev.spouseInfo,
                            mealPackage: e.target.value,
                          },
                        }))
                      }
                    >
                      <option value='1'>No Preference</option>
                      <option value='2'>Vegan</option>
                      <option value='3'>Gluten Free</option>
                      <option value='4'>Vegan & Gluten Free</option>
                    </select>
                  </div>

                  <div className='col-md-5 col-sm-6'>
                    <label className='form-label'>Remark</label>
                    <input
                      type='text'
                      className='form-control'
                      value={formData.spouseInfo.remark || ''}
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          spouseInfo: {
                            ...prev.spouseInfo,
                            remark: e.target.value,
                          },
                        }))
                      }
                    />
                  </div>

                  <div
                    className='col-md-6 col-sm-12 d-flex align-items-center mt-3'
                    style={{ fontWeight: '600' }}
                  >
                    <input
                      type='checkbox'
                      id='spouseMeeting'
                      checked={formData.spouseInfo.meeting || false}
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          spouseInfo: {
                            ...prev.spouseInfo,
                            meeting: e.target.checked,
                          },
                        }))
                      }
                      style={{
                        transform: 'scale(1.3)',
                        cursor: 'pointer',
                        marginRight: '8px',
                      }}
                    />
                    <label
                      htmlFor='spouseMeeting'
                      className='mb-0'
                      style={{ cursor: 'pointer' }}
                    >
                      Attending Lord's Day Morning Blending Meeting
                    </label>
                  </div>
                </div>
              </>
            )}

            <div className='d-flex justify-content-center mt-4'>
              <button
                className='btn btn-warning me-4'
                type='button'
                disabled={isSubmitting}
                onClick={handleSubmit}
              >
                {isSubmitting ? 'Submitting...' : 'Update'}
              </button>
              <button
                type='button'
                className='btn btn-secondary'
                onClick={() => navigate('/')}
              >
                Cancel
              </button>
            </div>
          </form>
        )}
      </div>
    </Layout>
  )
}

export default IntlGroupRegisterEdit
