import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import PaginationButtons from '../../../components/shared/PaginationButtons'
import LoadingSpinner from '../../../components/shared/LoadingSpinner'
import { Tooltip } from 'antd'
import { toast } from 'react-toastify'

const AdminRegistrationTable = () => {
  const { id } = useParams()
  const [registrations, setRegistrations] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [loading, setLoading] = useState(true)
  const [searchTerm, setSearchTerm] = useState('')
  const [searchTimeout, setSearchTimeout] = useState(null)
  const limit = 30

  useEffect(() => {
    fetchRegistrations()
  }, [id, currentPage])

  const fetchRegistrations = async (searchValue = searchTerm) => {
    setLoading(true)
    try {
      const response = await axios.get(
        `/api/v1/admin/registration/list/${id}?page=${currentPage}&limit=${limit}&search=${searchValue}`
      )
      setRegistrations(response.data.registrations)
      setTotalPages(response.data.totalPages)
    } catch (error) {
      console.error('Error fetching registration list:', error)
    }
    setLoading(false)
  }

  const handleSearchChange = (e) => {
    const value = e.target.value
    setSearchTerm(value)

    if (searchTimeout) {
      clearTimeout(searchTimeout)
    }

    const newTimeout = setTimeout(() => {
      setCurrentPage(1)
      if (value.length >= 4 || value.length === 0) {
        fetchRegistrations(value)
      }
    }, 100)

    setSearchTimeout(newTimeout)
  }

  // Clear search
  const handleClearSearch = () => {
    setSearchTerm('')
    setCurrentPage(1)
    fetchRegistrations('')
  }

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm('Confirm delete?')
    if (!confirmDelete) return

    try {
      await axios.delete(`/api/v1/admin/registration/${id}`)
      setRegistrations((prev) => prev.filter((reg) => reg._id !== id))
      toast.success('Registration deleted successfully.')
    } catch (err) {
      console.error('Failed to delete registration:', err)
      if (err.response && err.response.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error('Error deleting registration.')
      }
    }
  }

  return (
    <div className='mt-4'>
      <div className='d-flex justify-content-between align-items-center mb-2'>
        <div className='d-flex align-items-center ms-auto'>
          <div style={{ width: '300px' }}>
            <input
              type='text'
              className='form-control'
              placeholder='Search Name / Email / Phone'
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>
          <button
            className='btn btn-secondary'
            onClick={handleClearSearch}
            disabled={!searchTerm}
          >
            Clear
          </button>
        </div>
      </div>

      <div className='table-responsive'>
        <table className='table'>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email/Phone</th>
              <th style={{ whiteSpace: 'nowrap' }}>Age (I.C./Passport)</th>
              <th>Lang.</th>
              <th>Registration</th>
              <th>Locality</th>
              <th className='text-center'>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan='9' className='text-center'>
                  <LoadingSpinner />
                </td>
              </tr>
            ) : registrations.length > 0 ? (
              registrations.map((reg) => (
                <React.Fragment key={reg._id}>
                  <tr
                    style={{
                      borderColor: reg.remark ? 'white' : '',
                    }}
                  >
                    <td>
                      <div
                        className='fw-bold'
                        style={{
                          textTransform: 'uppercase',
                          color: reg.gender === 1 ? '#2f4483' : '#78003c',
                        }}
                      >
                        {reg.name}
                      </div>
                      {reg.chiName && <div>{reg.chiName}</div>}
                    </td>
                    <td>
                      {reg.email && !reg.email.includes('@empty') && (
                        <div style={{ whiteSpace: 'nowrap' }}>
                          <i
                            className='bi bi-envelope-fill me-2'
                            style={{ color: '#7a6844' }}
                          ></i>
                          {reg.email}
                        </div>
                      )}
                      {reg.phoneNo && (
                        <div style={{ whiteSpace: 'nowrap' }}>
                          <i
                            className='bi bi-telephone-fill me-2'
                            style={{ color: '#7a6844' }}
                          ></i>
                          {reg.phoneNo}
                        </div>
                      )}
                    </td>
                    <td>
                      {reg.age} {reg.icNo ? `(${reg.icNo})` : ''}
                    </td>
                    <td>{['', 'English', '中文', 'BM'][reg.language]}</td>
                    <td>
                      {reg.packageNmEn} {reg.roomNo}
                    </td>
                    <td>{reg.localityName}</td>
                    <td className='text-center'>
                      <Tooltip
                        title={
                          reg.status === 1
                            ? 'Paid & Confirmed'
                            : reg.status === 2
                            ? 'Pending Payment'
                            : 'Processing'
                        }
                      >
                        <span
                          style={{
                            display: 'inline-block',
                            width: '14px',
                            height: '14px',
                            borderRadius: '50%',
                            backgroundColor:
                              reg.status === 1
                                ? '#1e7e34'
                                : reg.status === 2
                                ? 'red'
                                : '#ba8b00',
                            marginRight: '8px',
                            cursor: 'pointer',
                          }}
                        ></span>
                      </Tooltip>
                    </td>
                    <td className='text-end'>
                      {/* <i className='bi bi-pencil-square text-primary'></i> */}
                      <div>
                        <i
                          className='bi bi-trash3 text-danger ms-3'
                          style={{
                            cursor:
                              reg.status !== 2 ? 'not-allowed' : 'pointer',
                            pointerEvents: reg.status !== 2 ? 'none' : 'auto',
                            opacity: reg.status !== 2 ? 0.5 : 1,
                          }}
                          onClick={() =>
                            reg.status === 2 && handleDelete(reg._id)
                          }
                        ></i>
                      </div>
                    </td>
                  </tr>
                  {reg.remark && (
                    <tr>
                      <td colSpan='9' className='pt-0'>
                        <span className='me-2' style={{ fontWeight: 600 }}>
                          <i
                            className='bi bi-info-circle-fill'
                            style={{ color: '#5bc0de' }}
                          ></i>{' '}
                          Remark:
                        </span>
                        {reg.remark}
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))
            ) : (
              <tr>
                <td colSpan='9' className='text-center'>
                  No Records
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <PaginationButtons
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={(page) => setCurrentPage(page)}
      />
    </div>
  )
}

export default AdminRegistrationTable
