import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Layout from '../../../components/Layout/Layout'
import { Modal, Tooltip } from 'antd'
import IntlNewTransport from './NewTransport'
import axios from 'axios'
import { toast } from 'react-toastify'

const IntlTransportList = () => {
  const navigate = useNavigate()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [transportRecords, setTransportRecords] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    fetchTransportRecords()
  }, [])

  const fetchTransportRecords = async () => {
    setLoading(true)
    try {
      const response = await axios.get('/api/v1/intl/transport/records')
      if (response.data.success) {
        setTransportRecords(response.data.data)
      }
    } catch (error) {
      console.error('Error fetching transport records:', error)
    } finally {
      setLoading(false)
    }
  }

  const showModal = async () => {
    setIsModalOpen(true)
  }

  const handleAddSuccess = (newRecords) => {
    setTransportRecords((prevRecords) => [...newRecords, ...prevRecords])
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm('Confirm Delete?')
    if (!confirmDelete) return

    try {
      const response = await axios.delete(
        `/api/v1/intl/transport/records/${id}`
      )
      if (response.data.success) {
        setTransportRecords((prevRecords) =>
          prevRecords.filter((record) => record._id !== id)
        )
        toast.success('Record deleted successfully')
      }
    } catch (error) {
      console.error('Error deleting record:', error)
      toast.error('Failed to delete record')
    }
  }

  return (
    <Layout title={'Event Registration System (ERS)'}>
      <div className='p-2'>
        <div
          className='fw-bold mb-3 text-uppercase'
          style={{
            color: '#5d3104',
            textAlign: 'center',
            fontSize: '18px',
            borderBottom: '1px solid #d3c4a7',
            paddingBottom: '10px',
          }}
        >
          2025 September International Training for Elders and Responsible Ones
        </div>

        <div className='d-flex justify-content-end gap-4 mb-2'>
          <button
            className='btn btn-success btn-sm'
            style={{ textTransform: 'uppercase', fontWeight: 600 }}
            onClick={() => navigate('/')}
          >
            Lodging & Meal List
          </button>
          <button
            className='btn btn-success btn-sm'
            style={{ textTransform: 'uppercase', fontWeight: 600 }}
            onClick={() => navigate('/itero/hotel-reservation/form')}
          >
            Lodging & Meal Form
          </button>
          <button
            className='btn btn-success btn-sm'
            style={{ textTransform: 'uppercase', fontWeight: 600 }}
            onClick={() => navigate('/itero/hotel-reservation/payment')}
          >
            Payment
          </button>
          <button
            className='btn btn-success btn-sm'
            disabled
            style={{
              textTransform: 'uppercase',
              fontWeight: 600,
              cursor: 'not-allowed',
            }}
          >
            Transportation
          </button>
        </div>

        <div
          className='fw-bold text-center mb-2'
          style={{
            fontSize: '17px',
            color: '#219119',
            textTransform: 'uppercase',
          }}
        >
          Transportation
        </div>

        <div className='d-flex justify-content-between align-items-center mb-2'>
          <div className='ms-auto'>
            <button className='btn btn-primary btn-sm' onClick={showModal}>
              Add Transportation
            </button>
          </div>
        </div>

        <div className='table-responsive'>
          <table className='table'>
            <thead>
              <tr>
                <th rowSpan='2' scope='col'>
                  Name
                </th>
                <th colSpan='3' className='text-center' scope='col'>
                  Arrival
                </th>
                <th colSpan='3' className='text-center' scope='col'>
                  Departure
                </th>
                <th rowSpan='2' className='text-end' scope='col'>
                  Created At
                </th>
                <th rowSpan='2' scope='col'></th>
              </tr>
              <tr>
                <th scope='col'>Date</th>
                <th className='text-center' scope='col'>
                  Time
                </th>
                <th scope='col'>Flight No.</th>
                <th scope='col'>Date</th>
                <th className='text-center' scope='col'>
                  Time
                </th>
                <th scope='col'>Flight No.</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan='9' className='text-center'>
                    Loading...
                  </td>
                </tr>
              ) : transportRecords.length > 0 ? (
                transportRecords.flatMap((record) => [
                  <React.Fragment key={record._id}>
                    <tr
                      style={{
                        borderColor: record.remark ? 'white' : '#219119',
                      }}
                    >
                      <td
                        className='fw-bold text-uppercase'
                        style={{
                          color: record.gender === 1 ? '#2f4483' : '#78003c',
                        }}
                      >
                        {record.name}
                      </td>
                      <td>
                        {record.arrivalDate
                          ? new Date(record.arrivalDate).toLocaleDateString(
                              'en-GB',
                              {
                                day: '2-digit',
                                month: '2-digit',
                                year: 'numeric',
                              }
                            )
                          : ''}
                      </td>
                      <td className='text-center'>
                        {record.arrivalTime || ''}
                      </td>
                      <td>{record.arrivalInfo || ''}</td>
                      <td>
                        {record.departDate
                          ? new Date(record.departDate).toLocaleDateString(
                              'en-GB',
                              {
                                day: '2-digit',
                                month: '2-digit',
                                year: 'numeric',
                              }
                            )
                          : ''}
                      </td>
                      <td className='text-center'>{record.departTime || ''}</td>
                      <td>{record.departInfo || ''}</td>
                      <td className='text-end'>
                        {new Date(record.createdAt).toLocaleDateString(
                          'en-GB',
                          {
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric',
                          }
                        )}
                        <div>
                          {new Date(record.createdAt).toLocaleTimeString(
                            'en-GB',
                            {
                              hour: '2-digit',
                              minute: '2-digit',
                              second: '2-digit',
                              hour12: false,
                            }
                          )}
                        </div>
                      </td>
                      <td className='text-end'>
                        {/* <Tooltip
                          title='Edit Record (Coming Soon)'
                          placement='top'
                        >
                          <i className='bi bi-pencil-square'></i>
                        </Tooltip> */}

                        <Tooltip title='Delete Record'>
                          <span>
                            <i
                              className='bi bi-trash3-fill ms-3'
                              style={{
                                cursor: 'pointer',
                                color: 'red',
                              }}
                              onClick={() => handleDelete(record._id)}
                            ></i>
                          </span>
                        </Tooltip>
                      </td>
                    </tr>
                    {record.remark && (
                      <tr
                        style={{
                          borderColor: '#219119',
                        }}
                      >
                        <td colSpan='9' className='pt-0'>
                          <span className='me-2' style={{ fontWeight: 600 }}>
                            Remark:
                          </span>
                          {record.remark}
                        </td>
                      </tr>
                    )}
                  </React.Fragment>,
                ])
              ) : (
                <tr>
                  <td colSpan='9' className='text-center'>
                    No Records
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <Modal
          open={isModalOpen}
          onCancel={handleCancel}
          footer={null}
          width={900}
          destroyOnClose
        >
          <IntlNewTransport
            isModalOpen={isModalOpen}
            onCancel={handleCancel}
            onAddSuccess={handleAddSuccess}
          />
        </Modal>
      </div>
    </Layout>
  )
}

export default IntlTransportList
