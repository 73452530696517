import React from 'react'

const PageTitle = ({ title }) => {
  return (
    <div
      className='text-center mb-3'
      style={{ fontWeight: '600', fontSize: '18px', color: '#006ba1' }}
    >
      {title}
    </div>
  )
}

export default PageTitle
